import React from 'react'
import { Box, Typography } from '@mui/material'
import FileDropZone from '../Dropzone'
import { makeStyles } from '@mui/styles'
import { UploadFile, MoreVert } from '@mui/icons-material'
import Button from '@mui/material/Button'

const useStyles: any = makeStyles(() => ({
  availableTitle: {
    color: '#919191',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '150% !important',
    letterSpacing: '-0.01em !important',
    marginTop: '20px !important',
    marginBottom: '10px !important',
  },
  fileList: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid #E0E4ED',
    borderRadius: '8px',
    margin: '8px 0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
  },
  fileIcon: {
    width: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInfo: {
    width: '90%',
    padding: '0px 10px',
  },
  fileName: {
    color: '#27344E',
    fontSize: '16px',
    fontWeight: '700 !important',
    lineHeight: '16px !important',
    margin: '4px 0px',
  },
  fileSize: {
    color: '#5C697B',
    fontSize: '14px',
    fontWeight: '500 !important',
    lineHeight: '150% !important',
  },
  fileOption: {
    width: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  downloadAllButton: {
    justifyContent: 'flex-end',
    margin: '20px 0px',
  },
}))
export default function JobFiles() {
  const [file, setFile] = React.useState([])
  const classes = useStyles()


  return (
    <Box>
      <FileDropZone onDrop={(file) => setFile(file)} />
      {file.length > 0 &&
        <Typography className={classes.availableTitle}>{'Uploaded Files'}</Typography>
      }
      {file.length > 0 && file.map((item) => {
        return (
          <Box className={classes.fileList}>
            <Box className={classes.fileIcon}>
              <UploadFile />
            </Box>
            <Box className={classes.fileInfo}>
              <Typography noWrap className={classes.fileName}>{item.name}</Typography>
              <Typography className={classes.fileSize}>{'12 MB'}</Typography>
            </Box>
            <Box className={classes.fileOption}>
              <MoreVert />
            </Box>
          </Box>
        )
      })}
      {file.length > 0 &&
        <Button
          variant={'outlined'}
          className={classes.downloadAllButton}
        >
          {'Download All'}
        </Button>
      }
    </Box>
  )
}