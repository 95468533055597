import React, { useEffect, useState } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import Loader from '../Loader'
import config from 'src/utils/config'

// implemented as redirect to login screen
const redirectToLogin = () => {
  if (typeof window === 'undefined') {
    return
  }
  window.location.replace('/login')
}

const isBrowser = typeof window !== 'undefined'

/**
 * @param children
 * @param isPublic
 */
function Auth({
  children,
  isPublic = false,
  redirectUrl,
}: AuthProps): JSX.Element {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0()
  const [token, setToken] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(false)

  const location = useLocation()
  const client = new ApolloClient({
    uri: config.hasuraUri,
    cache: new InMemoryCache(),
    headers: {
      'Hasura-Client-Name': 'hasura-console',
      'Authorization': `Bearer ${token}`,
      // 'hasura-collaborator-token': '',
      'content-type': 'application/json',
      'x-hasura-allowed-roles': '["user"]',
      'x-hasura-default-role': 'user',
      'x-hasura-role': 'user'
    }
  })

  const getToken = async () => {
    if (getAccessTokenSilently) {
      setTokenLoading(true)
      const token = await getAccessTokenSilently()
      setToken(token)
      setTokenLoading(false)
    }
  }

  useEffect(() => {
    if (
      !isPublic && location?.pathname !== '/login' && (!isBrowser || (!isAuthenticated && !isLoading))
    ) {
      redirectToLogin()
      return null
    }
    if (isAuthenticated && !isLoading) {
      getToken()
    }
  }, [isAuthenticated, isLoading])

  return (
    <>
      {
        isLoading || tokenLoading || (!token && location?.pathname !== '/login') ? <Loader /> :
          <ApolloProvider client={client}>
            {children}
          </ApolloProvider>
      }
    </>
  )
}

export const withAuth = (Component: any, props?: AuthProps): any => {
  const Comp = (p: any) => {
    return (

      <Auth0Provider
        domain="dsptch-stage.us.auth0.com"
        clientId="JJVlvwZRHKaQAT8TfpxzZz9XQYQ4GknI"
        redirectUri={window.location.origin}
        audience={config.hasuraUri}
      >
        <Auth {...p} {...props}>
          <Component {...p} />
        </Auth>
      </Auth0Provider>
    )
  }
  return React.memo(Comp)
}

type AuthProps = {
  children?: React.ReactNode
  isPublic?: Boolean
  redirectUrl?: () => string
}

export default withAuth

