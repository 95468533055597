import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {  Box, Button, CardHeader, Checkbox, IconButton } from '@mui/material'
import { CardBackground } from 'src/theme/Colors'
import SearchBox from '../SearchBox'
import { AppTextColor } from 'src/theme/Colors'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function CustomCard(props: CustomCardProps | any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (name: string) => {
    let index = props.updatedColumns.findIndex(f => f.name === name )
    let updatedObject = Object.assign({}, props.updatedColumns[index], {
      isChecked: !props.updatedColumns[index].isChecked
    })
    props.setUpdatedColumns([
      ...props.updatedColumns.slice(0, index),
      {...updatedObject},
      ...props.updatedColumns.slice(index + 1, props.updatedColumns.length )
    ])
  }

  const Menus = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="demo-customized-menu'"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.updatedColumns.map((c, i) =>
          <MenuItem
            key={i}
            value={c.key}
            style={{
              fontWeight:  '500',
              fontSize: '14px'
            }}
            onClick={e => handleChange(c.name)}
          >
          <Checkbox
            checked={c.isChecked}
            name={c.name}
          />
          {c.name}
        </MenuItem>
        )}
      <Divider/>
        <Box display='flex' gap="5px" p="5px" justifyContent={'center'}>
          <Button variant='outlined' onClick={() => {
            props.setColumnConfig([...props.defaultConfig])
            props.setUpdatedColumns([...props.defaultConfig])
            setAnchorEl(null)
          }}>Reset View</Button>
          <Button variant='contained' onClick={() => {
            props.setColumnConfig([...props.updatedColumns])
            setAnchorEl(null)
          }}> Apply</Button>
        </Box>
    </Menu>
    )
  }

  const renderHeaderAction = () => {
    return (
      <Box display={'flex'} gap={'10px'}>
        {
          props.isSearchRequired &&
          <SearchBox isFilterRequired={false} onSearchChange={props.onSearchChange} searchValue={props.searchValue} />
        }
        {
          props.showMore &&  (
          <IconButton aria-label='settings'>
            <MoreVertIcon sx={{ color: AppTextColor }} />
          </IconButton>
          )
        }
        {props.showFilterButton && (
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<ViewWeekOutlinedIcon />}
            sx={{
              fontWeight: '500',
              fontSize: '14px'
            }}
          >
            Table View
          </Button>
        )}
      </Box>
    )
  }
  return (
    <Card
      sx={{ position: 'relative', margin: { xs: 'auto' }, backgroundColor: CardBackground,  boxShadow: 'none' }}>
      <CardHeader
        title={props.title}
        subheader={props.subtitle}
        titleTypographyProps={{ fontSize: '16px', fontWeight: '700' }}
        action={renderHeaderAction()}
      />
      <Divider />
      <CardContent>
        {props.children}
      </CardContent>
      {props.showFilterButton && (
        <Menus />
      )}

    </Card>
  )
}

export type CustomCardProps = {
  title?: string,
  subtitle?: string,
  isSearchRequired?: Boolean,
  onSearchChange?: any,
  searchValue?: string,
  showMore?: Boolean,
  showFilterButton?: Boolean,
  updatedColumns?: any,
  setColumnConfig?: any,
  setUpdatedColumns?: any,
  defaultConfig?: any
}

