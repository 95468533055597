import React from 'react'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Link } from 'react-router-dom'
import { ActiveTab, AppBorderColor } from 'src/theme/Colors'

const SideBarItem = ({link, icon, activeTab, setActiveTab, isLogo}: SideBarItemProps) => {

    return (
        <Link to={link} onClick={() => setActiveTab()}>
            <ListItemIcon sx={{minWidth: '0px'}}>
                <Box sx={!isLogo && activeTab ? {backgroundColor: ActiveTab , height: '40px', borderRadius: '5px' , 'borderColor': AppBorderColor} : {}}>
                    {icon}
                </Box>
            </ListItemIcon>
        </Link>
    )
}

type SideBarItemProps = {
    link?: string,
    icon: JSX.Element,
    setActiveTab?: Function,
    activeTab?: Boolean,
    isLogo?: Boolean
}

export default React.memo(SideBarItem)