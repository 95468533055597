import { Box } from '@mui/system'
import React, { useMemo, useState, useEffect } from 'react'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
// import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import { Button, Grid, Typography, Divider } from '@mui/material'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import CustomInput from 'src/components/Inputs'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import OutlinedInput from '@mui/material/OutlinedInput'
import Notify from 'src/components/Toast'
import { FormControl, useFormControl } from '@mui/material'
import { AppTextColor, AppErrorColor } from 'src/theme/Colors'
// import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import Card from 'src/components/Card'
import PersonIcon from '@mui/icons-material/Person'
import CreatePosition from '../CreatePosition'
import { GetAllCompanies, GetJobCreatorCompanies } from 'src/core/gql/queries/company'
import { CREATE_JOB, UPDATE_JOB_WITH_CONTACT } from 'src/core/gql/mutations/jobs'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { SiteAssets, CompanySites, GetSingleJob } from 'src/core/gql/queries/job'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Dropdown from 'src/components/Dropdown'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { ScheduledTimeToStartEndDate, StartEndDateToScheduledTime, ValidateStartEndDate } from '../../../utils/DateHelper'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles(() => ({
  createJobsContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 0px 30px 0px'
  },
  createJobsJeader: {
    borderBottom: '1px solid #E0E4ED !important',
    height: '66px !important',
    padding: '16px 8px 8px 24px',
    width: '100%'
  },
  headerLabel: {
    fontWeight: '700 !important',
    fontSize: '20px !important',
    color: '#001533 !important'
  },
  templeteLabel: {
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: '#4D5C71 !important'
  },
  createJobInnerContainar: {
    padding: '10px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '400px',
    overflowY: 'scroll'
  },
  genetalDetailHeading: {
    width: '95%',
    borderBottom: '1px solid #E0E4ED !important',
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px'
  },
  locDetailHeading: {
    width: '95%',
    borderBottom: '1px solid #E0E4ED !important',
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px',
    margin: '0px 0px 20px 0px'
  },
  generalIcon: {
    color: '#3076FF !important',
    marginRight: '10px'
  },
  generalLabel: {
    fontWeight: '700 !important',
    color: '#3076FF !important',
    lineHeight: '16px !important',
    letterSpacing: '-0.01em'
  },
  generalForm: {
    width: '95%',
    padding: '20px 0px'
  },
  locationForm: {
    width: '95%',
    padding: '0px 0px 10px 0px'
  },
  contactForm: {
    width: '95%',
    padding: '0px 0px 10px 0px'
  },
  rowGrid: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px'
  },
  leftGrid: {
    width: '40%',
    display: 'flex',
  },
  rightGrid: {
    width: '55%',
    display: 'flex',
  },
  rightCardHeader: {
    width: '100%'
  },
  headingStyles: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    margin: '0px 0px 10px 10px !important',
  },
  inputBoxContainer: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '0px 0px 10px 0px'
  },
  boxInnerContainer: {
    width: '47%'
  },
  paperStyle: {
    width: '100%'
  },
  buttonRootContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonInnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0px 5px 0px'
  },
  cancelButtonContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center'
  },
  timePicker: {
    top: '30px !importamt'
  }
}))

const CreateJob = ({ }) => {

  var requestingCompanyId = 0
  var siteId = 0

  // Get Current User
  const { user } = useAuth0()

  const { id } = useParams()

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    // event.preventDefault()
  }

  const { data: jobCreatorCompanies = { company: [] } } = useQuery(GetJobCreatorCompanies, {
    fetchPolicy: 'network-only',
    variables: { user_id: user.sub }
  })

  const [refetchAssets, { loading: allAssetsLoading, data: assets = {} }] = useLazyQuery(SiteAssets, {
    fetchPolicy: 'network-only',
  })

  const [refetchSites, { loading: allSitesLoading, data: sites = {site: []} }] = useLazyQuery(CompanySites, {
    fetchPolicy: 'network-only',
  })

  const { loading: allCompaniesLoading, data: allCompanies = {company: []} } = useQuery(GetAllCompanies, {
    fetchPolicy: 'network-only'
  })

  const [refetchJob, { loading: singleJobDataLoading, data: singleJobData  }] = useLazyQuery(GetSingleJob)

  const [companySites, setCompanySites]: any = useState([])

  const [response, setResponse]: any = useState({
    type: '',
    message: '',
    show: false
  })

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/jobs"
    >
      All Jobs
    </Link>,
    <Typography key="3" color="text.primary">
      {id ? 'Edit Job' : 'Create New Job'}
    </Typography>,
  ]
  const classes = useStyles()

  const [updateJob] = useMutation<any, any>(UPDATE_JOB_WITH_CONTACT, {
    onCompleted: () => {
       setResponse({
           type: 'success',
           message: 'Job successfully updated.',
           show: true
       })
    },
    onError: (e) => {
        setResponse({
          type: 'error',
          message: e.graphQLErrors[0].message,
          show: true
        })
    }
})

  const [createJob] = useMutation<any, any>(CREATE_JOB, {
    onCompleted: (data) => {
      setResponse({
        type: 'success',
        message: 'Job successfully created.',
        show: true
    })
    },
    onError: (e) => {
      setResponse({
        type: 'error',
        message: e.graphQLErrors[0].message,
        show: true
      })
    }
  })
  const validationSchema = yup.object({
    requestingCompany: yup
      .string()
      .required('Requesting Company is required'),
    fulfillmentCompany: yup
      .string()
      .required('Fulfillment Company is required'),
    contactName: yup
      .string()
      .required('Contact Name is required')
      .min(8, 'Contact Name should be of minimum 8 characters length'),
    position: yup.string().required('Position is required'),
    email: yup.string()
      .email('Enter valid email')
      .required('This field is required')
  })

  const getValue = (event: any) => {
    const {
      target: { value },
    } = event
    return typeof value === 'string' ? value.split(',') : value
  }

  async function onCreateJob(values: any) {
    let data = {
      description: values.description,
      requested_by_company_id: values.requestingCompany,
      fulfilled_by_company_id: values.fulfillmentCompany,
      scheduled_time: StartEndDateToScheduledTime(values.startDate, values.endDate),
      site_id: values.siteId,
      type: 'POSITION',
      contacts: {
        data: {
          contact: { data: { email: values.email, name: values.contactName, phone_number: values.phoneNumber, title: values.position, created_for_company_id: values.fulfillmentCompany} }
        }
      }
    }

      await createJob({
        variables: {
          object: data
        }
      })
  }

  async function onUpdateJob(values: any) {
    let data = {
      description: values?.description,
      scheduled_time: StartEndDateToScheduledTime(values?.startDate, values?.endDate),
      site_id: values?.siteId,
      type: 'POSITION'
    }

    let contactData = {
      email: values?.email, name: values?.contactName, phone_number: values?.phoneNumber, title: values?.position, created_for_company_id: values?.fulfillmentCompany
    }

      updateJob({
        variables: {
          jobId: id,
          set: {
            ...data
          },
          contactId: values.contactId,
          contactSet: {
            ...contactData
          }
        }
      })
  }

  useEffect(() => {
    if(id) {
        getJobData(id)
    }
  }, [id])

  useEffect(() => {
    if(singleJobData) {
        const job = singleJobData?.job_by_pk
        const contact = job?.contacts?.length > 0 ? job?.contacts?.[0]?.contact : null
        const scheduledTime = job?.scheduled_time
        const [startDate, endDate] = ScheduledTimeToStartEndDate(scheduledTime)
        let values = {
          requestingCompany: job?.requested_by_company_id,
          fulfillmentCompany: job?.fulfilled_by_company_id,
          startDate: startDate ?? '',
          endDate: endDate ?? '',
          description: job?.description,
          siteId: job?.site_id,
          siteName: job?.site?.name,
          assets: [],
          completeAddress: '',
          contactId: contact?.id,
          contactName: contact?.name,
          position: contact?.title,
          phoneNumber: contact?.phone_number,
          email: contact?.email
        }
        // Get Sites for Company
        getSitesForCompany(job.requested_by_company_id)
        // Get Assets for Site
        if (job.site_id != null) {
          getAssetsForSite(job.site_id)
        }
        formik.setValues(values)
    }
  }, [singleJobData])

  const getJobData =  async (id: any) => {
    await refetchJob({
        variables: { id }
    })
  }

  const getSitesForCompany = async (company_id: any) => {
    await refetchSites({
      variables: { company_id }
    })
  }

  const getAssetsForSite = async (site_id: any) => {
    await refetchAssets({
      variables: { site_id }
    })
  }

  const formik = useFormik({
    initialValues: {
      requestingCompany: '',
      fulfillmentCompany: '',
      startDate: '',
      endDate: '',
      description: '',
      siteId: '',
      siteName: '',
      assets: [],
      completeAddress: '',
      contactId: '',
      contactName: '',
      position: '',
      phoneNumber: '',
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      // Check Start and End Date
      if (ValidateStartEndDate(values.startDate, values.endDate)) {
        id ? onUpdateJob(values) : onCreateJob(values)
      } else {
        // Invalid Start and End Date
        setErrors({startDate: 'Invalid Date Range', endDate: 'Invalid Date Range'})
      }
    },
  })


  const HeadingTextPicker = ({ isError, label }) => {
    const { focused } = useFormControl() || {}

    const headingColor = useMemo(() => {
      if (isError) {
        return AppErrorColor
      }

      if (focused && !isError) {
        return '#007FFF'
      }
      return AppTextColor
    }, [focused])

    return (
      <Typography fontSize={14} className={classes.headingStyles} color={headingColor}>
        {label}
      </Typography>
    )
  }

  function renderInputPicker(params, type, isError: any) {
    return (
      <FormControl variant="standard" fullWidth>
        <HeadingTextPicker label={type === 'startDate' ? 'Start Date' : 'End Date'} isError={isError} />
        <OutlinedInput
          id={`bootstrap-input-${params.label}`}
          name={`bootstrap-input-${params.label}`}
          fullWidth placeholder={'E.g. 06 Jun – 10 Jun'}
          error={isError}
          sx={{ height: '40px' }}
          value={params.inputProps.value}
          onChange={params.inputProps.onChange}
          endAdornment={params.InputProps.endAdornment}
        />
      </FormControl>
    )
  }

  return (
    <Grid spacing={3}>
      <Stack spacing={2}>
        <Breadcrumbs separator="<" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Box display={'flex'} gap={'15px'} marginTop={2}>
        <Grid item md={5}>
          <Card title={id ? 'Edit Job' : 'Create Job' }>
            <Box sx={{ height: '65vh', overflow: 'scroll' }}>
              <Box className={classes.generalForm}>
                <Box display="flex" alignItems={'center'} gap="10px">
                  <WorkOutlineIcon fontSize="medium" className={classes.generalIcon} />
                  <Typography className={classes.generalLabel}>General Details</Typography>
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.boxInnerContainer}>
                    <Dropdown
                      id='requestingCompany'
                      label="Requesting Company*"
                      value={formik.values.requestingCompany}
                      placeholder=''
                      setData={(val) => {
                        getSitesForCompany(val)
                        formik.setFieldValue('requestingCompany', val)
                      }}
                      options={allCompanies.company}
                      optionLable='name'
                      selectedLabel='id'
                      isError={formik.touched.requestingCompany && Boolean(formik.errors.requestingCompany)}/>
                  </Box>
                  <Box className={classes.boxInnerContainer}>
                    <Dropdown
                      id='fulfillmentCompany'
                      label="Fulfillment Company*"
                      value={formik.values.fulfillmentCompany}
                      placeholder=''
                      setData={(val) => {
                        formik.setFieldValue('fulfillmentCompany', val)
                      }}
                      options={jobCreatorCompanies.company}
                      optionLable='name'
                      selectedLabel='id'
                       isError={formik.touched.fulfillmentCompany && Boolean(formik.errors.fulfillmentCompany)}/>
                  </Box>
                </Box>
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.boxInnerContainer}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label=""
                        value={formik.values.startDate}
                        onChange={(val) => formik.setFieldValue('startDate', val)}
                        renderInput={(params) =>
                          renderInputPicker(params, 'startDate', formik.touched.startDate && Boolean(formik.errors.startDate))
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box className={classes.boxInnerContainer}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label=""
                        className={classes.timePicker}
                        value={formik.values.endDate}
                        onChange={(val) => formik.setFieldValue('endDate', val)}
                        renderInput={(params) =>
                          renderInputPicker(params, 'endDate', formik.touched.endDate && Boolean(formik.errors.endDate))
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.paperStyle}>
                    <CustomInput
                      id='description'
                      label={'Description*'}
                      placeholder="Type here..."
                      value={formik.values.description}
                      onChangeHandler={formik.handleChange}
                      isError={false}
                      helperText={''}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.locationForm}>
                <Box display="flex" alignItems={'center'} gap="10px">
                  <LocationOnIcon fontSize="medium" className={classes.generalIcon} />
                  <Typography className={classes.generalLabel}>Location Details</Typography>
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
                <Box className={classes.inputBoxContainer}>
                  <Box sx={{width: '200px'}}>
                    {!allSitesLoading && <FormControl sx={{width: 200}} size='small'>
                      <InputLabel id="demo-multiple-checkbox-label">Sites</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={formik.values.siteId}
                        onChange={(event) => {
                          const siteName = getValue(event)
                          formik.setFieldValue('siteName', siteName)
                          // Set Addresss
                          const selectedSite = sites?.site?.find((x) => {
                            return x.name === siteName[0]
                          })
                          siteId = selectedSite?.id
                          formik.setFieldValue('siteId', siteId)
                          getAssetsForSite(siteId)
                          if (selectedSite?.address != null) {
                            formik.setFieldValue('completeAddress', 'Test Address')
                          }
                        }}
                        input={<OutlinedInput label="Assets" />}
                        renderValue={(_) => formik.values.siteName}
                        MenuProps={MenuProps}
                      >
                        {sites && sites?.site?.length > 0 && sites?.site?.map((item, index) => (
                          <MenuItem key={index} value={item.name}>
                            <Checkbox checked={formik.values.siteId === item.id} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>}
                  </Box>
                  <Box className={classes.boxInnerContainer}>
                    {!allAssetsLoading && <FormControl sx={{width: 200}} size='small'>
                      <InputLabel id="demo-multiple-checkbox-label">Assets</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={formik.values.assets}
                        onChange={(event) => formik.setFieldValue('assets', getValue(event))}
                        input={<OutlinedInput label="Assets" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {assets && assets?.asset?.length > 0 && assets?.asset?.map((item, index) => (
                          <MenuItem key={index} value={item.name}>
                            <Checkbox checked={formik.values.assets.indexOf(item.name) > -1} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>}
                  </Box>
                </Box>
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.paperStyle}>
                    <CustomInput
                      id='completeAddress'
                      label={'Complete Address Or Coordinates'}
                      placeholder="Select a Site..."
                      value={formik.values.completeAddress}
                      onChangeHandler={formik.handleChange}
                      isError={formik.touched.completeAddress && Boolean(formik.errors.completeAddress)}
                      helperText={''}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.contactForm}>
                <Box display="flex" alignItems={'center'} gap="10px">
                  <PersonIcon fontSize="medium" className={classes.generalIcon} />
                  <Typography className={classes.generalLabel}>Local Contact</Typography>
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.boxInnerContainer}>
                    <CustomInput
                      id='contactName'
                      label={'Contact Name*'}
                      placeholder="Type here..."
                      value={formik.values.contactName}
                      onChangeHandler={formik.handleChange}
                      isError={formik.touched.contactName && Boolean(formik.errors.contactName)}
                      helperText={''}
                    />
                  </Box>
                  <Box className={classes.boxInnerContainer}>
                    <CustomInput
                      id='position'
                      label={'Position'}
                      placeholder="Type here..."
                      value={formik.values.position}
                      onChangeHandler={formik.handleChange}
                      isError={formik.touched.position && Boolean(formik.errors.position)}
                      helperText={''}
                    />
                  </Box>
                </Box>
                <Box className={classes.inputBoxContainer}>
                  <Box className={classes.boxInnerContainer}>
                    <CustomInput
                      id='phoneNumber'
                      label={'Phone Number'}
                      placeholder="Type here..."
                      value={formik.values.phoneNumber}
                      onChangeHandler={formik.handleChange}
                      isError={false}
                      helperText={''}
                    />
                  </Box>
                  <Box className={classes.boxInnerContainer}>
                    <CustomInput
                      id='email'
                      label={'Email'}
                      placeholder="Type here..."
                      value={formik.values.email}
                      onChangeHandler={formik.handleChange}
                      isError={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={''}
                    />
                  </Box>
                </Box>
              </Box>
              <Grid p="10px 0px" container spacing={2}>
                <Grid md={6} item>
                  <Button
                    type='button'
                    variant="outlined"
                    onClick={() => { }}
                    sx={{ width: '100%' }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid md={6} item>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    sx={{ width: '100%' }}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid className={classes.rightGrid}>
          <CreatePosition />

        </Grid>
        <Notify defaultOpen={response.show} message={response.message} type={response.type} />
      </Box>
    </Grid >
  )
}

export default CreateJob