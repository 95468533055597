import React, {useCallback} from 'react'
import { Box, InputBaseProps, ClickAwayListener, Divider, Typography } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import { AppBorderColor, AppTextColor } from 'src/theme/Colors'
import TuneSharpIcon from '@mui/icons-material/TuneSharp'
import debounce from 'lodash.debounce'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '80%',
      },
    },
  },
}))

export default function SearchBox({ isFilterRequired, text = null, background = 'inherit', inputProps, CustomFilter = <></>, onSearchChange, searchValue }: SearchBoxProps) {
  const [showInput, setShowInput] = React.useState(Boolean(text))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((query: string) => {
      onSearchChange(query)
    }, 1000), []
  )

  const onSearch = query => {
    if (!query) return onSearchChange('')
    debouncedSearch(query)
  }

  return (
    <ClickAwayListener onClickAway={() => setShowInput(Boolean(text) ? true : false)}>
      <Box sx={{ backgroundColor: background }} display={'flex'} alignItems="center" border={`1px solid ${AppBorderColor}`} borderRadius="8px" px={1} justifyContent={'space-between'}>
        {
          !showInput ? null : <Typography fontSize='18px' fontWeight={600} lineHeight='24.3px' >{text}</Typography>
        }
        {
          !showInput ?
            <Search>
              <SearchIconWrapper >
                <SearchIcon sx={{ color: AppTextColor }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                onChange={e => onSearch(e.target.value)}
                value={searchValue}
                {...inputProps}
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            : null
        }
        <Box display='flex' alignItems='center'>
          {!showInput ? null :
            <IconButton onClick={() => setShowInput(false)} >
              <SearchIcon sx={{ color: AppTextColor }} />
            </IconButton>
          }
          {isFilterRequired && (
            <>
              <Divider orientation="vertical" flexItem sx={{ marginTop: '5px', marginBottom: '5px' }} />
              <IconButton aria-label="settings">
                <TuneSharpIcon sx={{ color: AppTextColor }} />
              </IconButton>
            </>
          )}
          {
            CustomFilter
          }
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

export type SearchBoxProps = {
  isFilterRequired: Boolean
  text?: any
  background?: any
  inputProps?: InputBaseProps
  CustomFilter?: Element | JSX.Element,
  onSearchChange?: any,
  searchValue?: string
}