import React, { useState, useEffect, useContext, } from 'react'
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material'
import MyCalendar from 'src/components/Calendar'
import { makeStyles } from '@mui/styles'
import Modal from './DetailModal'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import JobDetails from 'src/components/JobDetails'
import JobFiles from 'src/components/JobFiles'
import Comments from 'src/components/Comments'
import { DragDropContext } from 'react-beautiful-dnd'
import { GetSingleJob } from 'src/core/gql/queries/job'
import GqlError from 'src/components/HOC/GQLError'
import { useLazyQuery,useQuery } from '@apollo/client'
import Notify from 'src/components/Toast'
import { LayoutContext } from 'src/contexts/layoutContext'
import { JobsView, WorkerViews } from 'src/components/Scheduler/Layout'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { getQueryParam } from 'src/utils/query'
import InviteConfirmation from 'src/components/Modal/Generic'
import CircularProgressBar from 'src/components/Loader'
import { useSchedulerContext } from 'src/contexts/SchedulerContext'
import Loader from '../../components/Loader'
import { GetSingleUser ,GetUserRole} from 'src/core/gql/queries/users'
import WorderDetails from 'src/components/WorkerDetails'
import WorkerActiveJobs from 'src/components/WorkerActiveJobs'
import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles(() => ({
  sectionHeadingText: {
    height: '18px',
    width: '226px',
    left: '8px',
    top: '12px',
    textAlign: 'left',
  },
  sectionHeadingBox: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  jobContainer: {
    width: '100%',
    marginTop: 20
  },
  jobDetailModalRoot: {
    width: '100%'
  },
  popUpBodyRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20
  },
  rowContainer: {
    width: '33%'
  },
  certificationsTitle: {
    marginBottom: '10px !important',
    color: '#A1A1A1',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '115% !important',
    letterSpacing: '0.08em !important',
    textTransform: 'uppercase'
  },
  assignedWorkers: {
    marginBottom: '10px !important',
    color: '#A1A1A1',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '115% !important',
    letterSpacing: '0.08em !important',
    textTransform: 'uppercase'
  },
  headerStatusPending: {
    width: '100%',
    backgroundColor: '#ECD7BE',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headerStatusInProgress: {
    width: '100%',
    backgroundColor: '#C2ECBE',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headerStatusOnHold: {
    width: '100%',
    backgroundColor: '#BEECE6',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  hideStatus: {
    display: 'none !important'
  }
}))

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const Scheduler = () => {
  const userData = useAuth0()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [dragStart, setDragStart] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [ID] = React.useState(getQueryParam('jobID'))
  const classes = useStyles()
  const { layout } = useContext(LayoutContext)
  const [openWorkerDetail, setOpenWorderDetail] = React.useState(false)

  const { allJobs, updateJob, sendPositionOffer, positionAssignData, setPositionAssignData, allWorkers } = useSchedulerContext()
  const [getSingleJob, { loading: loadingSingleJob, error: errorSingleJob, data: jobData }] = useLazyQuery(GetSingleJob)
  const [refetchWorker, { loading: singleWorkerLoading, error: singleWorkerError, data: singleWorkerData }] = useLazyQuery(GetSingleUser)
  const  { loading: userRoleLoading, error: userRoleError, data: userRoleData } = useQuery(GetUserRole,
      { variables: { id: userData.user.sub }
      }
     )
  //   internal functions
  const getSingleJobDetails = (id: any) => {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?jobID=${id}`
    window.history.pushState({ path: newurl }, '', newurl)
    if (id) {
      getSingleJob({
        variables: { id }
      })
      setOpen(true)
    }
  }

  const onDragEnd = async (result) => {
    setDragStart(false)
    if (!result.destination) return
    let { draggableId } = result
    draggableId = JSON.parse(draggableId)
    if (draggableId.from === 'worker') {
      setPositionAssignData(result)
      setConfirmationDialog(true)
    }
  }

  const assignUserToJob = async (noInvitation: boolean) => {
    setConfirmationDialog(false)
    let { destination, draggableId } = positionAssignData
    let { droppableId } = destination
    draggableId = JSON.parse(draggableId)
    if (draggableId.from === 'worker') {
      setPositionAssignData(positionAssignData)
      const userId = draggableId.id
      droppableId = JSON.parse(droppableId)
      const { jobId, positionId } = droppableId
      if (noInvitation) {
        // No need for offer assign user directly to position
        updateJob?.updateJobPositions({
          variables: {
            userId,
            jobId,
            positionId,
          },
        })
      } else {
        // Insert Position Offer
        if (validateWorkerPermission(userId,jobId) && validateUserPermission(jobId)) {
          sendPositionOffer?.sendPositionOffer({
            variables: {
              object: {
                position_id: positionId,
                user_id: userId
              }
            }
          })
        }else if ( !validateUserPermission(jobId)){
          <Notify defaultOpen={true} message={'You don’t have a permission to do that'} type={'error'} />
        }
      }
      // await refetch()
    }
  }

  const removeWorkerFromPosition = async (jobId, positionId) => {
    updateJob?.updateJobPositions({
      variables: {
        userId: null,
        jobId,
        positionId,
      },
    })
    // await refetch()
  }


  useEffect(() => {
    if (ID) getSingleJobDetails(ID)
  }, [ID])

  useEffect(() => {
    allWorkers.getAllWorkers()
    allJobs.getAllJobs()
  }, [])

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const modalCloseHandler = (state) => {
    setOpen(state)
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname
    window.history.pushState({ path: newurl }, '', newurl)
  }
  function validateWorkerPermission(id: any, jobId: any): boolean {
    const job = allJobs.data.job.find(item => item.id === jobId)
    const companyId = job.fulfilled_by_company_id
    const worker = allWorkers?.data?.user?.find(item => item.id === id)
    const workerRole = worker.company.user_roles.find(item => item.role === 'POSITION_ACCEPTOR')
    return workerRole && worker.company.id === companyId
  }

  function validateUserPermission (jobId: any): boolean {
    const job = allJobs.data.job.find(item => item.id === jobId)
    const companyId = job.fulfilled_by_company_id
    const userRoles = userRoleData.user_by_pk.position_offers[0].user.roles
    const userRole = userRoles.find(item => item.role === 'POSITION_ASSIGNER' && item.company_id === companyId)
    return userRole
  }

   async function getWorkerData(id: any) {

    await refetchWorker({
      variables: { id }
    })
    setOpenWorderDetail(true)
  }

  let status = jobData?.job_by_pk?.status ? jobData.job_by_pk.status : '--'

  const getDragDropDetails = () => {
    return {
      name: JSON.parse(positionAssignData?.draggableId)?.name,
      position: JSON.parse(positionAssignData?.destination?.droppableId)?.title
    }
  }

  return (
    <>
      {
        errorSingleJob ? <GqlError error={errorSingleJob} /> : null
      }
      {
        allJobs?.error ? <GqlError error={allJobs?.error} /> : null}
      {
        allWorkers?.error ? <GqlError error={allWorkers?.error} /> : null}
      {
        updateJob?.error ? <GqlError error={updateJob.error} /> : null
      }
      {
        open ?
          <Modal
            setOpen={modalCloseHandler}
            open={open}
            id={jobData?.job_by_pk?.id || Math.floor(Math.random() * 100)}
            title={jobData && Object.keys(jobData).length > 0 ? jobData?.job_by_pk?.created_by_user?.name : '--'}
            showActions={false}
            rootClass={classes.jobDetailModalRoot}
            subtitle={jobData && Object.keys(jobData).length > 0 ? jobData?.job_by_pk?.created_by_user?.email : '--'}
            statusClasses={status === 'Pending' ? classes.headerStatusPending : status === 'OnHold' ? classes.headerStatusOnHold : status === 'InProgress' ? classes.headerStatusInProgress : classes.headerStatusInProgress}
            statusLabel={status}
            type={'Job'}
          >
            <Box className={classes.jobContainer}>
              <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                <Tab label='Job Details' {...a11yProps(0)} />
                <Tab label='Files' {...a11yProps(1)} />
                {/* <Tab label='Comment' {...a11yProps(2)} /> */}
              </Tabs>
              <TabPanel value={value} index={0}>
                {!loadingSingleJob && !errorSingleJob && jobData?.job_by_pk && Object.keys(jobData).length > 0 ?
                  <JobDetails statusLabel={status} jobData={jobData} /> : null}
                {!loadingSingleJob && errorSingleJob && <Typography>Invaid ID</Typography>}
                {
                  loadingSingleJob &&
                  <Box position='relative' height='70vh' >
                    <CircularProgressBar left='45%' />
                  </Box>
                }
              </TabPanel>
              <TabPanel value={value} index={1}>
                <JobFiles />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Comments />
              </TabPanel>
            </Box>
          </Modal>
          : null
      }

      {
        confirmationDialog ?
          <InviteConfirmation
            setOpen={() => { setConfirmationDialog(false) }}
            open={confirmationDialog}
            title={<Typography fontSize='18px' color='#252626' fontWeight='800'>Confirmation Invitation</Typography>}
          >

            <Box className={classes.jobContainer}>
              <Typography>
                You can invite the worker <strong>{getDragDropDetails().name}</strong> to occupy the
                Position <strong>"{getDragDropDetails().position}"</strong> for this job, or you can
                directly assign them. Close this popup to cancel.
              </Typography>
              <Stack flexDirection={'row'} mt={4}>
                <Button sx={{ mr: 1 }} fullWidth variant='outlined' color='error' onClick={ () => assignUserToJob(true)}>Override Invitation</Button>
                <Button sx={{ ml: 1 }} fullWidth variant='contained' color='primary' onClick={ () => assignUserToJob(false)}>Invite Worker</Button>
              </Stack>
            </Box>
          </InviteConfirmation>
          : null
      }
      {
        !updateJob.loading && updateJob?.responseMessage?.text && updateJob?.responseMessage?.status ?
          <Notify defaultOpen={true} message={updateJob?.responseMessage.text} type={updateJob?.responseMessage.status} />
          : null
      }

      {
        layout === 'job-worker' ? <Stack flexDirection='row' alignItems='center'>
          <Typography >Scheduler Job | Worker view </Typography>
          <ChevronLeftIcon />
          <Chip label="Pending Jobs" size="small" variant="filled" color='info' />
        </Stack>
          : null
      }

      <DragDropContext
        onDragEnd={result => onDragEnd(result)}
      // onDragStart={(e) => setDragStart(true)}
      >
        <Grid container padding={1}>
          {
            layout === 'default' || layout === 'job-worker' || layout === 'job-calendar' ?
              <Grid item md={2.5} lg={2.5} xl={2.5} >
                <Box pl={1} pr={2} position='relative'>

                  {
                    <JobsView
                      onSearchChange={allJobs.applyJobSearch}
                      loading={allJobs?.loading}
                      layout={layout}
                      jobsItems={allJobs?.data}
                      positionLoading={updateJob.loading}
                      getSingleJobDetails={getSingleJobDetails}
                      dragStart={dragStart}
                      removeWorkerFromPosition={removeWorkerFromPosition}
                    />
                  }
                </Box>
              </Grid>
              : null
          }
          {
            layout === 'default' || layout === 'job-worker' ?
              <Grid position='relative' item md={layout === 'default' ? 2.5 : 9.5} lg={layout === 'default' ? 2.5 : 9.5} xl={layout === 'default' ? 2.5 : 9.5} >
                <Box pl={1} pr={2} >
                  <WorkerViews
                    loading={allWorkers?.loading}
                    onSearchChange={allWorkers.applyWorkerSearch}
                    onClickDetail={getWorkerData}
                    layout={layout}
                    items={allWorkers?.data?.user || []}
                  />
                </Box>
              </Grid> : null
          }
          <Modal
            setOpen={setOpenWorderDetail}
            open={openWorkerDetail}
            id={singleWorkerData && singleWorkerData.user[0].id}
            title={singleWorkerData && Object.keys(singleWorkerData.user).length > 0 ? `${singleWorkerData?.user[0]?.first_name !== null ? singleWorkerData?.user[0]?.first_name : '--'} ${singleWorkerData?.user[0]?.last_name !== null ? singleWorkerData?.user[0]?.last_name : ''}` : '--'}
            showActions={false}
            rootClass={classes.jobDetailModalRoot}
            subtitle={singleWorkerData && Object.keys(singleWorkerData.user).length > 0 ? singleWorkerData?.user[0]?.email : '--'}
            statusClasses={classes.hideStatus}
            statusLabel={''}
            type={'Worker'}
          >
            <Box className={classes.jobContainer}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Worker Details" {...a11yProps(0)} />
                <Tab label="Active Jobs" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                {!singleWorkerLoading && !singleWorkerError && singleWorkerData && singleWorkerData.user.length > 0 && <WorderDetails workerData={singleWorkerData} />}
                {!singleWorkerLoading && singleWorkerError && <Typography>Invaid ID</Typography>}
                {
                  singleWorkerLoading &&
                  <Box position='relative' height='70vh' >
                    <Loader left='45%' />
                  </Box>
                }

              </TabPanel>
              <TabPanel value={value} index={1}>
                {!singleWorkerLoading && !singleWorkerError && singleWorkerData && singleWorkerData.user.length > 0 && <WorkerActiveJobs workerData={singleWorkerData} />}
              </TabPanel>
            </Box>
          </Modal>
          {
            layout === 'default' || layout === 'calendar' || layout === 'job-calendar' ?
              <Grid item md={layout === 'default' ? 7 : layout === 'job-calendar' ? 9.5 : 12} lg={layout === 'default' ? 7 : layout === 'job-calendar' ? 9.5 : 12} >
                <Box sx={{
                  '& > div > div': {
                    height: '90vh !important'
                  }
                }}>
                  <MyCalendar jobsItems={allJobs?.data} onClickPosition={getSingleJobDetails} />
                </Box>
              </Grid>
              : null
          }
        </Grid>
      </DragDropContext>
    </>
  )
}


export default Scheduler
