import Notify from 'src/components/Toast'

const GqlError = ({ error }) => {
    // const [open, setOpen] = useState(true)
    return (
        <Notify
            defaultOpen={true}
            message={
                <pre>GQL ERROR: {error?.graphQLErrors?.map(({ message }, i) => (
                    <span key={i}>{message}</span>
                )) || 'AN UNKNOWN ERROR OCCURED'}
                </pre>} type='error' />
    )
}

export default GqlError
