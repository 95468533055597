import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import { Dashboard, Scheduler, Login, Certificates, Jobs, Workers, CreateJob } from './pages'
import DashboardLayout from './components/Layout'
import { Box } from '@mui/material'
import { SchedulerProvider } from 'src/contexts/SchedulerContext'
import CreateOrUpdateWorker from './pages/Worker/CreateOrUpdateWorker'
import { JobProvider } from 'src/contexts/JobContext'
import { WorkerProvider } from './contexts/WorkerContext'

function App() {

  return (
      <Box>
        <BrowserRouter>
          <DashboardLayout key='dashboardLayout'>
            <Routes>
              <Route path='/' element={<Dashboard />} />
              <Route path='/login' element={<Login />} />
              <Route path='/scheduler' element={<SchedulerProvider>
                <Scheduler />
              </SchedulerProvider>} />
              <Route path='/certificate' element={<Certificates />} />
              <Route path='/jobs' element={
                <JobProvider>
                  <Jobs />
                </JobProvider>
              } />
              <Route path='/workers/create' element={
                <WorkerProvider>
                  <CreateOrUpdateWorker />
                </WorkerProvider>
              } />
              <Route path='/workers/:id' element={
                <WorkerProvider>
                  <CreateOrUpdateWorker />
                </WorkerProvider>
              } />
              <Route path='/workers' element={<Workers />} />
              <Route path='/jobs/create' element={<CreateJob />} />
              <Route path='/jobs/:id' element={<CreateJob />} />
            </Routes>
          </DashboardLayout>
        </BrowserRouter>
      </Box>
  )
}


export default App
