import { Box, FormHelperText, Typography } from '@mui/material'
import { gql, useMutation, useQuery } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import {
  AppBorderColor,
  AppTextColor,
  DefaultIconColor,
  PrimaryColor,
} from 'src/theme/Colors'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'
import ButtonWithBorder from 'src/components/Button/ButtonWithBorder'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import Modal from 'src/components/Modal'
import { useContext, useState } from 'react'
import FileDropZone from 'src/components/Dropzone'
import { GetCertificatesByNameSearch } from 'src/core/gql/queries/certificate'
import AutoCompleteSearch from 'src/components/AutoComplete/AutoCompleteSearch'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { WorkerContext } from 'src/contexts/WorkerContext'
import { UploadFile } from 'src/core/api'
import { useAuth0 } from '@auth0/auth0-react'
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useParams } from 'react-router-dom'
import { GetSingleUser } from 'src/core/gql/queries/users'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '63vh',
  },
  bottomContainer: {
    borderTop: `1px solid ${AppBorderColor}`,
    width: '100%',
    paddingTop: '10px',
  },
  bottomText: {
    fontSize: '13px',
    color: AppTextColor,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '16px',
    color: PrimaryColor,
    marginRight: '2px',
  },
}))

const validationSchema = yup.object({
  certification: yup.string().required('Certificataion is required'),
  dateOfIssue: yup.string().required('Issue date is required.'),
})

const AddCertificate = () => {
  const { id: workerId } = useParams()
  const { certificates } = useContext(WorkerContext)
  const { getAccessTokenSilently } = useAuth0()
  const [insertCert] = useMutation(
    gql`
      mutation InsertUserCert(
        $user: String!
        $cert: Int!
        $filename: uuid!
        $issueDate: timestamptz
      ) {
        insert_user_certification_one(
          object: {
            user_id: $user
            certification_id: $cert
            file_uri: $filename
            date_received: $issueDate
          }
        ) {
          user_id
          certification_id
        }
      }
    `,
    { refetchQueries: [GetSingleUser] }
  )
  const [openModal, setOpenModal] = useState(false)
  const classes = useStyles()
  const { loading, data } = useQuery(GetCertificatesByNameSearch)
  const [error, setError] = useState(null)
  const handleChange = newValue => {
    formik.setFieldValue('dateOfIssue', newValue)
  }

  const formik = useFormik({
    initialValues: {
      certification: '',
      dateOfIssue: '',
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (values.file === null) {
        setError('Please upload a document')
      } else {
        const file: File = values.file
        let { filename } = await UploadFile(
          file,
          await getAccessTokenSilently()
        )
        if (filename) {
          try {
            await insertCert({
              variables: {
                user: workerId,
                cert: values.certification,
                filename: filename,
                issueDate: values.dateOfIssue,
              },
            })
            formik.resetForm()
            setOpenModal(false)
          } catch (e: any) {
            setError(`Uploading ${file.name} failed. ${(e as Error).message}`)
          }
        } else {
          setError(`Uploading ${file.name} failed.`)
        }
      }
    },
  })

  const CertificateItem = (cer, i) => {
    return (
      <Box
        key={i}
        sx={{
          display: 'flex',
          padding: '7px',
          marginTop: '10px',
          alignItems: 'center',
          border: `1px solid ${AppBorderColor}`,
          borderRadius: '10px',
        }}
      >
        <Typography
          sx={{
            color: AppTextColor,
            fontSize: '13px',
            fontWeight: '700',
            width: '60%',
          }}
        >
          {cer.certification.name}
        </Typography>
        <Box
          sx={{ width: '30%', display: 'flex', alignItems: 'center' }}
          gap={'10px'}
        >
          <AccessAlarmOutlinedIcon sx={{ color: DefaultIconColor }} />
          <Typography sx={{ color: AppTextColor, fontSize: '13px' }}>
            Issued {new Date(cer?.date_received).toLocaleDateString()} Valid{' '}
            {cer.certification.duration ?? ' forever'}
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box className={classes.container}>
      <Box sx={{ width: '100%' }}>
        <ButtonWithBorder
          label={'Add New Certificate'}
          Icon={UploadFileOutlinedIcon}
          onClick={() => setOpenModal(!openModal)}
        />
        <Box>{certificates.map((c, i) => CertificateItem(c, i))}</Box>
      </Box>
      <Box className={classes.bottomContainer}>
        <Typography className={classes.bottomText}>
          <HelpCenterOutlinedIcon className={classes.icon} />
          The user must be created before their certifications can be added.
        </Typography>
      </Box>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        title={'Add Certification'}
        cancelButtonTitle="Cancel"
        acceptButtonTitle="Save Certificate"
        handleAccept={() => formik.handleSubmit()}
        handleClose={() => {
          formik.resetForm()
          setOpenModal(!openModal)
        }}
      >
        <Box rowGap={'10px'} display="flex" flexDirection={'column'}>
          <AutoCompleteSearch
            placeholder={'Type to search or Select...'}
            setData={val => formik.setFieldValue('certification', val.id)}
            label="Certification"
            options={data?.certification}
            optionLabel={'name'}
            loading={loading}
            isError={Boolean(formik.errors.certification)}
            helperText={
              Boolean(formik.errors.certification) &&
              'Certification is required'
            }
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            columnGap="10px"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of Issue"
                inputFormat="MM/dd/yyyy"
                value={formik.values.dateOfIssue}
                onChange={handleChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={Boolean(formik.errors.dateOfIssue)}
                    helperText={
                      Boolean(formik.errors.dateOfIssue) && 'Date is required'
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <FileDropZone
            label="Upload Certification File"
            onDrop={files => {
              setError(null)
              formik.setFieldValue('file', files[0])
            }}
            file={formik.values.file}
            reset={() => formik.setFieldValue('file', null)}
          />
          <FormHelperText error={error !== null}>{error}</FormHelperText>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddCertificate
