const AppBackground = '#F7F8FC'
const CardBackground = '#FFFFFF'
const ActiveTab = '#001533'
const AppBorderColor = '#C3CCD9'
const AppTextColor = '#27344E'
const DefaultIconColor = '#8697AB'
const AppErrorColor = '#d32f2f'
const PrimaryColor = '#3076FF'

export {
  AppBackground,
  CardBackground,
  ActiveTab,
  AppBorderColor,
  AppTextColor,
  DefaultIconColor,
  AppErrorColor,
  PrimaryColor
}
