import { Box } from '@mui/material'
import React, { useState } from 'react'
import Navbar from 'src/components/Layout/Navbar'
import Sidebar from 'src/components/Layout/Sidebar'
import withAuth from '../HOC/Auth'
import { AppBackground } from 'src/theme/Colors'
import { useLocation } from 'react-router-dom'
import { LayoutProvider } from 'src/contexts/layoutContext'

const Layout = ({ children }) => {
  const location = useLocation()
  const drawerWidth = 72
  const navbarHeight = 74
  return (
    <LayoutProvider>
      <Box >
        {
          location.pathname === '/login' ? null :
            <LayoutComponent drawerWidth={drawerWidth} navbarHeight={navbarHeight} />
        }
        <Box
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, flexGrow: 1, p: 2, pt: `${navbarHeight + 20}px`, background: AppBackground }}
          component='main'
        >
          {children}
        </Box>
      </Box>
    </LayoutProvider>
  )
}

const LayoutComponent = ({ drawerWidth, navbarHeight }) => {
  const [activeTab, setActiveTab] = useState('Dashboard')
  return (
    <>
      <Navbar drawerWidth={drawerWidth} navbarHeight={navbarHeight} activeTab={activeTab} />
      <Sidebar drawerWidth={drawerWidth} setActiveTab={(tab) => setActiveTab(tab)} activeTab={activeTab} />
    </>
  )
}

export default React.memo(withAuth(Layout))
