import { gql } from '@apollo/client'

export const GetCertificatesTags = gql`
query GetCertificatesTags(
  $where: certification_tag_bool_exp
) {
    certification_tag(where: $where) {
        name
        comment
    }
  }
`