export const TABS = {
  Dashboard: 'Dashboard',
  Scheduler: 'Scheduler',
  Jobs: 'Jobs',
  Worker: 'Workers',
  Certificates: 'Certificates',
}

export const GetAbbrivation = (value: string) => {
  if (value) {
    var split_names = value.trim().split(' ')
    let abbraivation = ''
    split_names.map((name) => {
      abbraivation = `${abbraivation}${name.charAt(0)}`
    })
    return abbraivation.toUpperCase()
  }
  return ''
}

export const JOB_STATUS = {
  Pending: 'PENDING_PROVIDER',
  'Pending Worker': 'PENDING_WORKERS',
  'PENDING_WORKERS': 'Pending Worker',
  Past: 'COMPLETED',
  Cancelled: 'CANCELLED',
  Active: 'READY',
  PENDING_PROVIDER: 'Pending',
  COMPLETED: 'Past',
  READY: 'Active',
  Disputed: 'DISPUTED'
}

export const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

export const generateArrayOfYears = () => {
  let max = new Date().getFullYear()
  let min = max - 20
  let years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export const PhoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const GenerateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8)
      return v.toString(16)
  })
}

export const STATES = [
  {
      'name': 'Alabama',
      'abbreviation': 'AL'
  },
  {
      'name': 'Alaska',
      'abbreviation': 'AK'
  },
  {
      'name': 'American Samoa',
      'abbreviation': 'AS'
  },
  {
      'name': 'Arizona',
      'abbreviation': 'AZ'
  },
  {
      'name': 'Arkansas',
      'abbreviation': 'AR'
  },
  {
      'name': 'California',
      'abbreviation': 'CA'
  },
  {
      'name': 'Colorado',
      'abbreviation': 'CO'
  },
  {
      'name': 'Connecticut',
      'abbreviation': 'CT'
  },
  {
      'name': 'Delaware',
      'abbreviation': 'DE'
  },
  {
      'name': 'District Of Columbia',
      'abbreviation': 'DC'
  },
  {
      'name': 'Federated States Of Micronesia',
      'abbreviation': 'FM'
  },
  {
      'name': 'Florida',
      'abbreviation': 'FL'
  },
  {
      'name': 'Georgia',
      'abbreviation': 'GA'
  },
  {
      'name': 'Guam',
      'abbreviation': 'GU'
  },
  {
      'name': 'Hawaii',
      'abbreviation': 'HI'
  },
  {
      'name': 'Idaho',
      'abbreviation': 'ID'
  },
  {
      'name': 'Illinois',
      'abbreviation': 'IL'
  },
  {
      'name': 'Indiana',
      'abbreviation': 'IN'
  },
  {
      'name': 'Iowa',
      'abbreviation': 'IA'
  },
  {
      'name': 'Kansas',
      'abbreviation': 'KS'
  },
  {
      'name': 'Kentucky',
      'abbreviation': 'KY'
  },
  {
      'name': 'Louisiana',
      'abbreviation': 'LA'
  },
  {
      'name': 'Maine',
      'abbreviation': 'ME'
  },
  {
      'name': 'Marshall Islands',
      'abbreviation': 'MH'
  },
  {
      'name': 'Maryland',
      'abbreviation': 'MD'
  },
  {
      'name': 'Massachusetts',
      'abbreviation': 'MA'
  },
  {
      'name': 'Michigan',
      'abbreviation': 'MI'
  },
  {
      'name': 'Minnesota',
      'abbreviation': 'MN'
  },
  {
      'name': 'Mississippi',
      'abbreviation': 'MS'
  },
  {
      'name': 'Missouri',
      'abbreviation': 'MO'
  },
  {
      'name': 'Montana',
      'abbreviation': 'MT'
  },
  {
      'name': 'Nebraska',
      'abbreviation': 'NE'
  },
  {
      'name': 'Nevada',
      'abbreviation': 'NV'
  },
  {
      'name': 'New Hampshire',
      'abbreviation': 'NH'
  },
  {
      'name': 'New Jersey',
      'abbreviation': 'NJ'
  },
  {
      'name': 'New Mexico',
      'abbreviation': 'NM'
  },
  {
      'name': 'New York',
      'abbreviation': 'NY'
  },
  {
      'name': 'North Carolina',
      'abbreviation': 'NC'
  },
  {
      'name': 'North Dakota',
      'abbreviation': 'ND'
  },
  {
      'name': 'Northern Mariana Islands',
      'abbreviation': 'MP'
  },
  {
      'name': 'Ohio',
      'abbreviation': 'OH'
  },
  {
      'name': 'Oklahoma',
      'abbreviation': 'OK'
  },
  {
      'name': 'Oregon',
      'abbreviation': 'OR'
  },
  {
      'name': 'Palau',
      'abbreviation': 'PW'
  },
  {
      'name': 'Pennsylvania',
      'abbreviation': 'PA'
  },
  {
      'name': 'Puerto Rico',
      'abbreviation': 'PR'
  },
  {
      'name': 'Rhode Island',
      'abbreviation': 'RI'
  },
  {
      'name': 'South Carolina',
      'abbreviation': 'SC'
  },
  {
      'name': 'South Dakota',
      'abbreviation': 'SD'
  },
  {
      'name': 'Tennessee',
      'abbreviation': 'TN'
  },
  {
      'name': 'Texas',
      'abbreviation': 'TX'
  },
  {
      'name': 'Utah',
      'abbreviation': 'UT'
  },
  {
      'name': 'Vermont',
      'abbreviation': 'VT'
  },
  {
      'name': 'Virgin Islands',
      'abbreviation': 'VI'
  },
  {
      'name': 'Virginia',
      'abbreviation': 'VA'
  },
  {
      'name': 'Washington',
      'abbreviation': 'WA'
  },
  {
      'name': 'West Virginia',
      'abbreviation': 'WV'
  },
  {
      'name': 'Wisconsin',
      'abbreviation': 'WI'
  },
  {
      'name': 'Wyoming',
      'abbreviation': 'WY'
  }
]

export const WORKERS_AVAILBILITY_TPYE = {
    1099: '1099',
    W2: 'W2'
}
