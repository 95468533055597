import { gql } from '@apollo/client'

export const UPDATE_JOBS_POSITIONS = gql`
  mutation updateJobPosition(
    $userId: String!
    $jobId: Int!
    $positionId: Int!
  ) {
    update_position(
      _set:{ fulfilled_by_user_id: $userId},
       where:{job_id: {_eq: $jobId},offers: {position: {id: {_eq: $positionId}}} }){
    returning{
      job_id
    }
  }
  }
`
export const UPDATE_JOB_POSITION_BY_PK = gql`
 mutation updateJobPositionByPK(
    $userId: String
    $positionId: Int!
  ) {
  update_position_by_pk(_set: {
      fulfilled_by_user_id: $userId
  }, pk_columns: {
    id:  $positionId
  }){
    job_id
    id
    fulfilled_by_user{
      name
      id
    }
  }
}
`
export const UPDATE_JOB = gql`
mutation updateJob(
  $set: job_set_input
  $jobId: Int!
) {
  update_job_by_pk(_set: $set, pk_columns: {id: $jobId}) {
    id
    status
  }
}
`

export const UPDATE_JOB_WITH_CONTACT = gql`
mutation updateJob(
  $set: job_set_input
  $jobId: Int!
  $contactSet: contact_set_input 
  $contactId: Int!
) {
  update_job_by_pk(_set: $set, pk_columns: {id: $jobId}) {
    id
    status
  }

  update_contact_by_pk(_set: $contactSet, pk_columns: {id: $contactId}){
    id
  }
}
`

export const CREATE_JOB = gql`
mutation createJob(
  $object: job_insert_input!
) {
  insert_job_one (object: $object) {
      id
  }
}
`