import { useLazyQuery, useQuery } from '@apollo/client'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import Table from 'src/components/Table'
import {GetCertCount, GetCertificates} from 'src/core/gql/queries/certificate'
import { AppBorderColor, AppTextColor, DefaultIconColor } from 'src/theme/Colors'
import Card from 'src/components/Card'
import { GetAbbrivation } from 'src/utils/Constants'
import CreateCertificate from './components/CreateCertificate'
import { useEffect, useRef, useState } from 'react'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'
import Notify from 'src/components/Toast'
import EditCertificate from './components/EditCertificate'
import { getQueryParam, setQueryParams } from 'src/utils/query'
import { useAuth0 } from '@auth0/auth0-react'
import { GetSingleUser } from 'src/core/gql/queries/users'
const IconRender = (Icon, iconClickHandler, row) => {
  return (
    <IconButton aria-label='settings' sx={{ padding: '5px' }} onClick={() => iconClickHandler(row)}>
      <Icon fontSize='small' sx={{ color: DefaultIconColor }} />
    </IconButton>
  )
}

const renderTagsList = (tags: any) => {
  return (
    <Box display={'flex'} flexWrap={'wrap'} columnGap="4px" maxWidth={'fit-content'}>
      {tags.length ? tags.map((t, i) => (
        <Typography
          fontSize={'14px'}
          color={AppTextColor}
          sx={{ border: `2px solid ${AppBorderColor}`, p: '3px', mt: '4px', borderRadius: '5px', backgroundColor: '#fff' }}
          key={i}
        >
          {t.tag.name}
        </Typography>
      )) :
        <>-</>
      }
    </Box>
  )
}

const renderAbbrivation = (name: string) => {
  return (
    <Box width={'fit-content'}>
      <Typography fontSize={'14px'} fontWeight="600" sx={{ padding: '5px 8px', borderRadius: '16px', backgroundColor: '#E9EBF4' }}>{GetAbbrivation(name)}</Typography>
    </Box>
  )
}

const Certificates = () => {
  const userData = useAuth0()
    const userId= userData.user.sub
    const [getSingleUser,{ data: singleUserData }] = useLazyQuery(GetSingleUser)
    useEffect(()=>{
        getSingleUser({
            variables: {
            id: userId
            }
    })
    },[getSingleUser, userId])
    const columns = (iconClickHandler) => [
    { name: 'Name', key: 'name', numaric: false, isSortable: true },
    { name: 'Tags', key: 'tags', customRender: (tags) => renderTagsList(tags) },
    { name: 'Abbreviations', key: 'name', isSortable: true, customRender: (name: string) => renderAbbrivation(name), numaric: false },
    {
      name: 'Action',
      key: 'action',
      component: (row) => (
        <>
          {/*{IconRender(InfoOutlinedIcon)}*/}
          {row.company_id && singleUserData?.user[0]?.roles?.find(item => item.role === 'CERT_MANAGER')?.company_id === row.company_id && (
          IconRender(ModeEditOutlinedIcon, iconClickHandler, row)
          )}
          {/*{IconRender(DeleteOutlineOutlinedIcon)}*/}
        </>
      )
    }
  ]

  const firstCallDone = useRef(false)
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [response, setResponse]: any = useState({
    type: '',
    message: '',
    show: false
  })

  const { loading, data, refetch } = useQuery(GetCertificates, {
    variables: {
      where: {
      },
      limit:rowsPerPage,
      offset: rowsPerPage * (page)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const count = useQuery(GetCertCount, {
    variables: {},
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [searchQuery, setSearchQuery] = useState('')

  useEnhancedEffect(() => {
    if (firstCallDone.current) {
      refetch({
        where: { name: { _ilike: `%${searchQuery}%` } },
        limit: rowsPerPage,
        offset: 0
      })
    } else {
      firstCallDone.current = true
    }

  }, [searchQuery, refetch])

  const iconClickHandler = (event) => {
    setQueryParams({ key: 'id', value: event.id })
    setOpen(true)
  }

  useEffect(() => {
    const id = getQueryParam('id')
    if (id) {
      setOpen(true)
    }
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            title="Registered Certificates"
            isSearchRequired={true}
            value={searchQuery}
            onSearchChange={value => setSearchQuery(value)}
          >
            {!count.loading&&<Table
              columns={columns(iconClickHandler)}
              data={data?.certification}
              loading={loading}
              // onRowClick={(row) => console.log(row) }
              isPaginationRequired={true}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={count.data.certification_aggregate.aggregate.count}
            />}
          </Card>
        </Grid>
      </Grid>
      <CreateCertificate setResponse={setResponse}  refetchAllCertificates={refetch}/>
      {open && <EditCertificate refetchAllCertificates={refetch} setResponse={setResponse} open={open} setOpen={setOpen}/>}
      <Notify defaultOpen={response.show} message={response.message} type={response.type} />
    </>
  )
}

export default Certificates

