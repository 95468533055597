

import { gql } from '@apollo/client'

export const GetAllUsers = gql`
  query GetAllUsers(
  $where: user_bool_exp 
  $offset: Int
  $limit: Int
  ) {
  user(where: $where, limit: $limit, offset: $offset) {
    id
    name
    email
    first_name
    phone_number
    last_name
    city
    state
    company {
      name
    }
    certifications {
      certification_id
      certification {
        id
        name
        description
        duration
      }
    }
    jobs {
      id
    }
  }
}
`
export const GetAllUsersFiltered = gql`
  query GetAllUsers(
  $where: user_bool_exp 
  $offset: Int
  $limit: Int
  $user_id: String
  ) {
  user(where: {
        _and: [
          {
            company: {
              user_roles: {
                _and: [
                  { user_id: { _eq: $user_id } }
                  {
                    _or: [
                      { role: { _eq: POSITION_OFFERER } }
                      { role: { _eq: POSITION_ASSIGNER } }
                    ]
                  }
                ]
              }
            }
          }
          { roles: { role: { _eq: POSITION_ACCEPTOR } } }
        ]
      },
    limit: $limit, offset: $offset) {
    id
    name
    email
    first_name
    phone_number
    last_name
    city
    state
    company {
       user_roles{
        role
      }
      name
      id
    }
    certifications {
      certification_id
      certification {
        id
        name
        description
        duration
      }
    }
    jobs {
      id
    }
  }
}
`

export const GetSingleUser = gql`
query GetSingleUser($id: String!) {
  user(where: {id: {_eq: $id}}) {
    id
    name
    email
    first_name
    phone_number
    company{
      id
      name
    }
    roles {
      role
      company_id
    }
    jobs{
      status
      id
      fulfilled_by_company{
        name
      }
      scheduled_time
      site{
        address
        name
      }
    }
    last_name
    city
    state
    certifications{
      certification_id
      is_verified
      date_received
      certification{
        id 
        name
        description
        duration
      }
    }
  }
}
`
export const GetUserRole = gql`
query GetUserRole($id: String!) {
  user_by_pk(id: $id) {
    position_offers {
      user {
        roles {
          role
          company_id
        }
        company_id
      }
      position {
        job {
          fulfilled_by_company {
            id
          }
        }
      }
    }
  }
}
`
export const GetUserCount = gql`
query GetUserCount(
  $where: user_bool_exp 
  ) {
  user(where: $where) {
    id
  }
}
`