import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

type Props = {
  text: string,
  Icon: any,
}

const useStyles: any = makeStyles(() => ({
  container: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  title: {
    color: '#001533',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    letterSpacing: '0.08em !important',
    lineHeight: '125%',
    textTransform: 'capitalize'
  },
  icon: {
    marginRight: 10,
    opacity: 0.7,
  },
}))


export default function IconText({ text, Icon }: Props) {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Icon width={26} height={26} className={classes.icon} />
      <Typography className={classes.title}>{text}</Typography>
    </Box>
  )
}