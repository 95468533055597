import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import { makeStyles } from '@mui/styles'
import { MoreVert, Close } from '@mui/icons-material'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ButtonBase from '@mui/material/ButtonBase'
import JobStatusDropDown from 'src/components/Inputs/JobStatusDropDown'
import MoreMenu from 'src/components/Scheduler/MoreMenu'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from 'src/assets/images/DSPTCH_logo.svg'
const useStyles = makeStyles((theme) => ({
  dialogActions: {
    background: 'white',
    justifyContent: 'space-around',
    '& button': {
      background: 'blue',
      color: 'white',
      opacity: '0.7',
      fontWeight: 500,
      letterSpacing: '2px',
      '&:hover': {
        opacity: '1',
        fontWeight: 700,
        background: '#5555e9'
      }
    }
  },
  mobileLinks: {
    borderBottom: '1px solid #1976d2',
    cursor: 'pointer'
  },
  rootBox: {
    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
  },
  titleContainer: {
    padding: '0px !important',
    background: '#F7F8FC',
    border: 'none',
  },
  titleContentContainer: {
    width: '100%',
    padding: '24px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerProfileAvatar: {
    marginRight: 10,
    border: '1px solid #3076FF',
    background: 'rgba(48, 118, 255, 0.1)',
    color: '#3076FF',
  },
  dialogTitle: {
    fontSize: '18px',
    color: '#252626',
    fontWeight: '800'
  },
  dialogSubTitle: {
    fontSize: '16px',
    color: '#AEAEAE',
    fontWeight: '400'
  },
  dialogTitleRoot: {
    width: '90% !important',
    flexDirection: 'row',
    display: 'flex',
  },
  headerStatus: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    margin: '0px 12px',
    borderRadius: '40px',
    '& > button': {
      color: 'white',
      background: 'inherit',
      border: 'none',
      minHeight: '15px',
      lineHeight: '0px',
      minWidth: '160px',
      margin: '0px',
      '&:hover': {
        background: 'none'
      }
    },
    '& ul': {
      // margin: '0px',
      border: 'none',
      minWidth: '150px',
      padding: '0px',
      // color: 'white'
      // border: '1px solid black'
    }
  },
  headerStatusText: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '100%',
    margin: '0px 4px',
    color: '#FFFFFF',
  },
  headerStatusIcon: {
    color: '#FFFFFF',
    width: '18px',
    height: '18px',
  },
  iconsContainer: {
    width: '10% !important',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex'
  },
  buttonsContainer: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  cancelButton: {
    // marginRight: 20
  },
  okButton: {
    color: 'white'
  },
  headerStatusId: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '100% !important',
  },
  headerStatusLabel: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '100% !important'
  },
  paper: {
    minWidth: '70vw !important',
    height: '70vw !important',
  }
}))

type IShareDialog = {
  open: boolean;
  setOpen: Function;
  id: string;
  title: string;
  children: any;
  rootClass?: any;
  showActions?: boolean;
  subtitle?: string;
  statusClasses?: any;
  statusLabel?: string;
  type: string;
}

export default function ShareDialog({
  open,
  setOpen,
  id,
  title,
  children,
  rootClass,
  showActions,
  subtitle,
  statusClasses,
  statusLabel,
  type
}: IShareDialog) {

  const navigation = useNavigate()

  const handleClose = (event: React.SyntheticEvent<unknown> | MouseEvent | TouchEvent, reason?: string) => {
    setOpen(false)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const getStatusBackgroundColor = () => {
    if (statusLabel === 'READY') return '#009c05'
    else if (statusLabel === 'COMPLETED') return '#00de07'
    else if (statusLabel === 'PENDING_WORKERS') return '#f4af6a'
    else if (statusLabel === 'PENDING_WORKERS') return '#f4af6a'
    else return 'red'
    // else if (statusLabel === 'COMPLETED') return '#FFFFFF'
  }

  const editJob = () => {
    handleCloseMenu()
    navigation(`/jobs/${id}`)
    setOpen(false)
  }

  const stringAvatar = (name) => {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  }

  const classes = useStyles()
  return (
    <Box>
      {
        open ?
          <ClickAwayListener onClickAway={handleClose}>
            <Box classes={classes.rootBox}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={editJob}>Edit Job</MenuItem>
                <MenuItem onClick={handleCloseMenu}>Company Profile</MenuItem>
                <MenuItem onClick={handleCloseMenu}>Copy Job Link</MenuItem>
              </Menu>
              <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
                <Box className={rootClass}>
                  <DialogTitle className={classes.titleContainer} >
                    <Box className={classes.titleContentContainer}>
                      {type === 'Job' ?
                       <Avatar sx={{ bgcolor: '#F7F8FC' }}><Logo/></Avatar>
                       :
                       <Avatar  className={classes.headerProfileAvatar} {...stringAvatar(title)}/>
                      }
                      <Box className={classes.dialogTitleRoot}>
                        <Typography className={classes.dialogTitle}>{title}</Typography>
                        {statusLabel && <Box
                          className={classes.headerStatus}
                          sx={{ background: getStatusBackgroundColor() }}
                        >
                          <AccessTimeOutlinedIcon className={classes.headerStatusIcon} />
                          <JobStatusDropDown jobId={id} status={statusLabel} />
                          {/* <Typography className={classes.headerStatusText}>{statusLabel}</Typography> */}
                        </Box>}
                      </Box>
                      <Box className={classes.iconsContainer}>
                        <MoreMenu
                          menuItems={type === 'Job'
                            ? [{
                              label: 'Edit Job', handler: () => {
                              editJob()
                             }, icon: <RemoveOutlinedIcon style={{ marginRight: '8px' }} /> }]
                            : [{ label: 'Edit Worker', handler: () => navigation(`/workers/${id}`), icon: <AccountCircleOutlinedIcon style={{ marginRight: '8px' }} /> }]}
                        />
                        <Close onClick={handleClose} />
                      </Box>
                    </Box>
                  </DialogTitle>
                  <DialogContent sx={{ padding: '24px 40px' }}>
                    {children}
                  </DialogContent>
                  {showActions && <DialogActions className={classes.dialogActions} sx={{ flexDirection: { md: 'row', xs: 'column' } }}>
                    <Box className={classes.buttonsContainer}>
                      <Button classes={classes.cancelButton} fullWidth onClick={handleClose}>Cancel</Button>
                      <Button fullWidth classes={classes.okButton} onClick={handleClose}>Ok</Button> </Box>
                  </DialogActions>}
                </Box>
              </Dialog>
            </Box>
          </ClickAwayListener>
          :
          null
      }
    </Box>
  )
}
