import {
  Grid,
  Box,
  Button,
  Typography,
  Stack,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import withAuth from '../HOC/Auth'

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { isLoading } = useAuth0()
  return (
    <>
      {isLoading ?
        <Box className='googleLoader' />
        :
        null
      }
      <div style={{
        opacity: isLoading ? '0.6' : '1',
        pointerEvents: isLoading ? 'none' : 'all',
      }} >
        <Grid container spacing={2}  >
          <Grid item md={12} xs={12} sm={12}>
            <Box
              style={{
                display: 'flex',
                height: '99vh',
                alignItems: 'center',
                width: '100%'
              }}>
              <Box width={700}
                p={2} margin={'40px auto'}
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

                }}
              >
                <Stack spacing={2}>
                  <Typography textAlign='center' variant='h3'>
                    RNWBL Login
                  </Typography>

                  <Button
                    type="submit"
                    onClick={() => onSubmit()}
                    variant="contained"
                    fullWidth
                    size='large'
                    color={'primary'}
                    sx={{
                      marginTop: '32px',
                      height: '56px',
                    }}
                  >
                    Login
                  </Button>
                </Stack>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export type LoginFormProps = {
  onSubmit: Function
};

export default withAuth(LoginForm, { isPublic: true })
