import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, FormControl, Typography } from '@mui/material'
import { AppTextColor } from 'src/theme/Colors'

type AutoCompleteSearchProps = {
    options: any
    optionLabel: string
    loading: boolean
    label: string
    setData: any
    placeholder: string
    isError?: boolean
    helperText?: string
}

export default function AutoCompleteSearch({
    options,
    optionLabel,
    loading,
    label,
    setData,
    placeholder,
    isError,
    helperText
}: AutoCompleteSearchProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <Autocomplete
        id="asynchronous-autocomplete"
        fullWidth
        open={open}
        onOpen={() => {
            setOpen(true)
        }}
        onClose={() => {
            setOpen(false)
        }}
        getOptionLabel={(option) => option[optionLabel]}
        renderOption={(props, option: any) => (
            <Box component="li" {...props} key={option.id}>
              {option[optionLabel]}
            </Box>
          )}
        options={options}
        loading={loading}
        onChange={(e, val) => setData(val)}
        renderInput={(params) => {
            return (
                <FormControl variant="standard" fullWidth>
                    <Typography sx={{fontSize: '12px', fontWeight: '700', marginBottom: '5px', color: AppTextColor}}>
                        {label}
                    </Typography>
                    <TextField
                        {...params}
                        error={isError}
                        helperText={helperText}
                        fullWidth
                        placeholder={placeholder}
                        InputProps={{
                            style: {
                                paddingTop: '2px',
                                paddingBottom: '2px'
                            },
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        }}
                    />
                </FormControl>
            )}
        }
    />
  )
}