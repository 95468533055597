import * as React from 'react'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TuneSharpIcon from '@mui/icons-material/TuneSharp'
import { AppTextColor } from 'src/theme/Colors'
import CustomAccordion from '../Accordion'
import { Badge, Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, Stack, TextField } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GetDistinctJobStatus } from 'src/core/gql/queries/job'
import { useSchedulerContext } from 'src/contexts/SchedulerContext'
import { GetDistinctCertificates } from 'src/core/gql/queries/certificate'
import { Search } from '@mui/icons-material'

type ICustomMenu = {
  open: boolean
  anchorEl: any
  handleClose: any
  children: any
  variant: any
}

export const CustomMenu = ({ open, anchorEl, handleClose, children, variant }: ICustomMenu) => {
  return <Menu
    anchorEl={anchorEl}
    id="account-menu"
    open={open}
    onClose={handleClose}
    // onClick={handleClose}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          [variant]: 14,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: variant, vertical: 'top' }}
    anchorOrigin={{ horizontal: variant, vertical: 'bottom' }}
  >
    {children}
  </Menu>
}

export default function CustomFilter({ anchorEl, setAnchorEl, children }) {
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <TuneSharpIcon sx={{ color: AppTextColor }} />
        </IconButton>
      </Box>
      {
        children
      }
    </React.Fragment>
  )
}


export const JobsFilter = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { filters, setFilters, applyFilters, companies } = useSchedulerContext()

  const changedFilter = (event, key) => {
    let updatedCompanyFilters = {
      ...filters,
      [key]: {
        ...filters[key],
        [event.target.value]: event.target.checked
      }
    }
    if (!event.target.checked) {
      // }else {
      delete updatedCompanyFilters[key]?.[event.target.value]
    }
    setFilters(updatedCompanyFilters)
  }

  const clickFilters = () => {
    handleClose()
    applyFilters()
  }
  const resetFilters = () => {
    handleClose()
    setFilters({})
    applyFilters()
  }

  React.useEffect(() => {
    companies.getAllCompanies()
  }, [])

  return (
    <CustomFilter anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        variant='left'
      >
        <MenuItem>
          <Typography fontWeight={700}>
            Filter Jobs
          </Typography>
        </MenuItem>
        <CustomAccordion headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>POSITION STATUS</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(filters?.full_filled_by_user || {}).length || 0} ></Badge>
          </Box>
        }>
          <FormGroup>
            {
              ['Invited', 'Accepted'].map((status) => <FormControlLabel
                key={status}
                control={<Checkbox checked={Boolean(filters?.full_filled_by_user?.[status])} value={status} onChange={(event) => changedFilter(event, 'full_filled_by_user')} />}
                label={status}
              />)
            }
          </FormGroup>
        </CustomAccordion>
        <CustomAccordion headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>SCHEDULE STATUS</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(filters?.status || {}).length || 0} ></Badge>
          </Box>}>
          <FormGroup>
            {
              ['Scheduled', 'Pending'].map((status) => <FormControlLabel
                key={status}
                control={<Checkbox checked={Boolean(filters?.status?.[status])} value={status} onChange={(event) => changedFilter(event, 'status')} />}
                label={status}
              />)
            }
          </FormGroup>
        </CustomAccordion>
        <CustomAccordion detailsHeight='30vh !important' headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>COMPANY</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(filters?.requested_by_company_id || {}).length || 0} ></Badge>
          </Box>}>
          <FormGroup>
            {
              companies?.data?.map(({ name, id }) => <FormControlLabel
                key={id}
                control={<Checkbox checked={Boolean(filters?.requested_by_company_id?.[id])} value={id} onChange={(event) => changedFilter(event, 'requested_by_company_id')} />}
                label={name}
              />)
            }
          </FormGroup>
        </CustomAccordion>
        <MenuItem disableRipple={true} >
          <Stack flexDirection='row' width='100%' >
            <Button variant='outlined' fullWidth color='primary' sx={{ mx: 1 }} onClick={resetFilters}>Reset All</Button>
            <Button variant='contained' fullWidth color='primary' sx={{ mx: 1 }} onClick={clickFilters}>Apply Filters</Button>
          </Stack>
        </MenuItem>
      </CustomMenu>
    </CustomFilter>)
}

export const WorkersFilter = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [searchValue, setSearchValue] = React.useState('')
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { workers, companies } = useSchedulerContext()
  const { data } = useQuery(GetDistinctCertificates)

  const { workersFilters, setWorkersFilters, applyWorkerFilters } = workers || {}

  const changedFilter = (event, key) => {
    let updatedUserFilters: any

    updatedUserFilters = {
      ...workersFilters,
      [key]: {
        ...workersFilters[key],
        [event.target.value]: event.target.checked
      }
    }
    if (!event.target.checked) {
      delete updatedUserFilters[key]?.[event.target.value]
    }
    setWorkersFilters(updatedUserFilters)
  }

  const clickFilters = () => {
    handleClose()
    applyWorkerFilters()
  }
  const resetFilters = () => {
    handleClose()
    setWorkersFilters({})
    applyWorkerFilters()
  }

  React.useEffect(() => {
    companies.getAllCompanies()
  }, [])

  return (
    <CustomFilter anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        variant='right'
        handleClose={handleClose}
      >
        <MenuItem>
          <Typography fontWeight={700}>
            Filter Workers
          </Typography>
        </MenuItem>
        {/* <CustomAccordion headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>AVAILABILITY STATUS</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(workersFilters?.availabilityStatus || {})?.length || 0} ></Badge>
          </Box>
        }>
          <FormGroup>
            {
              ['Scheduled', 'Full Time Employee'].map((status) => <FormControlLabel
                key={status}
                control={<Checkbox checked={Boolean(workersFilters?.status?.[status])} value={status} onChange={(event) => changedFilter(event, 'availabilityStatus')} />}
                label={status}
              />)
            }
          </FormGroup>
        </CustomAccordion> */}
        <CustomAccordion detailsHeight='30vh !important' headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>COMPANY</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(workersFilters?.company_id || {})?.length || 0} ></Badge>
          </Box>}>
          <FormGroup>
            {
              companies?.data?.map(({ name, id }) => <FormControlLabel
                key={id}
                control={<Checkbox checked={Boolean(workersFilters?.company_id?.[id])} value={id} onChange={(event) => changedFilter(event, 'company_id')} />}
                label={name}
              />)
            }
          </FormGroup>
        </CustomAccordion>
        <CustomAccordion detailsHeight='20vh' headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>CERTIFICATION</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(workersFilters?.certifications || {})?.length || 0} ></Badge>
          </Box>}>
          <Box>
            <TextField
              value={searchValue}
              onChange={(event) => { setSearchValue(event.target.value) }}
              placeholder='Type to search and Select'
              variant='standard'
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Search />
                </InputAdornment>

              }} />
          </Box>
          <FormGroup>
            {
              data?.certification?.filter((certificate) => searchValue ? certificate.name.toLowerCase().includes(searchValue.toLowerCase()) : 1)?.map(({ name, id }, index) => <FormControlLabel
                key={id}
                control={<Checkbox checked={Boolean(workersFilters?.certifications?.[id])} value={id} onChange={(event) => changedFilter(event, 'certifications')} />}
                label={name}
              />)
            }
          </FormGroup>
        </CustomAccordion>
        {/* <CustomAccordion detailsHeight='20vh' headerBackground='white' defaultExpanded={false} accordionSummary={
          <Box display='flex' alignItems={'center'} width='100%'>
            <Typography fontWeight={700} color={'gray'}>STATUS</Typography>
            <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={Object.keys(workersFilters?.status || {})?.length || 0} ></Badge>
          </Box>}>
          <FormGroup>
            {
              ['Disabled', 'Active'].map((value, index) => <FormControlLabel
                key={value}
                control={<Checkbox checked={Boolean(workersFilters?.certifications?.[value])} value={value} onChange={(event) => changedFilter(event, 'status')} />}
                label={value}
              />)
            }
          </FormGroup>
        </CustomAccordion> */}
        <MenuItem disableRipple={true} >
          <Stack flexDirection='row' width='100%' >
            <Button variant='outlined' fullWidth color='primary' sx={{ mx: 1 }} onClick={resetFilters}>Reset All</Button>
            <Button variant='contained' fullWidth color='primary' sx={{ mx: 1 }} onClick={clickFilters}>Apply Filters</Button>
          </Stack>
        </MenuItem>
      </CustomMenu>
    </CustomFilter>)
}