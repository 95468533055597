import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'
import { Box } from '@mui/material'

function GeneralCardSkeleton() {

  return (
    <Card sx={{ maxWidth: 345, minWidth: 260, mt: 1 }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circular" width={50} height={50} />}
        title={
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ marginBottom: 4 }}
          />
        }
        subheader={
          <Skeleton animation="wave" height={20} width="40%" />
        }
      />
      <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={15} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={15} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  )
}

export default function CardSkeleton({ count = 2, ...rest }) {
  return (
    <Box {...rest}>
      {
        Array(count).fill(Math.floor(Math.random() * 1000)).map(() =>
          <GeneralCardSkeleton />
        )
      }
    </Box>
  )
}
