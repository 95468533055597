import { useEffect, useState } from 'react'
import { Box, Skeleton } from '@mui/material'
import Modal from 'src/components/Modal'
import CustomInput from 'src/components/Inputs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import { EDIT_CERTIFICATE } from 'src/core/gql/mutations/certificate'
import AutoComplete from 'src/components/AutoComplete'
import { GetCertificatesTags } from 'src/core/gql/queries/tags'
import GqlError from 'src/components/HOC/GQLError'
import { getQueryParam } from 'src/utils/query'
import { GET_CERTIFICATES_BY_ID } from 'src/core/gql/queries/certificate'

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Certification name is required')
    .min(8, 'Certification name should be of minimum 8 characters length')
})

type EditCertificateProps = {
  setResponse: any
  open: any
  setOpen: any
  refetchAllCertificates?: Function
}

const formatTags = (tags = []) => {
  return tags.map(({ tag }) => tag)
}

const EditCertificate = ({ setResponse, open, setOpen, refetchAllCertificates }: EditCertificateProps) => {
  const id = getQueryParam('id')
  const { loading: fetchCertificateLoading, data: openedCertificate, refetch } = useQuery(GET_CERTIFICATES_BY_ID, {
    variables: {
      id
    }
  })

  useEffect(() => {
    if (!fetchCertificateLoading && Object.keys(openedCertificate.certification_by_pk).length > 0) {
      formik.setValues({
        name: openedCertificate.certification_by_pk.name,
        description: openedCertificate.certification_by_pk.description,
      })
    }
  }, [fetchCertificateLoading])

  const [tagsData, setTagsData] = useState([])
  const [editCertificate, { loading }] = useMutation<any, any>(EDIT_CERTIFICATE, {
    onCompleted: () => {
      setOpen(false)
      refetch()
      // refetchAllCertificates()
      setResponse({
        type: 'success',
        message: 'Certification successfully Updated.',
        show: true
      })
    },
    onError: (e) => {
      setResponse({
        type: 'error',
        message: e.graphQLErrors[0].message,
        show: true
      })
    }
  })

  const { loading: TagsLoading, data, error } = useQuery(GetCertificatesTags, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })


  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, i) => {
      // const tags: any = {
      //   data:
      //     tagsData.map((tag) => ({
      //       tag: {
      //         data: {
      //           name: tag.name
      //         }
      //       }
      //     }))
      // }
      let set: any = {
        name: values.name,
        description: values.description,
      }
      // if (tags.data.length > 0) {
      //   object = {
      //     ...object,
      //     tags: tags
      //   }
      // }

      editCertificate({
        variables: {
          set,
          id
        }
      })
    },
  })

  if (error) return <GqlError error={error} />

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={`Edit Certification # ${id}`}
      cancelButtonTitle={'Cancel'}
      acceptButtonVarient='contained'
      acceptButtonTitle={'Update Certificate'}
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
    >
      <Box rowGap={'10px'} display="flex" flexDirection={'column'} mt='10px'>
        <CustomInput
          id='name'
          label={'Certification Name'}
          placeholder="Type here..."
          value={formik.values.name}
          onChangeHandler={formik.handleChange}
          isError={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <CustomInput
          id='description'
          label={'Description'}
          placeholder="Type here..."
          value={formik.values.description}
          onChangeHandler={formik.handleChange}
          helperText={`${200 - formik.values.description.length} characters Left`}
          maxLength={200}
        />
        {
          TagsLoading ?
            <Box display='flex' alignItems='center' columnGap={2} p={1} border='2px solid lightgray' borderRadius={'10px'}>
              <Skeleton animation="wave" height={40} width="30%" />
              <Skeleton animation="wave" height={40} width="30%" />
              <Skeleton animation="wave" height={40} width="30%" />

            </Box> :
            <AutoComplete
              currentValue={formatTags(openedCertificate?.certification_by_pk?.tags)}
              placeholder={'Add Tags...'}
              setData={setTagsData}
              label='Tags'
              options={data?.certification_tag}
              optionLabel={'name'}
              loading={TagsLoading} />

        }
      </Box>
    </Modal>
  )
}

export default EditCertificate

