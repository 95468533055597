import React from 'react'
import { Box, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { makeStyles } from '@mui/styles'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
    profile: {
        margin: '4px 20px 0px 0px'
    },
    dialogTitleRoot: {
        width: '90% !important'
    },
    dialogTitle: {
        fontSize: '16px',
        color: '#252626',
        fontWeight: '800'
    },
    dialogSubTitle: {
        fontSize: '14px',
        color: '#AEAEAE',
        fontWeight: '400'
    },
    commentBody: {
        fontSize: '16px',
        color: '#000000 !important',
        fontWeight: '400',
        margin: '10px 0px 20px 0px !important'
    },
    commentField: {
        height: '200px !important'
    },
    addNewComment: {
        fontSize: '14px',
        color: '#AEAEAE',
        fontWeight: '400',
        margin: '20px 0px 0px 0px !important'
    },
}))
export default function Comments() {
    const classes = useStyles()
    return (
        <Box>
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <Avatar className={classes.profile} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <Box className={classes.dialogTitleRoot}>
                    <Typography className={classes.dialogTitle}>Username</Typography>
                    <Typography className={classes.dialogSubTitle}>Access Type (Worker or RNWBL Admin)</Typography>
                </Box>
            </Box>
            <Typography className={classes.commentBody}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Exercitation veniam consequat sunt nostrud amet. Velit officia consequ.</Typography>
            <Divider />
            <Typography className={classes.addNewComment}>Add New Comment</Typography>
            <TextField
                id="input-with-icon-textfield"
                label="Type here"
                sx={{ width: '100%', maxWidth: '100%', margin: '10px 0px !important' }}
                variant="outlined"
                multiline
                rows={3}
            />
            <Button variant="contained">Send Comment</Button>
        </Box>
    )
}
