import { gql } from '@apollo/client'

export const CREATE_CERTIFICATE = gql `
    mutation createCertificate(
        $object: certification_insert_input!
    ) {
        insert_certification_one (object: $object) {
            name
            description
            tags {
            certification_tag_name
            certification_id
            certification {
                name
            }
            }
        }
    }
`


export const EDIT_CERTIFICATE = gql `
    mutation editCertification(
        $set: certification_set_input,
        $id: Int! 
    ) {
        update_certification_by_pk (_set: $set, pk_columns: {id : $id}) {
            name
            description
        }
    }
`
