import React from 'react'

import { Avatar, Badge, Box, Button, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import AddPositionPopup from 'src/components/Modal/AddPosition'
import JobFiles from 'src/components/JobFiles'
import { getQueryParam } from 'src/utils/query'
import { useJobContext } from 'src/contexts/JobContext'
import { ReactComponent as UserScanIcon } from 'src/components/icons/User-scan.svg'
import { MoreVert } from '@mui/icons-material'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import { DefaultIconColor } from 'src/theme/Colors'
import MoreMenu from 'src/components/Scheduler/MoreMenu'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
const useStyles = makeStyles(() => ({
  rightGrid: {
    width: '55%',
    display: 'flex',
  },
  rightCardHeader: {
    width: '100%'
  },
  createPositionHeader: {
    borderBottom: '1px solid #E0E4ED !important',
    height: '66px !important',
    padding: '16px 8px 8px 24px',
    width: '100%'
  },
  card: {
    background: '#FFFFFF'
  },
  addPositionButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px',
    position: 'static',
    height: '56px',
    left: '0px',
    top: '0px',
    background: '#F7F8FC',
    border: '1px dashed #C3CCD9',
    boxSizing: 'border-box',
    borderRadius: '8px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '12px 0px',
    justifyContent: 'flex-start',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
  },
}))

const AddedPosition = ({ position }) => {
  const classes = useStyles()
  return <Box
    className={classes.addPositionButton} sx={{
      border: '1px solid #E0E4ED !important',
      background: 'initial !important',

    }}>
    <Box width='100%' display='flex' columnGap={2} alignItems='center' justifyContent='space-between'>
      <Box display='flex' columnGap={1} alignItems='center'>
        <UserScanIcon sx={{ color: DefaultIconColor }} />
        <Typography color='black' fontWeight={700} fontSize={14}>{position.function}</Typography>
      </Box>
      <Box display='flex' columnGap={1} alignItems='center'>
        <LocalOfferOutlinedIcon sx={{ width: 12, height: 12, color: DefaultIconColor }} />
        <Typography>
          Certificates</Typography>
        <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={position?.certifications?.length || 0} ></Badge>
      </Box>
      <Avatar sx={{ ml: 10, border: '1px dashed #27344E', background: '#C3CCD9' }} />
      <MoreMenu
        menuItems={[
          // { label: 'Match Workers', handler: () => { }, icon: <AddCircleOutlineOutlinedIcon style={{ marginRight: '8px' }} /> },
          { label: 'Edit Position', handler: () => { }, icon: <ModeEditOutlineOutlinedIcon style={{ marginRight: '8px' }} /> },
          // { label: 'Duplicate Position', handler: () => { }, icon: <ContentCopyOutlinedIcon style={{ marginRight: '8px' }} /> },
          // { label: 'Delete Position', handler: () => { }, icon: <DeleteForeverOutlinedIcon style={{ marginRight: '8px' }} /> },
        ]}
      />
    </Box>
  </Box>
}

const CreatePosition = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState('1')
  const [open, setOpen] = React.useState(false)
  const jobId = getQueryParam('jobId')
  const { singleJob, addPosition, notify }: any = useJobContext()

  // const scheduledTime = getQueryParam('scheduled_time')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  return <Box sx={{ width: '100%', typography: 'body1' }} className={classes.card} >
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '53.32px' }}>
        <TabList onChange={handleChange} aria-label="custom tabs">
          <Tab label={<Typography variant='subtitle1' fontSize='14px' fontWeight={800}>JOB POSITIONS</Typography>} value={'1'} />
          <Tab label={<Typography variant='subtitle1' fontSize='14px' fontWeight={800}>FILES</Typography>} value={'2'} />
        </TabList>
      </Box>

      <TabPanel value={'1'}>
        {
          jobId ? <>
            <Button onClick={() => setOpen(true)} startIcon={<PersonAddAltOutlinedIcon sx={{ fontSize: '32px !important' }} />} fullWidth disableRipple className={classes.addPositionButton}  >Add New Position</Button>
            {
              singleJob?.data?.job_by_pk?.positions?.map((position) =>
                <AddedPosition key={position.id} position={position} />
              )
            }
          </> : <Typography>You are required to create a job First</Typography>
        }

        {
          open ?
            <AddPositionPopup open={open} setOpen={setOpen} addPosition={addPosition} notify={notify} />
            : null
        }
      </TabPanel>
      <TabPanel value={'2'}><JobFiles /></TabPanel>
    </TabContext>
  </Box >
}



export default CreatePosition