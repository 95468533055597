import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import TextGroup from '../TextGroup'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconText from '../IconText'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import BatteryChargingFullOutlinedIcon from '@mui/icons-material/BatteryChargingFullOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
export default function WorderDetails({ workerData }: any) {

  const useStyles: any = makeStyles(() => ({
    sectionHeadingText: {
      height: '18px',
      width: '226px',
      left: '8px',
      top: '12px',
      textAlign: 'left',
    },
    sectionHeadingBox: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    jobContainer: {
      width: '100%',
      height: '300px',
      marginTop: 20
    },
    jobDetailModalRoot: {
      width: '100%'
    },
    popUpBodyRowContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 20
    },
    rowContainer: {
      width: '33%'
    },
    certificationsTitle: {
      marginBottom: '10px !important',
      color: '#27344E',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    assignedWorkers: {
      marginBottom: '10px !important',
      color: '#A1A1A1',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    addIcon: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important'
    },
    addButton: {
      backgroundColor: '#A1A1A1 !important',
    },
    menuBodyContainer: {
      maxWidth: '400px !important',
      minWidth: '200px !important'
    },
    profileMenu: {
      margin: '0px 10px'
    },
    statusButton: {
      border: '1px solid red !important'
    },
    dropDownStatus: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '100% !important',
    },
    modalMainDescription: {
      backgroundColor: '#F7F8FC',
    },
    certContainer: {
      width: '100%',
      border: '1px solid #E0E4ED',
      borderRadius: '8px',
      height: '56px',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 20px'
    },
    certName: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '16px !important',
      textTransform: 'capitalize',
      letterSpacing: '2px'
    },
    certTitle: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '16px !important',
      textTransform: 'capitalize',
      letterSpacing: '2px'
    }
  }))

  const classes = useStyles()
// console.log(workerData)
  return (
    <Box>
      <Box className={classes.popUpBodyRowContainer}>
        <Grid container spacing={4} className={classes.modalMainDescription} >
          <Grid item xs={6}>
            <IconText
              text={workerData?.user[0]?.phone_number}
              Icon={LocalPhoneOutlinedIcon}
            />
            <IconText
              text={workerData?.user[0]?.email}
              Icon={MailOutlinedIcon}
            />
            <IconText
              text={`${workerData?.user[0]?.city}, ${workerData?.user[0]?.state}`}
              Icon={LocationOnOutlinedIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <IconText
              text={workerData?.user[0]?.company?.name}
              Icon={BatteryChargingFullOutlinedIcon}
            />
            <IconText
              text={'Function'}
              Icon={WorkOutlineOutlinedIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.popUpBodyRowContainerBottom}>
        <Box sx={{ width: '100%' }}>
          <Typography className={classes.certificationsTitle}>Valid Certifications</Typography>
          <Stack sx={{ width: '100%' }} spacing={1}>
            {workerData?.user[0]?.certifications.length > 0 && workerData?.user[0]?.certifications.map((item) => {
              return (
                <Box className={classes.certContainer}>
                  <InsertDriveFileOutlinedIcon fontSize='medium' sx={{ margin: '0px 10px 0px 0px' }} />
                  <Typography className={classes.certName}>{item?.certification?.name}</Typography>
                </Box>
              )
            })}
          </Stack>
          <Stack direction="row" spacing={1}>
            {workerData?.user[0]?.certifications.length === 0 && '--'}
          </Stack>
        </Box>
      </Box>
      {/* <Button variant="contained">Filter In Calendar</Button> */}
    </Box>
  )
}