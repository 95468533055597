import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { MoreVert } from '@mui/icons-material'
import { Typography } from '@mui/material'

export default function MoreMenu({ menuItems }) {

  return (
    <PopupState variant="popover" popupId="demo-popup-menu" style={{cursor: 'pointer'}}>
      {(popupState) => (
        <React.Fragment>
          <MoreVert {...bindTrigger(popupState)} />
          <Menu {...bindMenu(popupState)}>
            {
              menuItems.map(({ label, handler, icon }) =>
                <MenuItem style={{ display: 'flex' }} onClick={() => {
                  popupState.close()
                  handler()
                }}>
                  {icon}
                  <Typography>
                    {label}
                  </Typography>
                </MenuItem>
              )
            }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
