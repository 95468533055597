import * as React from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ClickAwayListener } from '@mui/base'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    background: 'white',
    justifyContent: 'space-around',
    '& button': {
      background: 'blue',
      color: 'white',
      opacity: '0.7',
      fontWeight: 500,
      letterSpacing: '2px',
      '&:hover': {
        opacity: '1',
        fontWeight: 700,
        background: '#5555e9'
      }
    }
  },
  mobileLinks: {
    borderBottom: '1px solid #1976d2',
    cursor: 'pointer'
  },
  rootBox: {
    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
  },
  titleContainer: {
    padding: '0px !important'
  },
  titleContentContainer: {
    borderBottom: '1px solid',
    // width: '100%',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  profile: {
    marginRight: 10
  },
  dialogTitle: {
    fontSize: '18px',
    color: '#252626',
    fontWeight: '800'
  },
  dialogSubTitle: {
    fontSize: '16px',
    color: '#AEAEAE',
    fontWeight: '400'
  },
  dialogTitleRoot: {
    // width: '90% !important'
  },
  iconsContainer: {
    width: '10% !important',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex'
  },
  buttonsContainer: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  cancelButton: {
    // marginRight: 20
  },
  okButton: {
    color: 'white'
  },
  headerStatusId: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '100% !important',
  },
  headerStatusLabel: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '100% !important'
  },

}))

type IInviteConfirmation = {
  open: boolean;
  setOpen: Function;
  title?: string | any;
  children: any;
  rootClass?: any;
}

export default function InviteConfirmation({
  open,
  setOpen,
  title,
  children,
  rootClass,
}: IInviteConfirmation) {

  const handleClose = (event: React.SyntheticEvent<unknown> | MouseEvent | TouchEvent, reason?: string) => {
    setOpen(false)
  }

  const classes = useStyles()
  return (
    <Box>
      {
        open ?
          <ClickAwayListener onClickAway={handleClose}>
            <Box classes={classes.rootBox}>
              <Dialog open={open} onClose={handleClose} >
                <Box className={rootClass}>
                  <DialogTitle className={classes.titleContainer}>
                    <Box className={classes.titleContentContainer}>
                      <Box className={classes.dialogTitleRoot}>
                        {title}
                      </Box>
                      <Box className={classes.iconsContainer}>
                        <Close onClick={handleClose} />
                      </Box>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    {children}
                  </DialogContent>
                </Box>
              </Dialog>
            </Box>
          </ClickAwayListener>
          :
          null
      }
    </Box>
  )
}
