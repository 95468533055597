import * as React from 'react'
import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import { AppBorderColor, AppErrorColor, AppTextColor } from 'src/theme/Colors'

type DropdownProps = {
    label: string
    setData: any
    placeholder: string
    options: any
    id?: string
    value: any
    isError?: boolean
    optionLable?: string
    selectedLabel?: string
}

export default function Dropdown({
    label,
    setData,
    value,
    options,
    placeholder,
    id,
    isError,
    optionLable,
    selectedLabel
}: DropdownProps) {

  return (
    <FormControl variant="standard" fullWidth>
        <Typography sx={{fontSize: '14px', fontWeight: '700', marginBottom: '5px', color: isError ? 'red' : AppTextColor}}>
            {label}&nbsp;
        </Typography>
        <Select
            id={id??'drop-down-custom'}
            value={value}
            variant="outlined"
            onChange={(e) => {
                setData(e.target.value)
            }}
            placeholder={placeholder}
            inputProps={{
                style: {
                    color: AppTextColor,
                    fontSize: '12px'
                }
            }}
            SelectDisplayProps={{
               style: {paddingTop: '9px', paddingBottom: '8px',
                    border: `1px solid ${isError ? AppErrorColor : AppBorderColor}`
                }
            }}
        >
            {options.map((option, i) => (
                <MenuItem key={i} value={selectedLabel ? option[selectedLabel] : option}>{optionLable ? option[optionLable] : option}</MenuItem>
            ))}
        </Select>
    </FormControl>
  )
}