import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import Breadcrumbs from 'src/components/Breadcrumbs'
import Card from 'src/components/Card'
import SubSection from 'src/components/SubSection'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import CustomInput from 'src/components/Inputs'
import { AppTextColor } from 'src/theme/Colors'
import AddCertificate from './AddCertificate'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { GenerateUUID, PhoneRegExp, STATES, WORKERS_AVAILBILITY_TPYE } from 'src/utils/Constants'
import { CREATE_WORKER, UPDATE_WORKER } from 'src/core/gql/mutations/user'
import { useLazyQuery, useMutation } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import { useContext, useEffect, useState } from 'react'
import { WorkerContext } from 'src/contexts/WorkerContext'
import Dropdown from 'src/components/Dropdown'
import { GetSingleUser } from 'src/core/gql/queries/users'
import { useParams, useNavigate } from 'react-router-dom'
import Loader from 'src/components/Loader'
import Notify from 'src/components/Toast'
const NewWorkerUrls = [{name: 'All Worker', url: '/workers'}, {name: 'New Worker'}]
const UpdateWorkerUrls = [{name: 'All Worker', url: '/workers'}, {name: 'Update Worker'}]

const CreateOrUpdateWorker = () => {
    const navigation = useNavigate()
    const { certificates, setCertificates } = useContext(WorkerContext)
    const {id} = useParams()
    const [refetchWorker, { loading: singleWorkerLoading, data: singleWorkerData }] = useLazyQuery(GetSingleUser)
    const [response, setResponse]: any = useState({
        type: '',
        message: '',
        show: false
      })

    const [updateWorker, { loading: updateWorkerLoading }] = useMutation<any, any>(UPDATE_WORKER, {
        onCompleted: () => {
           setResponse({
               type: 'success',
               message: 'Worker successfully updated.',
               show: true
           })
        },
        onError: (e) => {
            setResponse({
              type: 'error',
              message: e.graphQLErrors[0].message,
              show: true
            })
        }
    })

    const [createWorker, { loading }] = useMutation<any, any>(CREATE_WORKER, {
        onCompleted: () => {
            setResponse({
                type: 'success',
                message: 'Worker successfully created.',
                show: true
            })
        },
        onError: (e) => {
            setResponse({
              type: 'error',
              message: e.graphQLErrors[0].message,
              show: true
            })
          }
    })

    useEffect(() => {
        if(id) {
            getWorkerData(id)
        }
    }, [id])

    useEffect(() => {
        if(singleWorkerData) {
            const user = singleWorkerData.user[0]
            let values = {
                firstname: user.first_name,
                lastname: user.last_name,
                city:  user.city,
                state: user.state,
                email: user.email,
                company: user.company?.name,
                phonenumber: user.phone_number,
                availbilityType: WORKERS_AVAILBILITY_TPYE[1099]
            }
            formik.setValues(values)

            setCertificates([...user.certifications])
        }
    }, [singleWorkerData])

    const getWorkerData =  async (id: any) => {
        await refetchWorker({
            variables: { id }
        })
    }

    const validationSchema = yup.object({
        firstname: yup
            .string()
            .required('Firstname is required'),
        lastname: yup
            .string()
            .required('Lastname is required'),
        city: yup
            .string()
            .required('City is required'),
        state: yup
            .string()
            .required('State is required'),
        email: yup
            .string()
            .required('Email is required')
            .email('Invalid email format'),
        company: yup
            .string()
            .required('Company is required'),
        phonenumber: yup
            .string()
            .matches(PhoneRegExp, 'Phone number is not valid')
            .required('Phone number is required')
      })

      const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            city: '',
            state: '',
            email: '',
            company: '',
            phonenumber: '',
            availbilityType: WORKERS_AVAILBILITY_TPYE[1099]
        },
        validationSchema: validationSchema,

        onSubmit: (values, i) => {
            let company = {
                data: {
                    name: values.company
                }
            }
            const certificateData: any = {
                data:
                    certificates.map((c) => ({
                        certification_id: c.certification,
                        file_uri: c.filename,
                        date_received: getTimeStamp(c.dateOfIsssueMonth, c.dateOfIsssueYear)
                    }))
                }

            let object: any = {
                name: `${values.firstname} ${values.lastname}`,
                first_name: values.firstname,
                last_name: values.lastname,
                city: values.city,
                state: values.state,
                phone_number: values.phonenumber,
                email: values.email,
            }

            let creationObj:  any = {
                ...object,
                id: GenerateUUID(),
                certifications: certificateData,
                company: company,
            }

            if(id) {
                updateWorker({
                    variables: {
                        userId: id,
                        set: {
                            id: id,
                            ...object
                        }
                    }
                })
            } else {
                createWorker({
                    variables: {
                        object: creationObj
                    }
                })
            }
        },
    })

    const getTimeStamp = (month, year) => {
        return new Date( year, getMonthFromString(month) - 1, 1)
    }

    const getMonthFromString = (mon) => {
        var d = Date.parse(mon + '1, 2012')
        if(!isNaN(d)){
           return new Date(d).getMonth() + 1
        }
        return -1
      }

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                {singleWorkerLoading ?
                  <Loader />
                :
                    <>
                    <Breadcrumbs urls={id ? UpdateWorkerUrls : NewWorkerUrls}/>
                    <Box display={'flex'} gap={'15px'} marginTop={2}>
                    <Grid item md={5}>
                        <Card title={id ? 'Update Worker' : 'New Worker'}>
                            <Box sx={{height: '63vh', overflow: 'scroll'}}>
                                <SubSection heading={'General Information'} Icon={AccountCircleOutlinedIcon} >
                                    <Grid container spacing={2} item>
                                        <Grid md={6} item>
                                            <CustomInput
                                                id='firstname'
                                                label='First Name'
                                                placeholder={'Type here...'}
                                                value={formik.values.firstname}
                                                onChangeHandler={formik.handleChange}
                                                isError={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                                helperText={formik.touched.firstname && formik.errors.firstname}
                                            />
                                        </Grid>
                                        <Grid md={6} item>
                                            <CustomInput
                                                id='lastname'
                                                label='Last Name'
                                                placeholder={'Type here...'}
                                                value={formik.values.lastname}
                                                onChangeHandler={formik.handleChange}
                                                isError={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                                helperText={formik.touched.lastname && formik.errors.lastname}
                                            />
                                        </Grid>
                                        <Grid md={6} item>
                                            <CustomInput
                                                id='city'
                                                label='Home City'
                                                placeholder={'E.g. Albany'}
                                                value={formik.values.city}
                                                onChangeHandler={formik.handleChange}
                                                isError={formik.touched.city && Boolean(formik.errors.city)}
                                                helperText={formik.touched.city && formik.errors.city}
                                            />
                                        </Grid>
                                        <Grid md={6} mt="5px" item>
                                            <Dropdown
                                                id='state'
                                                label="State"
                                                value={formik.values.state}
                                                placeholder='State'
                                                setData={(val) => {
                                                    formik.setFieldValue('state', val)
                                                }}
                                                options={STATES}
                                                optionLable='name'
                                                selectedLabel='abbreviation'
                                                isError={formik.touched.state && Boolean(formik.errors.state)}
                                            />
                                        </Grid>
                                        <Grid md={6} item>
                                            <CustomInput
                                                id='phonenumber'
                                                label='PhoneNumber'
                                                placeholder={'E.g. +1 (000) 000-0000'}
                                                value={formik.values.phonenumber}
                                                onChangeHandler={formik.handleChange}
                                                isError={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                                                helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                                            />
                                        </Grid>
                                        <Grid md={6} item>
                                            <CustomInput
                                                id='email'
                                                label='Email'
                                                placeholder={'E.g. yourname@email.com'}
                                                value={formik.values.email}
                                                onChangeHandler={formik.handleChange}
                                                isError={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email   && formik.errors.email}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubSection>
                                <SubSection heading={'Professional Details'} Icon={BusinessCenterOutlinedIcon} >
                                    <CustomInput
                                        label='Company'
                                        id="company"
                                        value={formik.values.company}
                                        onChangeHandler={formik.handleChange}
                                        isError={formik.touched.company && Boolean(formik.errors.company)}
                                        helperText={formik.touched.company && formik.errors.company}
                                    />
                                    <Typography fontSize={14} mt="10px" fontWeight="700" color={AppTextColor}>
                                        Availability Type
                                    </Typography>
                                    <Typography fontSize={16} mt="5px" color={AppTextColor}>
                                        W2 workers are employees, and 1099 workers are contractors
                                    </Typography>

                                    <FormControl sx={{marginTop: '10px'}}>
                                        <RadioGroup
                                            aria-labelledby="radio-buttons-group-label-worker"
                                            value={formik.values.availbilityType}
                                            name='availbilityType'
                                            onChange={(e) => {
                                                formik.setFieldValue('availbilityType', e.target.value)
                                            }}
                                        >
                                            <FormControlLabel value="1099" control={<Radio />} label="1099" />
                                            <FormControlLabel value="W2" control={<Radio />} label="W2" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Grid p="10px 0px" container spacing={2}>
                                        <Grid md={6} item>
                                            <Button
                                                type='button'
                                                variant="outlined"
                                                onClick={() => {navigation('/workers')}}
                                                sx={{width: '100%'}}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid md={6} item>
                                            <LoadingButton
                                                variant={'outlined'}
                                                loading={loading || updateWorkerLoading}
                                                onClick={() => formik.handleSubmit()}
                                                fullWidth
                                            >
                                                {id ? 'Update Worker' : 'Invite Worker'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </SubSection>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={7}>
                        <Card title={'Add Certification'}>
                            <AddCertificate />
                        </Card>
                    </Grid>
                    <Notify defaultOpen={response.show} message={response.message} type={response.type} />
                    </Box>
                    </>
                }

            </Grid>
        </Grid>
    )
}

export default CreateOrUpdateWorker

