import React, {useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import IconText from '../IconText'
import Avatar from '@mui/material/Avatar'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import moment from 'moment'

export default function JobDetails({jobData, statusLabel}: any) {

  const useStyles: any = makeStyles(() => ({
    sectionHeadingText: {
      height: '18px',
      width: '226px',
      left: '8px',
      top: '12px',
      textAlign: 'left',
    },
    sectionHeadingBox: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    jobContainer: {
      width: '100%',
      height: '300px',
      marginTop: 20
    },
    jobDetailModalRoot: {
      width: '100%'
    },
    popUpBodyRowContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 20
    },
    rowContainer: {
      width: '33%'
    },
    certificationsTitle: {
      marginBottom: '10px !important',
      color: '#A1A1A1',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    assignedWorkers: {
      marginBottom: '10px !important',
      color: '#A1A1A1',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    addIcon: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important'
    },
    addButton: {
      backgroundColor: '#A1A1A1 !important',
    },
    menuBodyContainer: {
      maxWidth: '400px !important',
      minWidth: '200px !important'
    },
    profileMenu: {
      margin: '0px 10px'
    },
    statusButton: {
      border: '1px solid red !important'
    },
    dropDownStatus: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '100% !important',
    },
    modalMainDescription: {
      backgroundColor: '#F7F8FC',
    },
    spaceVertical: {
      padding: '20px 0px',
      borderStyle: 'solid',
      height: '50 px'
    },
    label: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#27344E',
      letterSpacing: '0.08em',
    },
    description: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      padding: '8px 0px',
      color: '#001533',
      letterSpacing: '0.02em',
    },
    assestsList: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    requestMembersCountContainer: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    requestMembersCount: {
      marginLeft: '15px',
      background: '#FFFFFF',
      border: '1px solid #E0E4ED',
      borderRadius: '8px',
      padding: '5px',
    },
    requestedWorkersCard: {
      flexDirection: 'row',
      display: 'flex',
      margin: '16px 0px',
      background: '#FFFFFF',
      border: '1px solid #E0E4ED',
      borderRadius: '8px',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px',
    },
    requestedWorkerAvatarContainer: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    requestedWorkerAcceptedStatus: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      background: 'rgb(0, 218, 100,0.1)',
      padding: '8px',
      borderRadius: '40px',
    },
    requestedWorkerAcceptedStatusText: {
      color: '#00B051',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '100%',
    },
    requestedWorkerAcceptedStatusIcon: {
      color: '#00B051',
    },
    requestedWorkerInvitedStatus: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      background: 'rgb(238, 132, 7,0.1)',
      padding: '8px',
      borderRadius: '40px',
    },
    requestedWorkerInvitedStatusText: {
      color: '#EE8407',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '100%',
    },
    requestedWorkerInvitedStatusIcon: {
      color: '#EE8407',
    },
    requestedWorkerAvatar: {
      marginLeft: '5px',
    },
    requestedWorkerType: {
      margin: '8px 0px',
      color: '#27344E',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '16px',
    },
  }))

  // let time = jobData?.job_by_pk ? jobData?.job_by_pk?.scheduled_time?.replace(')', ']').split('-') : null
  let time = jobData?.job_by_pk ? jobData?.job_by_pk?.scheduled_time?.replace(')', '').replace('[', '').replace('(', '').replace(']', '').split(',') : '--'
  const formattedTime = time ? moment(time[0]).format('DD MMM, YYYY') + ' - ' + moment(time[1]).format('DD MMM, YYYY') : '--'
  const classes = useStyles()
  const [certifications, setCertifications] = React.useState([])
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }
  // const [anchorElWorker, setAnchorElWorker] = React.useState<null | HTMLElement>(null)
  // const openWorker = Boolean(anchorElWorker)
  // const handleClickWorker = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorElWorker(event.currentTarget)
  // }
  // const handleCloseWorker = () => {
  //   setAnchorElWorker(null)
  // }
  //
  // const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null)
  // const openStatus = Boolean(anchorElStatus)
  // const handleClickStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorElStatus(event.currentTarget)
  // }
  // const handleCloseStatus = () => {
  //   setAnchorElStatus(null)
  //
  let fulfilledPositions = 0
  useEffect(() => {
    let certs = []
    if (Object.keys(jobData).length > 0) {
      if (jobData?.job_by_pk.positions.length > 0) {
        jobData?.job_by_pk.positions.map((item) => {
          item.certifications.length > 0 && item.certifications.map((certification) => {
            certs.push(certification)
          })
        })
      }
    }
    setCertifications(certs)
  }, [jobData])
  const renderUsersInvited = (offers) => {
    return (
      <Box sx={{width: '200px', display: 'flex'}}>
        {offers !== null &&
          offers.length > 0 &&
          offers.map((item, index) => {
            return (
              <Box display={index >= 2 ? 'none' : 'flex'} marginRight={1}>
                <Avatar
                  sx={{width: '40px', height: '40px'}}
                  alt={item.user.name}
                  src="/static/images/avatar/1.jpg"
                />
              </Box>
            )
          })}
        {offers !== null && offers.length > 2 && (
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '20px',
              backgroundColor: '#E1EBFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{fontSize: '12px', color: '#3076FF'}}>
              {offers.length - 2}{' '}
              +
            </Typography>
          </Box>
        )}
      </Box>
    )
  }
  const renderUsersAccepted = (position) => {
    return (
      <Box sx={{width: '200px', display: 'flex'}}>
        {position.fulfilled_by_user !== null &&
          (
            <Box display={'flex'} marginRight={1}>
              <Avatar
                sx={{width: '40px', height: '40px'}}
                alt={position.fulfilled_by_user.name}
                src="/static/images/avatar/1.jpg"
              />
            </Box>
          )
        }
      </Box>
    )
  }
  return (
    <Box sx={{padding: '20px 0'}}>
      <Grid container spacing={4} className={classes.modalMainDescription}>
        <Grid item xs={6}>
          <IconText
            text={`Job # ${jobData?.job_by_pk.id}`}
            Icon={CodeOutlinedIcon}
          />
          <IconText
            text={jobData?.job_by_pk?.created_by_user?.phone_number ? jobData?.job_by_pk?.created_by_user?.phone_number : '--'}
            Icon={PhoneOutlinedIcon}
          />
          <IconText
            text={jobData?.job_by_pk ? jobData?.job_by_pk?.created_by_user?.email ? jobData?.job_by_pk?.created_by_user?.email : '--' : '--'}
            Icon={MailOutlinedIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <IconText
            text={jobData?.job_by_pk?.site?.site_name ? jobData?.job_by_pk?.site?.site_name : '--'}
            Icon={LocationOnOutlinedIcon}
          />
          <IconText
            text={jobData?.job_by_pk?.site?.address ? jobData?.job_by_pk?.site?.address : '--'}
            Icon={LocationOnOutlinedIcon}
          />
          <IconText text={formattedTime} Icon={AccessTimeOutlinedIcon}/>
        </Grid>
      </Grid>

      <Box sx={{margin: '32px 0px'}}>
        <Typography className={classes.label}>
          {'JOB DESCRIPTION'}
        </Typography>
        <Typography className={classes.description}>
          {jobData?.job_by_pk?.description ? jobData.job_by_pk.description : '--'}
        </Typography>
      </Box>

      <Box sx={{margin: '32px 0px'}}>
        <Typography className={classes.label}>
          {'JOB ASSETS'}
        </Typography>
        <Box className={classes.assestsList}>
          {jobData?.job_by_pk?.assets.length > 0
            ? jobData.job_by_pk.assets.map((assets, index) => (
              <Typography key={'job-data-' + index} className={classes.description}>
                {index !== jobData.job_by_pk.assets.length - 1
                  ? `${assets.asset.name}, `
                  : `${assets.asset.name}`}
              </Typography>
            ))
            : <Typography className={classes.description}>{'--'}</Typography>
          }
        </Box>
      </Box>

      <Box sx={{margin: '32px 0px'}}>
        <Box className={classes.requestMembersCountContainer}>
          <Typography className={classes.label}>
            {'Positions'}
          </Typography>
          <Typography className={classes.requestMembersCount}>
            {jobData.job_by_pk?.positions.map((item) => {
              if (item.fulfilled_by_user !== null) {
                fulfilledPositions += 1
              }
            })}
            {fulfilledPositions}
            {'/'}
            {jobData.job_by_pk?.positions.length}
          </Typography>
        </Box>
      </Box>
      {jobData.job_by_pk.fulfilled_by_company !== null
        && jobData.job_by_pk?.positions.map((item) => {
          if (item.fulfilled_by_user !== null) {
            return (
              <Box className={classes.requestedWorkersCard}>
                <Box className={classes.requestedWorkerInfo}>
                  <Typography className={classes.requestedWorkerType}>
                    {item.function?.toUpperCase()}
                  </Typography>
                  <Box className={classes.requestedWorkerAcceptedStatus}>
                    <CheckCircleOutlineRoundedIcon className={classes.requestedWorkerAcceptedStatusIcon}/>
                    <Typography className={classes.requestedWorkerAcceptedStatusText}>
                      {'Accepted'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.requestedWorkerAvatarContainer}>
                  {renderUsersAccepted(item)}
                </Box>
              </Box>
            )
          } else if (item.offers.length > 0) {
            return (
              <Box className={classes.requestedWorkersCard}>
                <Box className={classes.requestedWorkerInfo}>
                  <Typography className={classes.requestedWorkerType}>
                    {item.function?.toUpperCase()}
                  </Typography>
                  <Box className={classes.requestedWorkerInvitedStatus}>
                    <CheckCircleOutlineRoundedIcon className={classes.requestedWorkerInvitedStatusIcon}/>
                    <Typography className={classes.requestedWorkerInvitedStatusText}>
                      {'Invited'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.requestedWorkerAvatarContainer}>
                  {renderUsersInvited(item?.offers)}
                </Box>
              </Box>
            )
          }
        })}
    </Box>
  )
}