import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { LayoutContext } from 'src/contexts/layoutContext'
import Modal from 'src/components/Modal'
import CustomInput from 'src/components/Inputs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { CREATE_CERTIFICATE } from 'src/core/gql/mutations/certificate'
import AutoComplete from 'src/components/AutoComplete'
import { GetCertificatesTags } from 'src/core/gql/queries/tags'
import GqlError from 'src/components/HOC/GQLError'
import { useAuth0 } from '@auth0/auth0-react'
import { GetSingleUser } from 'src/core/gql/queries/users'

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Certification name is required')
        .min(8, 'Certification name should be of minimum 8 characters length')
})

type CreateCertificateProps = {
    setResponse: any
    refetchAllCertificates: Function
}

const CreateCertificate = ({ setResponse,refetchAllCertificates }: CreateCertificateProps) => {
    const userData = useAuth0()
    const userId= userData.user.sub
    const [getSingleUser,{ data: singleUserData }] = useLazyQuery(GetSingleUser)
    useEffect(()=>{
        getSingleUser({
            variables: {
            id: userId
            }
    })
    },[getSingleUser, userId])
    const [tagsData, setTagsData] = useState([])
    const { certificateModalOpen, setCertificateModalOpen } = useContext(LayoutContext)
    const [insertCertificate, { loading }] = useMutation<any, any>(CREATE_CERTIFICATE, {
        onCompleted: () => {
            setCertificateModalOpen(false)
            setResponse({
                type: 'success',
                message: 'Certification successfully created.',
                show: true
            })
            refetchAllCertificates()
        },
        onError: (e) => {
            setResponse({
                type: 'error',
                message: e.graphQLErrors[0].message,
                show: true
            })
        }
    })

    const { loading: TagsLoading, data, error } = useQuery(GetCertificatesTags, {
        variables: {
            where: {
            }
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    })

    const checkExistingTag = (tag) =>{
        return  data?.certification_tag?.find((item) => item.name === tag.name)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, i) => {
            const tags: any = {
                data:
                    tagsData.map((tag) => (
                         checkExistingTag(tag) ? {
                     certification_tag_name: tag.name
                    } : {tag: {
                            data: {
                                name: tag.name
                            }
                        }
                    }
                    ))
            }
            let object: any = {
                name: values.name,
                description: values.description,
                company_id: singleUserData?.user[0]?.roles?.find(item => item.role === 'CERT_MANAGER')?.company_id,
            }
            if (tags.data.length > 0) {
                object = {
                    ...object,
                    tags: tags
                }
            }

            insertCertificate({
                variables: {
                    object: object
                }
            })
        },
    })

    if (error) return <GqlError error={error} />

    return (
        <Modal
            setOpen={() => setCertificateModalOpen(!certificateModalOpen)}
            open={certificateModalOpen}
            title={'New Certification'}
            cancelButtonTitle={'Cancel'}
            acceptButtonVarient='contained'
            acceptButtonTitle={'Save Certificate'}
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
        >
            <Box rowGap={'10px'} display="flex" flexDirection={'column'} mt='10px'>
                <CustomInput
                    id='name'
                    label={'Certification Name'}
                    placeholder="Type here..."
                    value={formik.values.name}
                    onChangeHandler={formik.handleChange}
                    isError={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <CustomInput
                    id='description'
                    label={'Description'}
                    placeholder="Type here..."
                    value={formik.values.description}
                    onChangeHandler={formik.handleChange}
                    helperText={`${200 - formik.values.description.length} characters Left`}
                    maxLength={200}
                />

                <AutoComplete placeholder={'Add Tags...'} setData={setTagsData} label='Tags' options={data?.certification_tag} optionLabel={'name'} loading={TagsLoading} />
            </Box>
        </Modal>
    )
}

export default CreateCertificate

