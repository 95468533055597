import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { AppBorderColor, CardBackground, AppTextColor } from 'src/theme/Colors'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import CustomButton from '../Button'
import { LayoutContext } from 'src/contexts/layoutContext'
import { TABS } from 'src/utils/Constants'
import CustomSelectComponent, { layoutRender } from 'src/components/Inputs/CustomSelect'
import availableLayouts from 'src/components/Scheduler/Layout'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { GetSingleUser } from 'src/core/gql/queries/users'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: `${CardBackground}`,
    color: `${AppTextColor}`
  }
}))

const Navbar = ({ drawerWidth, navbarHeight, activeTab }) => {

  const navigation = useNavigate()
  const classes = useStyles()
  const location = useLocation()
  const {
    certificateModalOpen,
    setCertificateModalOpen,
    layoutChangeHandler,
  } = useContext(LayoutContext)

  const CertifcateButton = () => {
    return (
      <CustomButton label={'New Certificate'} Icon={ViewWeekOutlinedIcon} onClick={() => setCertificateModalOpen(!certificateModalOpen)} />
    )
  }

  const JobsButton = () => {
    return (
      <CustomButton label={'Create New Job'} Icon={WorkOutlineIcon} onClick={() => navigation('/jobs/create')} />
    )
  }

  const WorkersButton = () => {
    return (
      <CustomButton label={'New Worker'} Icon={PersonAddAltOutlinedIcon} onClick={() => navigation('/workers/create')} />
    )
  }

  const SchedulerLayoutsSelect = (<Box display='flex' alignItems='center' justifyContent='flex-end'>
    <CustomSelectComponent options={availableLayouts} onChange={layoutChangeHandler} renderFunction={layoutRender} defaultSelected={null} />
  </Box>)
  const userData = useAuth0()
  const userId= userData.user.sub
  const [getSingleUser,{data: singleUserData }] = useLazyQuery(GetSingleUser)
  useEffect(()=>{
    getSingleUser({
        variables: {
          id: userId
        }
  })
  },[getSingleUser, userId])
  return (
    <>
      <CssBaseline />
      <AppBar
        classes={classes}
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, border: `1px solid ${AppBorderColor};`, boxShadow: 'none' }}
      >
        <Toolbar sx={{ height: navbarHeight }} >
          <Box display='flex' width='100%' alignItems='center' justifyContent='space-between'>
            <Typography variant="h5" fontWeight={'bold'} noWrap component="div">
              {activeTab}
            </Typography>
            {activeTab === TABS.Certificates && singleUserData?.user[0]?.roles?.find(item => item.role === 'CERT_MANAGER') &&(
              <CertifcateButton />
            )}
            {(activeTab === TABS.Jobs && location.pathname !== '/jobs/create') &&
              <JobsButton />
            }
            {activeTab === TABS.Scheduler &&
              SchedulerLayoutsSelect
            }
            {(activeTab === TABS.Worker && location.pathname !== '/workers/create') &&
              <WorkersButton />
            }
          </Box>
        </Toolbar>
      </AppBar>

    </>

  )
}
export default React.memo(Navbar)
