import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide, { SlideProps } from '@mui/material/Slide'
import { Alert, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
type TransitionProps = Omit<SlideProps, 'direction'>;

interface INotify {
  message?: string | any
  defaultOpen?: boolean
  type: 'error' | 'info' | 'success' | 'warning'
}

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction='right' />
}


const Notify = ({ message, defaultOpen, type = 'warning'}: INotify) => {
  const [open, setOpen] = React.useState(false)
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined)

  React.useEffect(() => {
    // console.log('TESITNG', defaultOpen)
    setOpen(defaultOpen)
  }, [defaultOpen])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {defaultOpen && <Snackbar
        style={{zIndex: 12345}}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={open}
        onClose={handleClose}
        autoHideDuration={20000}
        TransitionComponent={TransitionRight}
        key={transition ? transition.name : ''}
      >
        <Stack>
          <Alert
            sx={{display: 'flex', alignItems: 'center'}}
            severity={type}
            action={<IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small'/>
            </IconButton>}
          >{message}</Alert>

        </Stack>
      </Snackbar>}
    </div>
  )
}

export default Notify