import Button from '@mui/material/Button'

const CustomButton = ({label ,Icon, onClick} : ButtonProps) => {
    return (
        <Button
            type='button'
            variant="contained"
            endIcon={<Icon />}
            sx={{ fontWeight: '700'}}
            onClick={() => onClick()}
        >
            {label}
        </Button>
    )
}

export type ButtonProps = {
    onClick: Function
    label: String,
    Icon?: any
}
export default CustomButton