import * as React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { AppTextColor } from 'src/theme/Colors'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function BasicBreadcrumbs({urls}: any) {
    const navigation = useNavigate()
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, u: any) => {
        event.preventDefault()
        navigation(u.url)
      }

  return (
    <Stack>
        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowBackIosNewRoundedIcon sx={{color: AppTextColor, fontSize: '13px'}} />}>
            {urls?.map((u, i) => u.url ?
                (
                  <Link underline="hover" fontWeight={'500'} key={i} color={AppTextColor} href={`${u.url}`} onClick={(e) => handleClick(e, u)}>
                   {u.name}
                  </Link>
                )  : (
                    <Typography key={i} fontWeight={'500'} color={'#3076FF'}>{u.name}</Typography>
                ))
            }
        </Breadcrumbs>
    </Stack>
  )
}