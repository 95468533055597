import { ApolloError, NetworkStatus, useMutation, useLazyQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { UPDATE_JOB, UPDATE_JOB_POSITION_BY_PK } from 'src/core/gql/mutations/jobs'
import { GetAllJobs } from 'src/core/gql/queries/job'
import { GetAllCompanies } from 'src/core/gql/queries/company'
import { GetAllUsersFiltered } from 'src/core/gql/queries/users'
import { CREATE_POSITION_OFFER } from 'src/core/gql/mutations/positions'

type Children = JSX.Element | JSX.Element[] | string

type SchedulerContextValue = {
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<{}>>
  positionAssignData: any
  setPositionAssignData: Function
  workers: {
    workersFilters: any,
    setWorkersFilters: Function,
    applyWorkerFilters: Function
  }
  allJobs: {
    getAllJobs: Function
    loading: Boolean
    error: ApolloError | any
    data: any
    refetch: any
    applyJobSearch: Function
  },
  allWorkers: {
    getAllWorkers: Function
    loading: boolean,
    error: any,
    data: any,
    applyWorkerSearch
  },
  updateJob: {
    updateJobPositions: Function
    loading: Boolean
    error: ApolloError | any
    data: any
    responseMessage: any
  },
  sendPositionOffer: {
    sendPositionOffer: Function
    loading: Boolean
    error: ApolloError | any
    data: any
    responseMessage: any
  },
  updateJobGeneral: {
    updateJobGeneral: Function
    loading: Boolean
    error: ApolloError | any
    data: any
    responseMessage: any
  }
  applyFilters: Function
  companies: {
    getAllCompanies: Function,
    data: []
  }
}

const defaultSchedulerContextValue: SchedulerContextValue = {
  filters: {},
  setFilters: () => { },
  workers: {
    workersFilters: {},
    applyWorkerFilters: () => { },
    setWorkersFilters: () => { },
  },
  positionAssignData: null,
  setPositionAssignData: () => { },
  allJobs: {
    getAllJobs: () => { },
    loading: false,
    error: {},
    data: {},
    refetch: () => { },
    applyJobSearch: () => { }
  },

  allWorkers: {
    getAllWorkers: () => { },
    loading: false,
    error: {},
    data: {},
    applyWorkerSearch: () => { }
  },
  updateJob: {
    updateJobPositions: () => { },
    loading: false,
    responseMessage: {},
    error: {},
    data: {},
  },
  sendPositionOffer: {
    sendPositionOffer: () => { },
    loading: false,
    responseMessage: {},
    error: {},
    data: {},
  },
  updateJobGeneral: {
    updateJobGeneral: () => { },
    loading: false,
    responseMessage: {},
    error: {},
    data: {},
  },
  applyFilters: () => { },
  companies: {
    getAllCompanies: () => { },
    data: []
  }
}



export const SchedulerContext = React.createContext(defaultSchedulerContextValue)

const formatFilters: any = (filters) => {
  let formattedFilters = []
  Object.keys(filters).map(
    (key) => Object.keys(filters[key]).map((value) => {
      let valueToSet
      if (key === 'certifications') {
        valueToSet = [{ [key]: { certification_id: { _eq: value } } }]
      }

      else if (key === 'full_filled_by_user') {
        valueToSet = [{
          positions: {
            fulfilled_by_user_id: { _is_null: value === 'Invited' ? true : false }
          }
        }
        ]
      }
      else if (key === 'status') {
        if (value === 'Pending') {
          valueToSet = [
            { status: { _eq: 'PENDING_WORKERS' } },
            { status: { _eq: 'PENDING_PROVIDER' } },
            { status: { _eq: 'PENDING_SCHEDULE' } },
          ]
        }
        else if (value === 'Scheduled') {
          valueToSet = [
            { status: { _eq: 'COMPLETED' } },
            { status: { _eq: 'READY' } },
            { status: { _eq: 'CANCELLED' } },
          ]
        }
      }
      else {
        valueToSet = [{ [key]: { _eq: value } }]
      }
      formattedFilters = [...formattedFilters, ...valueToSet]
    }
    )
  )
  return formattedFilters.length > 0 ? { _or: formattedFilters } : {}
}


export const SchedulerProvider = ({ children }: { children: Children }) => {

  const [filters, setFilters] = React.useState<any>({})
  const [workersFilters, setWorkersFilters] = React.useState<any>({})
  const [updatePositionResponse, setUpdatePositionResponse] = React.useState<any>({})
  const [positionAssignData, setPositionAssignData] = React.useState<any>({})

  const { user } = useAuth0()

  const [getAllCompaniesQuery, { data: companyData, loading: allCompaniesLoading }] = useLazyQuery(GetAllCompanies)
  const [getAllUsersQuery, { loading: allWorkersLoading, error: allWorkersError, data: allWorkersData, refetch: refetchAllUsers, networkStatus: userNetworkStatus }] = useLazyQuery(GetAllUsersFiltered, {
    variables: {
      where: {},
      user_id: user.sub,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const [getAllJobsQuery, { loading: allJobsLoading, error: allJobsError, data: jobsItems = {}, refetch, networkStatus }] = useLazyQuery(GetAllJobs, {
    variables: {
      where: {}
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }
  )

  const getAllCompanies = () => {
    const { company } = companyData || {}
    if (!company || company?.length === 0) {
      getAllCompaniesQuery()
    }
  }

  const getAllWorkers = () => {
    const { user } = allWorkersData || {}
    if (!user || user?.length === 0) {
      getAllUsersQuery()
    }
  }

  const getAllJobs = () => {
    const { job } = jobsItems || {}
    if (!job || job?.length === 0) {
      getAllJobsQuery()
    }
  }

  const applyFilters = () => {
    refetch({
      where: formatFilters(filters)
    }
    )
  }
  const applyJobSearch = (value) => {
    let search: any = value ? [
      { description: { _iregex: value } },
    ] : []
    if (parseInt(value)) {
      search.push({ id: { _eq: parseInt(value) } })
    }
    let filter = filters?._or?.length > 0 ? formatFilters(filters)?._or : []
    const formattedFilterAndQuery = value ? {
      _or: [...filter, ...search]
    } : {}
    refetch(value && {
      where: formattedFilterAndQuery
    })
  }

  const applyWorkerSearch = (value) => {
    const search = value ? [
      { name: { _iregex: value } },
      { email: { _iregex: value } },
      { first_name: { _iregex: value } },
      { last_name: { _iregex: value } },
    ] : []
    let filter = filters?._or?.length > 0 ? formatFilters(filters)?._or : []
    const formattedFilterAndQuery = value ? {
      _or: [...filter, ...search]
    } : {}
    refetchAllUsers(value && {
      where: formattedFilterAndQuery
    }
    )
  }

  const applyWorkerFilters = async () => {
    const formattedData = await formatFilters(workersFilters)
    refetchAllUsers({
      where: formattedData
    })
  }

  const getDragDropDetails = () => {
    return {
      name: JSON.parse(positionAssignData?.draggableId)?.name,
      position: JSON.parse(positionAssignData?.destination?.droppableId)?.title
    }
  }

  const updateJobPositions = (variables) => {
    setPositionAssignData({
      ...positionAssignData,
      variables,
      operation: variables?.userId ? 'accepted' : 'removed from'
    })
    updateJob(variables)
  }

  const [updateJob, { loading: updatePositionLoading, error: updatePositionError, data }] = useMutation<any, any>
    (UPDATE_JOB_POSITION_BY_PK, {
      variables: {},
      onError: (e) => {
        setUpdatePositionResponse({ status: 'error', text: <>{e?.graphQLErrors?.[0]?.message}</> })
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      },
      onCompleted: () => {
        refetch({
          where: formatFilters(filters)
        })
        if (data?.update_position_by_pk == null) {
          setUpdatePositionResponse({
            status: 'error', text: <Box>
            Cannot assign position, please ensure you have privileges to take this action</Box>
          })
         } else {
          setUpdatePositionResponse({
            status: 'success', text: <Box>Job# {data?.update_position_by_pk?.id} updated successfully
              <Typography>{getDragDropDetails().name} accepted position Electric Engineer</Typography>
            </Box>
          })
        }
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      }
    })

  const [sendPositionOffer, { loading: sendPositionLoading, error: sendPositionError, data: sendPositionData }] = useMutation<any, any>
    (CREATE_POSITION_OFFER, {
      variables: {},
      onError: (e) => {
        setUpdatePositionResponse({ status: 'error', text: <>{e?.graphQLErrors?.[0]?.message}</> })
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      },
      onCompleted: (dataOnComplete) => {
        refetch({
          where: formatFilters(filters)
        })
        if (dataOnComplete?.insert_position_offer_one == null) {
          setUpdatePositionResponse({
            status: 'error', text: <Box>
            Cannot send invitation, please ensure you have privileges to take this action</Box>
          })
         } else {
          setUpdatePositionResponse({
            status: 'success', text: <Box>Job# {data?.update_position_by_pk?.id} updated successfully
              <Typography>Invitation sent to {getDragDropDetails().name} for position Electric Engineer</Typography>
            </Box>
          })
        }
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      }
    })

  const [updateJobGeneral, { loading: updatePositionGeneralLoading, error: updatePositionGeneralError, data: updatePositionData }] = useMutation<any, any>
    (UPDATE_JOB, {
      variables: {},
      onError: (e) => {
        setUpdatePositionResponse({ status: 'error', text: <>{e?.graphQLErrors?.[0]?.message}</> })
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      },
      onCompleted: () => {
        refetch({
          where: formatFilters(filters)
        })
        setUpdatePositionResponse({
          status: 'success', text: <Box>Job# {updatePositionData?.update_job_by_pk?.id} updated successfully
          </Box>
        })
        setTimeout(() => {
          setUpdatePositionResponse({ status: null, text: null })
        }, 20000)
      }
    })

  const values = {
    filters, setFilters,
    positionAssignData,
    setPositionAssignData,
    allJobs: {
      getAllJobs,
      loading: allJobsLoading || networkStatus === Number(NetworkStatus.refetch),
      error: allJobsError,
      data: jobsItems,
      refetch,
      applyJobSearch
    },
    allWorkers: {
      getAllWorkers,
      loading: allWorkersLoading || userNetworkStatus === Number(NetworkStatus.refetch),
      error: allWorkersError,
      data: allWorkersData,
      applyWorkerSearch
    },
    updateJob: {
      updateJobPositions,
      loading: updatePositionLoading,
      responseMessage: updatePositionResponse,
      error: updatePositionError,
      data: data,
    },
    sendPositionOffer: {
      sendPositionOffer,
      loading: sendPositionLoading,
      responseMessage: updatePositionResponse,
      error: sendPositionError,
      data: sendPositionData,
    },
    updateJobGeneral: {
      updateJobGeneral,
      loading: updatePositionGeneralLoading,
      responseMessage: updatePositionResponse,
      error: updatePositionGeneralError,
      data: updatePositionData,
    },
    applyFilters,
    companies: {
      getAllCompanies,
      data: companyData?.company || []
    },
    workers: {
      workersFilters,
      applyWorkerFilters,
      setWorkersFilters
    }
  }

  return (
    <SchedulerContext.Provider value={values}>
      {children}
    </SchedulerContext.Provider>
  )
}

export function useSchedulerContext() {
  const context = React.useContext(SchedulerContext)
  if (context === undefined) {
    throw new Error('useSchedulerContext must be used within an SchedulerProvider')
  }
  return context
}
