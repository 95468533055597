import config from 'src/utils/config'
import { GenerateUUID } from 'src/utils/Constants'

// TODO: have the backend assign a UUID to the file
// TODO: sniff mime types on the backend
export const UploadFile = async (
  file: File,
  token: string
): Promise<{ status: Number; filename?: string }> => {
  const fileName = GenerateUUID()
  const resp = await fetch(`${config.cloudfrontUri}/${fileName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      Authorization: `bearer ${token}`,
    },
    body: file,
  })

  return {
    status: resp.status,
    filename: resp.status === 200 ? fileName : null,
  }
}
