import { useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'
import Table from 'src/components/Table'
import { GetAllUsers, GetUserCount } from 'src/core/gql/queries/users'
import { AppBorderColor } from 'src/theme/Colors'
import Card from 'src/components/Card'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'
import { useNavigate } from 'react-router-dom'
import EditModel from 'src/components/EditModel'


const columns = [
  { name: 'Name', key: 'name', numaric: false, isSortable: true, isChecked: true },
  { name: 'Company', key: 'company', childKey: 'name', isChecked: true },
  { name: 'Home Address', key: 'city', isChecked: true, mergeRender: (row) => `${row.city}, ${row.state}`, isSortable: true },
  { name: 'Phone', key: 'phone_number', isChecked: true },
  { name: 'Email', key: 'email', isChecked: false },
  {
    name: 'Active Jobs', key: 'jobs', isChecked: true,
    renderContent: row => row.length === 0 ?
      <Typography sx={{ color: AppBorderColor, fontStyle: 'italic' }} >None.</Typography> :
      <Typography sx={{ fontSize: '14px', fontWeight: '700' }} >{row.length}</Typography>,
    align: 'center'
  },
  { name: 'Expired Certification', isChecked: true, key: '' },
  {
    name: 'Actions',
    isChecked: true,
    key: 'action',
    component : row => <EditModel id={row.id} path = {'/workers/'}/>
  },
]

const Workers = () => {
  const navigation = useNavigate()
  const firstCallDone = useRef(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const { loading, data, refetch } = useQuery(GetAllUsers, {
    variables: {
      where: {},
      limit:20,
      offset: rowsPerPage * (page === 0 ? 1 : page)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
   const count = useQuery(GetUserCount, {
    variables: {},
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [updatedColumns, setUpdatedColumns] = useState([...columns])
  const [columnsConfig, setColumnConfig] = useState([...columns])
  const [searchQuery, setSearchQuery] = useState('')

  useEnhancedEffect(() => {
    if (firstCallDone.current) {
      refetch({
         where: {name: { _ilike: `%${searchQuery}%` } },
         limit:20,
         offset: 0
      })
    } else {
      firstCallDone.current = true
    }
  }, [searchQuery, refetch])


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            title="Registered Workers"
            isSearchRequired={true}
            value={searchQuery}
            onSearchChange={value => setSearchQuery(value)}
            showFilterButton={true}
            updatedColumns={updatedColumns}
            setUpdatedColumns={setUpdatedColumns}
            setColumnConfig={setColumnConfig}
            defaultConfig={columns}
          >
           <Table
              isPaginationRequired={true}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              columns={columnsConfig.filter(f => f.isChecked)}
              data={data?.user}
              onRowClick={row => navigation(`/workers/${row.id}`)}
              loading={loading}
              total={count?.data?.user?.length}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Workers

