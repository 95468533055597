

import { gql } from '@apollo/client'

export const GetAllJobs = gql`
   query GetJobs($where: job_bool_exp, $limit: Int, $offset: Int ) {
  job(where: $where, limit: $limit, offset: $offset) {
    status
    created_at
    created_by_user_id
    fulfilled_by_company{
      users{
        name
      }
    }
    description
    status
    assets {
      asset_id
      asset {
        name
      }
    }
    created_by_user {
      first_name
      last_name
      email
      name
      phone_number
    }
    fulfilled_by_company_id
    id
    requested_by_company {
      name
    }
    requested_by_company_id
    scheduled_time
    site_id
    site {
      address
      name
    }
    type
    updated_at
    positions {
      created_at
      fulfilled_by_user {
        id
        name
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      offers {
        status
        user {
          name
          id
          email
        }
      }
      work_completed_status
      updated_at
      job {
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`
export const GetAllPendingJobs = gql`
 query GetAllPendingJobs ( 
   $where: job_bool_exp 
  $offset: Int
  $limit: Int
  ) {
  job(where: {status: {_eq: PENDING_WORKERS}}, limit: $limit, offset: $offset ) {
    status
    created_at
    created_by_user_id
    fulfilled_by_company{
      users{
        name
      }
    }
    description
    status
    assets {
      asset_id
      asset {
        name
      }
    }
    created_by_user {
      first_name
      last_name
      email
      name
      phone_number
    }
    fulfilled_by_company_id
    id
    requested_by_company {
      name
    }
    requested_by_company_id
    scheduled_time
    site_id
    site {
      address
      name
    }
    type
    updated_at
    positions {
      created_at
      fulfilled_by_user {
        id
        name
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      offers {
        status
        user {
          name
          id
          email
        }
      }
      work_completed_status
      updated_at
      job {
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`
export const GetAllActiveJobs = gql`
   query GetJobs {
  job(where: {status: {_eq: READY}}, limit: 20) {
    status
    created_at
    created_by_user_id
    fulfilled_by_company{
      users{
        name
      }
    }
    description
    status
    assets {
      asset_id
      asset {
        name
      }
    }
    created_by_user {
      first_name
      last_name
      email
      name
      phone_number
    }
    fulfilled_by_company_id
    id
    requested_by_company {
      name
    }
    requested_by_company_id
    scheduled_time
    site_id
    site {
      address
      name
    }
    type
    updated_at
    positions {
      created_at
      fulfilled_by_user {
        id
        name
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      offers {
        status
        user {
          name
          id
          email
        }
      }
      work_completed_status
      updated_at
      job {
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`
export const GetAllPastJobs = gql`
   query GetJobs {
  job(where: {status: {_eq: COMPLETED}}, limit: 20) {
    status
    created_at
    created_by_user_id
    fulfilled_by_company{
      users{
        name
      }
    }
    description
    status
    assets {
      asset_id
      asset {
        name
      }
    }
    created_by_user {
      first_name
      last_name
      email
      name
      phone_number
    }
    fulfilled_by_company_id
    id
    requested_by_company {
      name
    }
    requested_by_company_id
    scheduled_time
    site_id
    site {
      address
      name
    }
    type
    updated_at
    positions {
      created_at
      fulfilled_by_user {
        id
        name
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      offers {
        status
        user {
          name
          id
          email
        }
      }
      work_completed_status
      updated_at
      job {
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`

export const GetSingleJob = gql`
query GetSingleJob($id: Int!) {
  job_by_pk(id: $id) {
    created_at
    status
    created_by_user_id
    description
    fulfilled_by_company_id
    id
    fulfilled_by_company {
      name
      __typename
    }
    requested_by_company_id
    scheduled_time
    site_id
    type
    updated_at
    site {
      address
    }
    created_by_user {
      name
      email
      phone_number
    }
    site {
      address
      name
      id
    }
    contacts {
      contact_id
      contact {
        id
        name
        phone_number
        title
        email
      }
    }
    assets {
      asset_id
      asset {
        name
      }
    }
    positions {
      created_at
      fulfilled_by_user_id
      fulfilled_by_user{
        name
      }
      offers {
        user {
          name
          id
          email
        }
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      work_completed_status
      updated_at
      scheduled_time
      job {
        id
        description
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`

export const GetDistinctJobStatus = gql`
  query GetDistinctJobStatus {
    job(distinct_on: status){
      status
    }
  }
`

export const GetPendingJobsCount = gql`
  query GetPendingJobsCount {
      job_aggregate(where: {status: {_eq: PENDING_WORKERS}}) {
        aggregate {
          count
        }
      }
    }
`
export const GetReadyJobsCount = gql`
  query GetReadyJobsCount {
    job_aggregate(where: {status: {_eq: READY}}) {
      aggregate {
        count
      }
    }
  }
`
export const GetPastJobsCount = gql`
  query GetPastJobsCount {
    job_aggregate(where: {status: {_eq: COMPLETED}}) {
      aggregate {
        count
      }
    }
  }
`
export const GetAllJobsNoLimitCount = gql`
  query GetJobs($where: job_bool_exp){
    job(where: $where) {
      status
    }
  }
`
export const GetAllJobsNoLimit = gql`
   query GetJobs($where: job_bool_exp){
  job(where: $where) {
    status
    created_at
    created_by_user_id
    description
    status
    assets {
      asset_id
      asset {
        name
      }
    }
    created_by_user {
      first_name
      last_name
      email
      name
      phone_number
    }
    fulfilled_by_company_id
    id
    requested_by_company {
      name
    }
    requested_by_company_id
    scheduled_time
    site_id
    site {
      address
      name
    }
    type
    updated_at
    positions {
      created_at
      fulfilled_by_user {
        id
        name
      }
      function
      id
      job_id
      certifications {
        certification_id
        certification {
          description
          id
          name
        }
      }
      work_completed_status
      updated_at
      offers {
        user {
          name
          id
        }
      }
      job {
        created_at
        created_by_user {
          email
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
}
`

export const AllAssets = gql`
  query GetAssets{
    asset {
      name
      id
   }
}
`

export const AllSites = gql`
  query GetSites{
    site {
      name
      id
      address
    }
}
`

export const CompanySites = gql`
  query GetCompanySites($company_id: Int!){
    site(where: { company_id: {_eq: $company_id}}){
      name
      id
      address
    }
  }
`

export const SiteAssets = gql`
  query GetSiteAssets($site_id: Int!){
    asset(where: { site_id: {_eq: $site_id}}){
      name
      id
    }
  }
`

export const GetAllPendingJobsMinified = gql`
 query GetAllPendingJobsMinified ( 
   $where: job_bool_exp 
  $offset: Int
  $limit: Int
  ) {
  job(where: {status: {_eq: PENDING_WORKERS}}, limit: $limit, offset: $offset ) {
    fulfilled_by_company{
      users{
        name
      }
    }
    created_by_user {
      email
      phone_number
    }
    id
    requested_by_company {
      name
    }
    scheduled_time
    site {
      name
    }
    type
    positions {
      fulfilled_by_user {
        name
      }
      offers {
        status
        user {
          name
        }
      }
    }
  }
}
`

export const GetAllActiveJobsMinified = gql`
   query GetAllActiveJobsMinified {
  job(where: {status: {_eq: READY}}, limit: 20) {
    fulfilled_by_company{
      users{
        name
      }
    }
    created_by_user {
      email
      phone_number
    }
    id
    requested_by_company {
      name
    }
    scheduled_time
    site {
      name
    }
    type
    positions {
      fulfilled_by_user {
        name
      }
      offers {
        status
        user {
          name
        }
      }
    }
  }
}
`
export const GetAllPastJobsMinified = gql`
   query GetAllPastJobsMinified {
  job(where: {status: {_eq: COMPLETED}}, limit: 20) {
    fulfilled_by_company{
      users{
        name
      }
    }
    created_by_user {
      email
      phone_number
    }
    id
    requested_by_company {
      name
    }
    scheduled_time
    site {
      name
    }
    type
    positions {
      fulfilled_by_user {
        name
      }
      offers {
        status
        user {
          name
        }
      }
    }
  }
}
`