import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { FormControl, Typography } from '@mui/material'
import { AppTextColor } from 'src/theme/Colors'

type AutoCompleteProps = {
    options: any
    optionLabel: string
    loading: boolean
    label: string
    setData: any
    placeholder: string
    currentValue?: any
}

export default function AutoComplete({
    options,
    optionLabel,
    loading,
    label,
    setData,
    placeholder,
    currentValue = []
}: AutoCompleteProps) {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState<any | null>(currentValue)
    const [inputValue, setInputValue] = React.useState('')

    React.useEffect(() => {
        setData([...value])
    }, [value, setData])

    return (
        <Autocomplete
            id="asynchronous-autocomplete"
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            multiple={true}
            getOptionLabel={(option) => option[optionLabel]}
            options={options}
            loading={loading}
            onChange={(e, val) => setValue([...val])}
            value={value}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => {
                return (
                    <FormControl variant="standard" fullWidth>
                        <Typography sx={{ fontSize: '12px', fontWeight: '700', marginBottom: '5px', color: AppTextColor }}>
                            {label}
                        </Typography>
                        <TextField
                            {...params}
                            fullWidth
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    let valueToLower = inputValue.toLowerCase()
                                    var filterdValue = options.filter(o => o.name.toLowerCase().includes(valueToLower))
                                    if (filterdValue.length === 0) {
                                        setOpen(false)
                                        setValue([...value, {
                                            name: inputValue
                                        }])
                                        setInputValue('')
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            InputProps={{
                                style: {
                                    paddingTop: '2px',
                                    paddingBottom: '2px'
                                },
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}

                        />
                    </FormControl>
                )
            }
            }
        />
    )
}