export const DateToUTCString = (date: Date) => {
  var utcDate = `${date.getUTCFullYear}-${date.getUTCMonth}-${date.getUTCDate} ${date.getUTCHours}:${date.getUTCMinutes}`
  return utcDate
}

export const ScheduledTimeToStartEndDate = (scheduledTime: string) => {

  if (scheduledTime === null) {
    return ['', '']
  }

  var startDate: string
  var endDate: string
  var scheduledTimeSplit = scheduledTime.split('","')

  startDate = scheduledTimeSplit[0].replace('("', '')
  endDate = scheduledTimeSplit[1].replace('")', '')

  return [startDate, endDate]
}

export const ValidateStartEndDate = (startDateObject: any, endDateObject: any) => {
  var startDate: Date
  var endDate: Date

  if (startDateObject instanceof Date) {
    startDate = startDateObject
  } else {
    startDate = new Date(startDateObject)
  }

  if (endDateObject instanceof Date) {
    endDate = endDateObject
  } else {
    endDate = new Date(endDateObject)
  }

  if (startDateObject === '' && endDateObject === '') {
    // No Schedule (This is a valid option)
    return true
  }

  if (startDate.toString() === 'Invalid Date' || endDate.toString() === 'Invalid Date') {
    // Invalid Date
    return false
  }

  if (startDate.getTime() > endDate.getTime()) {
    // Invalid Date
    return false
  }

  // Valid Start and End Date
  return true
}

export const StartEndDateToScheduledTime = (startDateObject: any, endDateObject: any) => {

  var startDate: Date
  var endDate: Date

  if (startDateObject instanceof Date) {
    startDate = startDateObject
  } else {
    startDate = new Date(startDateObject)
  }

  if (endDateObject instanceof Date) {
    endDate = endDateObject
  } else {
    endDate = new Date(endDateObject)
  }

  if (startDate.toString() === 'Invalid Date' || endDate.toString() === 'Invalid Date') {
    // Invalid Date
    return null
  }

  return `("${startDate.getUTCFullYear()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCDate()} ${startDate.getUTCHours()}:${startDate.getUTCMinutes()}","${endDate.getUTCFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()} ${endDate.getUTCHours()}:${endDate.getUTCMinutes()}")`
}
