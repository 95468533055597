import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
// import { ReactComponent as DashboardIcon } from 'src/assets/images/dashboard_icon.svg'
import { ReactComponent as SchedulerIcon } from 'src/assets/images/scheduler_icon.svg'
import { ReactComponent as JobsIcon } from 'src/assets/images/jobs_icon.svg'
import { ReactComponent as CertificateIcon } from 'src/assets/images/certificate_icon.svg'
import { ReactComponent as WorkerIcon } from 'src/assets/images/worker_icon.svg'
// import { ReactComponent as NotifiactionIcon } from 'src/assets/images/notification_icon.svg'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuth0 } from '@auth0/auth0-react'

import { Tooltip } from '@mui/material'
import SidebarItem from './SidebarItem'
import { useLocation } from 'react-router-dom'
import { AppBorderColor } from 'src/theme/Colors'
// import Button from '@mui/material/Button'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// const UserProfile = ({ open, anchorEl, handleClose }) => {
//   const { logout } = useAuth0()
//   const onClickLogout = () => {
//     logout({ returnTo: `${window.location.origin}` })
//     handleClose()
//   }
//   return (
//     <div>
//       <Menu
//         id="user-profile-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//       >
//         <MenuItem onClick={onClickLogout}>Logout</MenuItem>
//       </Menu>
//     </div>
//   )
// }



const Icons = [
  { icon: <Logo width={40} height={40} />, key: 'Dashboard', link: '/', isLogo: true },
  // { icon: <DashboardIcon width={40} height={40} />, key: 'Dashboard', link: '/' },
  { icon: <SchedulerIcon width={40} height={40} />, key: 'Scheduler', link: '/scheduler' },
  { icon: <JobsIcon width={40} height={40} />, key: 'Jobs', link: '/jobs' },
  { icon: <WorkerIcon width={40} height={40} />, key: 'Workers', link: '/workers' },
  { icon: <CertificateIcon width={40} height={40} />, key: 'Certificates', link: '/certificate' },
]
const SideBar = ({ drawerWidth, activeTab, setActiveTab }) => {
  const history = useLocation()
  const { logout } = useAuth0()

  useEffect(() => {
    let filteredRoute = Icons.filter(i => i.link === history.pathname)
    if (filteredRoute.length) {
      setActiveTab(filteredRoute[0].key)
    }
  }, [])

  return (
    <Drawer
      variant="permanent"
      sx={{
        overflowX: 'hidden',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', border: `1px solid ${AppBorderColor}` },
      }}
    >
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh'
        }}
      >
        <List>
          {Icons.map(({ key, link, icon, isLogo }) => (
            <Tooltip title={key} key={key} placement="right-start">
              <ListItem button>
                <SidebarItem isLogo={isLogo} icon={icon} link={link} setActiveTab={() => setActiveTab(key)} activeTab={key === activeTab} />
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <List>
          {/*<ListItem button >*/}
          {/*  <ListItemIcon>*/}
          {/*    <NotifiactionIcon sx={{ width: 100, height: 100 }} />*/}
          {/*  </ListItemIcon>*/}
          {/*</ListItem>*/}
          <ListItem button >
            <ListItemIcon>
              <LogoutIcon sx={{ width: 40, height: 40 }} onClick={() => logout()} />
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
    </Drawer>

  )
}
export default React.memo(SideBar)