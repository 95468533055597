import * as React from 'react'
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
  SelectOption,
} from '@mui/base/SelectUnstyled'
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled'
import PopperUnstyled from '@mui/base/PopperUnstyled'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { getQueryParam } from 'src/utils/query'
const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
}

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
}

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: inherit;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 200px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid #3076FF;
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  label-align: left;
  line-height: 1.5;
  color: #3076FF  ;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    cursor: pointer;
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '';
    }
  }

  &::after {
    content: '';
    float: right;
  }
  `,
)

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 200px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid  #3076FF;
  border-radius: 0.75em;
  color:  #3076FF;
  overflow: auto;
  outline: 0px;
  `,
)

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color:  #3076FF;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color:  #3076FF;
  }

  &.${optionUnstyledClasses.disabled} {
    color:  #3076FF;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: #3076FF;
  }
  `,
)

const StyledPopper = styled(PopperUnstyled)`
  z-index: 10;
`

function CustomSelect(props: SelectUnstyledProps<number>) {
  const components: SelectUnstyledProps<number>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  }

  return <SelectUnstyled {...props} components={components} />
}

export const layoutRender = (option: SelectOption<any> | null, options: any) => {
  const activeLayout = getQueryParam('layout')
  if (options?.length > 0 || activeLayout) {
    // console.log(options, 'options')
    return (
      options?.filter((layout) => layout.value === activeLayout)[0]?.label || options[0]?.label
    )
  }
  if (option == null) {
    return <Typography variant='body2' fontSize='14px' fontWeight={500}>Select an option...</Typography>
  }

  return (
    option.label
  )
}


export const otherRender = (option: SelectOption<any> | null, options: any, defaultSelected) => {
  if (defaultSelected) {
    return (
      defaultSelected
    )
  }
  if (option == null) {
    return <Typography variant='body2' fontSize='14px' fontWeight={500}>Select an option...</Typography>
  }

  return (
    option.label
  )
}


export default function CustomSelectComponent({ options, onChange, renderFunction, defaultSelected }) {
  return (
    <CustomSelect renderValue={((option) => renderFunction(option, options, defaultSelected))} onChange={onChange} >
      {
        options.map(({ value, label }) => <StyledOption value={value}  >{label}</StyledOption>)
      }
    </CustomSelect>
  )
}
