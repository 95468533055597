import { gql } from '@apollo/client'

export const CREATE_POSITION = gql`
  mutation createPosition(
    $object: position_insert_input!
  ) 
  {
    insert_position_one(object: $object) {
      id
    }
  }
`
export const CREATE_POSITION_OFFER = gql`
  mutation createPositionOffer(
    $object: position_offer_insert_input!
  )
  {
    insert_position_offer_one(object: $object){
      position_id
      user_id
    }
  }
`
