import { useNavigate } from 'react-router-dom'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'

export default function EditModel ({
  id,
  path
}: {
  id: String
  path: String
}) {
    const navigation = useNavigate()
    return(
        <ModeEditOutlineOutlinedIcon style={{ marginRight: '8px' }} onClick={(e) => {
            e.stopPropagation()
            navigation(`${path}${id}`)
        }}
        />
    )
}