import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Avatar, Box, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Theme, Tooltip } from '@mui/material'
import { CalendarMonth, EmailOutlined, FitScreenSharp } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { deepPurple } from '@mui/material/colors'
import CallIcon from '@mui/icons-material/PhoneOutlined'
import moment from 'moment'
import CodeIcon from '@mui/icons-material/Code'
// import { ReactComponent as EditIcon } from 'src/components/icons/icon-park-outline_ticket.svg'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import CircularProgressBar from 'src/components/Loader'
import Card from 'src/components/Card'
import MoreMenu from 'src/components/Scheduler/MoreMenu'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HomeOutlinedIcon from '@mui/icons-material/Home'
import { useSchedulerContext } from 'src/contexts/SchedulerContext'
type Props = {
  dragStart?: any
}

export interface INameAndContactDetails {
  firstName?: string
  lastName?: string
  contact?: string
  children?: any
  state?: any
  company?: any
  onClick?: React.MouseEventHandler
}

const useStyles = makeStyles<Theme, Props>(() => ({
  scroll: {
    overflowY: 'auto',
    scroll: 'auto',
    scrollbarColor: '#6b6b6b #2b2b2b',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '10px',
      width: '3px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)',
      width: '3px'
    }
  },
  openPosition: {
    minWidth: '100px',
    minHeight: '100px',
    borderRadius: '10px'
  },
  active: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  inactive: {
    position: 'relative',
    top: '0%',
    left: '0%'
  },
  pulse: {
    animation: '$pulse 2s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.6)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '70%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0.6)',
    }
  },
  'MuiListItemIcon-root': {
    minWidth: '40px !important'
  },
  circle: {
    border: '2px dashed gray',
    padding: '4px',
    margin: '4px',
    borderRadius: '50%'
  },
  circleActive: {
    border: '2px solid #673AB7',
    margin: '4px',
    borderRadius: '50%'
  },
  circleOffer: {
    border: '2px solid #EE8407',
    margin: '4px',
    borderRadius: '50%'
  }
}))


const formatName = (user) => {
  const { first_name, last_name, name } = user || {}
  const firstName = first_name && first_name || name.split('@')[0].replace('-', '.').replace(' ', '.').split('.')[0] || ''
  const lastName = last_name && last_name || (name.split('@'))[0].replace('-', '.').replace(' ', '.').split('.')[1] || ''
  return {
    firstName, lastName
  }
}

export default function CustomAccordion({ headerBackground = '#E3E3E3', accordionSummary, defaultExpanded = true, children, detailsHeight = '70vh', ...rest }) {
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  return (
    <Box margin={'8px 0px'}
      sx={{ with: '940px' }}
    >
      <Accordion
        {...rest}
        expanded={expanded}
      >
        <AccordionSummary
          style={{
            background: headerBackground,
            height: '45px !important',
            minHeight: '45px !important',
          }}
          expandIcon={<ExpandMoreIcon onClick={() => { setExpanded(!expanded) }} />}
          aria-controls='accordion-content'
        >
          {accordionSummary}
        </AccordionSummary>
        <AccordionDetails className={useStyles({}).scroll} sx={{ maxHeight: detailsHeight, paddingTop: '0px' }}>
          {children}
        </AccordionDetails>
      </Accordion>

    </Box >
  )
}

const allColors = {
  '0': '#21A516',
  '1': '#1F00DF',
  '2': '#CE4040',
  '3': '#001533',
  '4': '#18B6C0',
  '5': '#8C39F5',
}

export const CertificatesSection = ({ certifications = [] }) => {
  return (
    certifications.length > 0 ?
      <Box py={1} borderBottom='1px solid lightgray'>
        <Grid container spacing={1}>
          {
            certifications?.map(({ certification }) => {
              return (<Grid key={certification?.name + certification?.id} item>
                {/* <Tooltip title={name}> */}
                <Chip label={certification?.name} style={{ color: allColors[Math.floor(Math.random() * 5)] }} />
                {/* </Tooltip> */}
              </Grid>)
            }
            )
          }
        </Grid>
      </Box> : null

  )
}

export const WorkersCertificatesSection = ({ certifications = [] }) => {
  return (
    certifications.length > 0 ?
      <Box py={1} borderBottom='1px solid lightgray'>
        <Grid container spacing={1}>
          {
            certifications?.map(({ certification, certification_id }) => {
              const { description, name } = certification || {}
              return (<Grid key={certification_id} item>
                <Tooltip title={description}>
                  <Chip label={name} style={{ color: allColors[Math.floor(Math.random() * 5)], fontWeight: 500, fontSize: '12px' }} />
                </Tooltip>
              </Grid>)
            }
            )
          }
        </Grid>
      </Box> : null

  )
}

const CreateUserIcons = ({position}) => {
  const classes = useStyles({})
  if(position.fulfilled_by_user!==null){
    return(
      <Box key={position.fulfilled_by_user.id} className={classes.circleActive}>
        <Tooltip title={`${formatName(position.fulfilled_by_user).firstName} ${formatName(position.fulfilled_by_user).lastName}`}>
          <Avatar sx={{ bgcolor: 'rgba(103, 58, 183, .2)', color: '#673AB7'}}>
            {formatName(position.fulfilled_by_user).firstName[0]}
            {formatName(position.fulfilled_by_user).lastName[0]}
          </Avatar>
        </Tooltip>
      </Box>
    )
  } else if(position.offers.length>0){
    return (
      <Box key={`empty-${position.id}`} className={classes.circleOffer}>
        <Avatar sx={{ bgcolor: 'rgba(238, 132, 7, .2)', color: '#EE8407'}}>
          {position.offers.length}
        </Avatar>
      </Box>
    )
  } else {
    return (
      <Box key={`empty-${position.id}`} className={classes.circle}>
        <Avatar src="/broken-image.jpg" />
      </Box>
    )
  }
}


export const RequestedWorkers = ({ positions }) => {
  return (
    positions.length > 0 ?
        <Box py={1} borderBottom='1px solid lightgray'>
          <Typography mb={1} variant='body1' fontWeight='600' textAlign='left'>
            Requested Workers:
          </Typography>
          <Grid container spacing={1}>
            {
              positions.map((position) => (
                <CreateUserIcons position={position}/>
              ))
            }
          </Grid>
        </Box>
    : null
  )
}

export const WorkSiteDetails = ({ site, scheduled_time, jobId = null }) => {
  let time = scheduled_time ? scheduled_time?.replace(')', '').replace('[', '').replace('(', '').replace(']', '').split(',') : '--'
  const formattedTime = scheduled_time ? moment(time[0]).format('DD MMM, YYYY') + ' - ' + moment(time[1]).format('DD MMM, YYYY') : '--'
  return (
    <Box borderBottom='1px solid lightgray'>
      <List>
        {
          jobId ?
            <ListItem disablePadding>
              <ListItemIcon style={{ minWidth: '40px !important' }}>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={
                <Typography textAlign='left' variant='body2'>
                  Job #{jobId}
                </Typography>} />
            </ListItem>
            : null
        }
        {
          site?.address ?
            <ListItem disablePadding>
              <ListItemIcon>
                <LocationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={
                <Typography textAlign='left' variant='body2'>
                  {site?.address}
                </Typography>} />
            </ListItem>
            : null
        }
        {
          scheduled_time ?
            <ListItem disablePadding>
              <ListItemIcon>
                <CalendarMonth />
              </ListItemIcon>
              <ListItemText primary={
                <Typography textAlign='left' variant='body2'>
                  {formattedTime}
                </Typography>
              } />
            </ListItem>
            : null
        }
      </List>
    </Box>
  )
}

export const NameAndContactDetails = ({ firstName, lastName, contact = null, state = null,company = null, children, onClick }: INameAndContactDetails) => {
  return (
    <Box>
      <Box component='div' onClick={onClick} py={1} display='flex' alignItems='center' width='100%' justifyContent='space-between' borderBottom='1px solid lightgray'>
        <Box display='flex' alignItems='center' width='100%' justifyContent='start' >
          <Avatar sx={{ bgcolor: deepPurple[500] }}>{firstName[0] + lastName[0]}</Avatar>
          <Box ml={2}>
            <Typography variant='body1' color='black' fontWeight='700'>
              {firstName + ' ' + lastName}
            </Typography>
          </Box>
        </Box>
        {
          children
        }
      </Box>
      <Box py={1} display='flex' width='100%' flexDirection='column' borderBottom='1px solid lightgray'>
        {
          company && (
             <Box px={3} display='flex' alignItems='flex-start' >
              <HomeOutlinedIcon sx={{ fontSize: '17px' }} />
              <Typography variant='subtitle2' ml={0.5} >
                {company?.name}
              </Typography>
            </Box>
          )
        }
        {
          contact ?
            <Box px={3} display='flex' alignItems='flex-start' >
              <CallIcon sx={{ fontSize: '17px' }} />
              <Typography variant='subtitle2' ml={0.5} >
                {contact}
              </Typography>
            </Box>
            : null
        }
        {
          state ?
            <Box px={3} display='flex' alignItems='center' >
              <LocationOnOutlinedIcon sx={{ fontSize: '17px' }} />
              <Typography variant='subtitle2' ml={0.5} >
                {state}
              </Typography>
            </Box>
            : null
        }
      </Box>
    </Box>
  )
}

export const CompanyDetails = ({ companyName, id, children = null }) => {
  return (
    <Box py={1} display='flex' alignItems='center' width='100%' justifyContent='space-between' borderBottom='1px solid lightgray'>
      <Box display='flex' alignItems='center' width='100%' justifyContent='start' > <Avatar sx={{ bgcolor: 'lightgray', borderRadius: '5px', padding: '8px', color: 'black', border: '1px solid black', fontSize: '14px' }}>logo</Avatar>
        <Box ml={2}>
          <Typography variant='body1' color='black' fontWeight='700'>
            {`Job #${id}`}
          </Typography>
          <Typography variant='subtitle2' >
            {companyName}
          </Typography>
        </Box>
      </Box>
      {
        children
      }
    </Box>
  )
}

export const UserDetails = ({ email }) => {
  return (
    <Box>
      {email ?
        <Stack>
          <EmailOutlined />
          <Typography>{email}</Typography>
        </Stack>
        : null}
    </Box>
  )
}

export const JobsAccordion = ({ positionUpdating, items, dragStart, removeWorkerFromPosition, onPressIcon }: any) => {
  const classes = useStyles(dragStart)

  return (
    <>
      {
        items?.map(({ background = '', status = 'pending', id, positions, type, requested_by_company, scheduled_time, site, description, }, index) => {
          const allCertificates: any = positions.map(({ certifications }) => certifications.map(({ certification }) => certification))
          const uniqueCertificates: any = new Set(allCertificates.flat().map((item) => item.name))

          return (
            <Box key={scheduled_time} my={1} className='MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-1elwnq4-MuiPaper-root-MuiAccordion-root'>
              <Box px={2} py={1} display='flex' alignItems='center' justifyContent='space-between' >
                <Typography fontWeight={700}>#{id} {' '} {status}</Typography>
                <FitScreenSharp onClick={() => onPressIcon(id)} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />

                <BorderColorOutlinedIcon />
              </Box>
              <CustomAccordion
                defaultExpanded={false}
                headerBackground={background}
                accordionSummary={<Box display='flex' alignItems={'center'} justifyContent='space-between' width='100%'>
                  <Typography>Details</Typography>
                  {/* <Box>
                <FitScreenSharpIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </Box> */}
                </Box>}>
                <Box px={0.5} py={1}>
                  <CompanyDetails id={id} companyName={requested_by_company?.name} />
                  <WorkSiteDetails scheduled_time={scheduled_time} site={site} />
                  <Box py={1} borderBottom='1px solid lightgray'>
                    <Typography textAlign='left' fontSize='0.875rem'>
                      {description}
                    </Typography>
                  </Box>
                  <CertificatesSection certifications={[...uniqueCertificates]} />
                </Box>
                <OpenedPositions
                  dragStart={dragStart}
                  title='Positions'
                  openedPositions={positions}
                  jobId={id}
                  removeWorkerFromPosition={removeWorkerFromPosition}
                  positionUpdating={positionUpdating}
                  classes={classes}
                />
              </CustomAccordion>
            </Box>

          )
        })
      }
    </>
  )
}

export const OpenedPositions = ({ title = 'Positions', openedPositions, jobId, dragStart, removeWorkerFromPosition, positionUpdating, classes }) => {
  const { positionAssignData } = useSchedulerContext()
  return (
    <Box py={1} px={1} borderBottom='1px solid lightgray'>
      <Typography variant='body1' fontWeight='600' textAlign='left'>
        {title}
      </Typography>
      <Grid container spacing={1}>
        {
          openedPositions?.map(({ function: positionTitle, id, fulfilled_by_user, certifications }, index) => {
            const idToAssign = JSON.stringify({
              positionId: id,
              jobId
            })
            return <Grid item md={12} xs={12} sm={12} key={positionTitle + id + jobId} sx={{ position: 'relative' }} >
              <Typography variant='body2' fontWeight='600' textAlign='center'>
                {positionTitle}
              </Typography>
              <Box className={classes.openPosition} sx={{
                cursor: positionUpdating ? 'wait' : 'pointer'
              }} >
                <Droppable
                  droppableId={idToAssign}
                  isDropDisabled={Boolean(fulfilled_by_user && Object.keys(fulfilled_by_user).length > 0)}
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >

                        <Box display='flex' alignItems='center' width='100%' height='100px' justifyContent='center' position='relative'
                          sx={{
                            '&:hover': {
                              '& svg.closeSvg': {
                                display: 'inline',
                                cursor: 'pointer !important'
                              }
                            }
                          }}
                        >
                          {
                            dragStart ?
                              fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0 ?
                                <CancelOutlinedIcon className={classes.pulse} sx={{ position: 'absolute', right: '5px', top: '5px', color: 'red', cursor: 'pointer !important' }} />
                                :
                                <AddCircleRoundedIcon className={classes.pulse} sx={{ position: 'absolute', right: '5px', top: '5px' }} />

                              :
                              null
                          }
                          {
                            positionUpdating && positionAssignData?.jobId === jobId && positionAssignData?.positionId === id ? null :

                              <Box position='relative' height='200px' >
                                <CircularProgressBar left='60px' top='36%' />
                              </Box>
                          }
                          {
                            fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0 ?
                              <Draggable
                                key={fulfilled_by_user?.id}
                                draggableId={JSON.stringify({
                                  'from': 'position',
                                  id: fulfilled_by_user?.id,
                                })}
                                index={index}
                                isDragDisabled={true}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}>
                                      <Box width='100%'>
                                        <CancelOutlinedIcon onClick={() => removeWorkerFromPosition(jobId, id)} className='closeSvg'
                                          sx={{
                                            color: 'red',
                                            display: 'none',
                                            position: 'absolute',
                                            right: '5px',
                                            top: '5px',
                                            '&:hover': {
                                              size: '18px',
                                              cursor: positionUpdating ? 'wait' : 'pointer'
                                            }
                                          }}
                                        />
                                        <Avatar />
                                        <Typography variant='body2'>{fulfilled_by_user?.name}</Typography>
                                      </Box>
                                    </div>)
                                }}

                              </Draggable>
                              : null
                          }
                        </Box>
                      </div>
                    )
                  }
                  }
                </Droppable>
              </Box>
            </Grid>
          })
        }
      </Grid>
    </Box>

  )
}

export const OpenedPositionsAccordion = ({ openedPositions, jobId, dragStart, removeWorkerFromPosition, positionUpdating }) => {
  const classes = useStyles({})
  const userAssigned = (fulfilled_by_user) => fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0
  return (
    <Box py={1} px={1} borderBottom='1px solid lightgray'>

      {
        openedPositions?.map(({ function: positionTitle, id, fulfilled_by_user, certifications }, index) => {
          const idToAssign = JSON.stringify({
            positionId: id,
            title: positionTitle,
            jobId
          })
          return <Box key={id} >
            <CustomAccordion
              defaultExpanded={true}
              headerBackground={'white'}
              accordionSummary={<Box width='100%'>
                <Typography pb={1} pl={1} fontWeight={700} textTransform='capitalize'>{positionTitle}</Typography>
                <Chip
                  label={userAssigned(fulfilled_by_user) ? 'Accepted' : 'Not Accepted'}
                  variant='filled'
                  color={userAssigned(fulfilled_by_user) ? 'success' : 'warning'}

                  icon={userAssigned(fulfilled_by_user) ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />} />
              </Box>}>
              <Droppable
                droppableId={idToAssign}
                isDropDisabled={Boolean(fulfilled_by_user && Object.keys(fulfilled_by_user).length > 0)}
              >
                {(provided, snapshot) => {

                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >

                      <Box position='relative'
                        sx={{
                          '&:hover': {
                            '& svg.closeSvg': {
                              display: 'inline',
                              cursor: 'pointer !important'
                            }
                          }
                        }}
                      >
                        {
                          snapshot.isDraggingOver ?
                            fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0 ?
                              <CancelOutlinedIcon className={classes.pulse} sx={{ position: 'absolute', right: '68px', top: '60px', color: 'red', cursor: 'pointer !important' }} />
                              :
                              <AddCircleOutlineIcon className={classes.pulse} sx={{ position: 'absolute', right: '60px', top: '70px', fontSize: 70 }} />

                            :
                            null
                        }
                        {
                          !positionUpdating ? null :
                            <CircularProgressBar left='90px' top='36%' />
                        }
                        <Box style={{ opacity: snapshot.isDraggingOver ? fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0 ? 1 : '0.5' : 1 }} >
                          {certifications && (
                          <Box my={1}>
                            <Typography variant='body1' fontWeight='700' >Certifications</Typography>
                            <CertificatesSection certifications={certifications} />
                          </Box>
                           )}
                          {
                            fulfilled_by_user !== null && Object.keys(fulfilled_by_user).length > 0 ?
                              <Box width='100%' display='flex' alignItems='center'>
                                <CancelOutlinedIcon onClick={() => removeWorkerFromPosition(jobId, id)} className='closeSvg'
                                  sx={{
                                    color: 'red',
                                    display: 'none',
                                    position: 'absolute',
                                    right: '5px',
                                    top: '5px',
                                    '&:hover': {
                                      size: '18px',
                                      cursor: positionUpdating ? 'wait' : 'pointer'
                                    }
                                  }}
                                />
                                <Avatar />
                                <Typography ml={1} variant='body2'>{fulfilled_by_user?.name}</Typography>
                              </Box>
                              : <Box my={1}>
                                <Typography variant='body1' fontWeight='700' >Invites</Typography>
                                <Typography variant='body2' fontWeight='500' >Drag and Drop to invite.</Typography>
                              </Box>
                          }

                        </Box>
                      </Box>
                      {provided.placeholder}
                    </div>
                  )
                }
                }
              </Droppable>
            </CustomAccordion>
          </Box>
        })
      }
      {/* </Grid> */}
    </Box >

  )
}

export const WorkersAccordion = ({ items, layout, onClickItem }: any) => {
  return (
    <Box my={1} className={'draggable-workers'}>
      <Droppable
        droppableId={'workers'}
        isDropDisabled
      >
        {(provided) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Grid container spacing={1}>
                {
                  items?.map(({ id, name, phone_number, first_name, state, last_name, certifications,company }, index) => {
                    const { firstName, lastName } = formatName({ name, first_name, last_name })
                    return (
                      <Grid key={id} item md={layout === 'default' ? 12 : 3}>
                        <Draggable
                          key={id}
                          draggableId={JSON.stringify({
                            'from': 'worker',
                            id,
                            name: firstName + ' ' + lastName
                          })}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box border='1px solid lightgray'>
                                  <Card
                                    elevation={3}
                                    title={
                                      <NameAndContactDetails firstName={firstName} lastName={lastName} contact={phone_number} state={state} company={company}>
                                        <MoreMenu
                                          menuItems={[
                                            { label: 'Collapse All', handler: () => { }, icon: <RemoveOutlinedIcon style={{ marginRight: '8px' }} /> },
                                            { label: 'Worker Profile', handler: () => onClickItem(id), icon: <AccountCircleOutlinedIcon style={{ marginRight: '8px' }} /> },
                                          ]}
                                        />
                                      </NameAndContactDetails>
                                    }>
                                    {certifications.length>0 && <WorkersCertificatesSection certifications={certifications}/>}
                                  </Card>
                                </Box>
                              </div>
                            )
                          }
                          }
                        </Draggable>
                      </Grid>
                    )
                  }
                  )
                }
              </Grid>
            </div >
          )
        }
        }
      </Droppable >
    </Box>
  )
}

