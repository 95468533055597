import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Typography, Box, Badge } from '@mui/material'

const formatJobs = (jobs, onClickPosition) => {
  const formattedJobItems = jobs.map(({ id, scheduled_time, positions, requested_by_company }) => {
    const countOfOccupiedUsers = positions.filter(({ fulfilled_by_user }) => fulfilled_by_user ? Object.keys(fulfilled_by_user).length > 0 : false).length
    let time = scheduled_time ? scheduled_time.replace(')', '').replace('[', '').replace('(', '').replace(']', '').split(',') : []
    const start = scheduled_time ? new Date(time[0]) : '--'
    const end = scheduled_time ? new Date(time[1]) : '--'
    return {
      id,
      title: <Box key={id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => onClickPosition(id)}>
        <Typography>JOB# {id} {' '} {requested_by_company?.name}</Typography>
        <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={countOfOccupiedUsers} ></Badge>
      </Box>,
      start,
      end
    }
  })
  return formattedJobItems
}

const MyCalendar = ({ jobsItems, onClickPosition }) => {
  const localizer = momentLocalizer(moment)
  const [myEventsList, setMyEventsList] = useState([])
  useEffect(() => {
    const formattedJobs = formatJobs(jobsItems?.job || [], onClickPosition)
    setMyEventsList(formattedJobs)
  }, [jobsItems])


  return <div>
    <Calendar
      localizer={localizer}
      events={myEventsList}
      startAccessor='start'
      endAccessor='end'
      style={{ height: 500 }}
    // onKeyPressEvent={() => { console.log('clicked test event') }}
    />
  </div>
}

export default MyCalendar