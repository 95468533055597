

import { gql } from '@apollo/client'

export const GetCertificates = gql`
query GetCertificates(
  $where: certification_bool_exp
  $offset: Int!
  $limit: Int!
) {
    certification (where: $where, limit: $limit, offset: $offset) {
      id
      name
      description
      tags {
        tag {
          name
          comment
        }
        certification_id
        certification_tag_name
      }
      verified
      company_id
    }
  } 
`

export const GetCertCount = gql`
query q @cached {
  certification_aggregate {
    aggregate {
      count
    }
  }
}
`


export const GetDistinctCertificates = gql`
query GetDistinctCertificates {
    certification(distinct_on: name) {
      id
      name
  }
}
`

export const GetCertificatesByNameSearch = gql`
  query GetCertificates(
    $where: certification_bool_exp
  ) {
    certification (where: $where) {
      id
      name
      description
    }
  }
`

export const GetUserCertificates = gql`
  query GetUserCertificates($user: String!) {
    user_certification(where: { user_id: { _eq: $user } }) {
      date_received
      is_verified
      file_uri
      certification {
        id
        name
        description
        duration
        company {
          name
        }
      }
    }
  }`


export const GET_CERTIFICATES_BY_ID = gql`
  query GetCertificateById(
    $id: Int!
  ) {
    certification_by_pk (id: $id) {
      id
      name
      description
      tags {
        tag {
          name
        }
      }
    }
  }
`