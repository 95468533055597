import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, FormControl, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { UploadFile } from '@mui/icons-material'
import { AppBorderColor, AppTextColor, DefaultIconColor } from 'src/theme/Colors'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
const useStyles: any = makeStyles(() => ({
  rootContainer: {
    width: '100%',
    height: 100,
    borderColor: '#AEAEAE',
    borderWidth: '1px',
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  fileSelectedContainer: {
    width: '100%',
    height: 100,
    border: `1px solid ${AppBorderColor}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '10px 20px'
  },
}))

export default function FileDropZone({ onDrop, label, file, reset }: FileDropZoneProps) {
    const { getRootProps, getInputProps, } = useDropzone({ onDrop })

    const classes = useStyles()
  return (
    <FormControl variant="standard" fullWidth >
      <Typography sx={{fontSize: '12px', fontWeight: '700', marginBottom: '5px', color: AppTextColor}}>
          {label}
      </Typography>
      {file ?
        <Box className={classes.fileSelectedContainer}>
          <Box sx={{display: 'flex', alignItems: 'center'}} gap="10px">
            <UploadFileIcon sx={{color: DefaultIconColor}} />
            <Box>
              <Typography sx={{color: AppTextColor, fontSize: '13px', fontWeight: '500'}}>{file.name}</Typography>
              <Typography sx={{color: AppTextColor, fontSize: '13px'}}>{file.size} bytes</Typography>
            </Box>
          </Box>
          <IconButton onClick={() => reset()}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
           :
        <Box className={classes.rootContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            <UploadFile fontSize='large' sx={{ color: DefaultIconColor }} />
            <Typography sx={{fontSize: '13px', color: DefaultIconColor}}>Accepted formats: PDF, PNG or JPEG.</Typography>
        </Box>
       }
    </FormControl>
  )
}

type FileDropZoneProps = {
  onDrop: any
  label?: string,
  file?: any,
  reset?: Function
}