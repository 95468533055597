

import { gql } from '@apollo/client'

export const GetAllCompanies = gql`
query GetCompanies {
  company {
    name
    id
  }
}
  
`

export const GetJobCreatorCompanies = gql`
query GetJobCreatorCompanies($user_id: String!) {
  company(where: {user_roles: {
    _and: [
      {user_id: {_eq: $user_id}},
      {role: {_eq: JOB_CREATOR}}
    ]
  }}) {
    name
    id
  }
}
`