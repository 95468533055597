import Modal from '../../pages/Scheduler/DetailModal'
import Tabs from '@mui/material/Tabs'
import JobDetails from 'src/components/JobDetails'
import CircularProgressBar from 'src/components/Loader'
import Tab from '@mui/material/Tab'
import JobFiles from 'src/components/JobFiles'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GetSingleJob } from 'src/core/gql/queries/job'

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(() => ({
  sectionHeadingText: {
    height: '18px',
    width: '226px',
    left: '8px',
    top: '12px',
    textAlign: 'left',
  },
  sectionHeadingBox: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  jobContainer: {
    width: '100%',
    marginTop: 20,
  },
  jobDetailModalRoot: {
    width: '100%',
  },
  popUpBodyRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
  },
  rowContainer: {
    width: '33%',
  },
  certificationsTitle: {
    marginBottom: '10px !important',
    color: '#A1A1A1',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '115% !important',
    letterSpacing: '0.08em !important',
    textTransform: 'uppercase',
  },
  assignedWorkers: {
    marginBottom: '10px !important',
    color: '#A1A1A1',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '115% !important',
    letterSpacing: '0.08em !important',
    textTransform: 'uppercase',
  },
  headerStatusPending: {
    width: '100%',
    backgroundColor: '#ECD7BE',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerStatusInProgress: {
    width: '100%',
    backgroundColor: '#C2ECBE',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerStatusOnHold: {
    width: '100%',
    backgroundColor: '#BEECE6',
    height: '40px',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  hideStatus: {
    display: 'none !important',
  },
}))

export default function ViewJobModal({
  open,
  modalCloseHandler,
  jobData,
  jobId,
}: {
  open: boolean
  modalCloseHandler: (state: any) => void
  jobData: boolean
  jobId: string | number
}) {
  useEffect(() => {
    getSingleJobDetails(jobId)
  }, [])

  const [
    getSingleJob,
    { loading: loadingSingleJob, error: errorSingleJob, data: singleJobData },
  ] = useLazyQuery(GetSingleJob)

  const getSingleJobDetails = (id: any) => {
    if (id) {
      getSingleJob({
        variables: { id },
      })
    }
  }

  const classes = useStyles()
  let status = singleJobData?.job_by_pk?.status
    ? singleJobData.job_by_pk.status
    : '--'
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function TabModalPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  return (
    <Modal
      setOpen={modalCloseHandler}
      open={open}
      id={singleJobData?.job_by_pk?.id || Math.floor(Math.random() * 100)}
      title={
        singleJobData && Object.keys(singleJobData).length > 0
          ? 'Job#'+ singleJobData?.job_by_pk?.id
          : '--'
      }
      showActions={false}
      rootClass={classes.jobDetailModalRoot}
      subtitle={
        jobData && Object.keys(jobData).length > 0
          ? singleJobData?.job_by_pk?.created_by_user?.email
          : '--'
      }
      statusClasses={
        status === 'Pending'
          ? classes.headerStatusPending
          : status === 'OnHold'
          ? classes.headerStatusOnHold
          : status === 'InProgress'
          ? classes.headerStatusInProgress
          : classes.headerStatusInProgress
      }
      statusLabel={status}
      type={'Job'}
    >
      <Box className={classes.jobContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Job Details" {...a11yProps(0)} />
          <Tab label="Files" {...a11yProps(1)} />
          {/* <Tab label="Comments" {...a11yProps(2)} /> */}
        </Tabs>
        <TabModalPanel value={value} index={0}>
          {!loadingSingleJob &&
          !errorSingleJob &&
          singleJobData?.job_by_pk &&
          Object.keys(singleJobData).length > 0 ? (
            <JobDetails statusLabel={status} jobData={singleJobData} />
          ) : null}
          {!loadingSingleJob && errorSingleJob && (
            <Typography>Invaid ID</Typography>
          )}
          {loadingSingleJob && (
            <Box position="relative" height="70vh">
              <CircularProgressBar left="45%" />
            </Box>
          )}
        </TabModalPanel>
        <TabModalPanel value={value} index={1}>
          <JobFiles />
        </TabModalPanel>
      </Box>
    </Modal>
  )
}
