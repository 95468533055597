import { Alert, Box, Divider, Stack, Typography } from '@mui/material'
import { ReactComponent as CalendarIcon } from 'src/components/icons/calendar.svg'
import { ReactComponent as ThreeColumn } from 'src/components/icons/threeColumn.svg'
import { ReactComponent as TwoColumn } from 'src/components/icons/twoColumn.svg'

import ScheduleIcon from '@mui/icons-material/Schedule'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import Badge from '@mui/material/Badge'
import CustomAccordion, { WorkersAccordion } from 'src/components/Accordion'
import { JOB_STATUS } from 'src/utils/Constants'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import SearchBox from 'src/components/SearchBox'
import { makeStyles } from '@mui/styles'
import { JobsFilter, WorkersFilter } from './Filters'

import JobsDetails from './JobDetails'

import CardSkeleton from 'src/components/Skeletons/CardSkeleton'
import { useEffect, useState } from 'react'
import ViewJobModal from '../ViewJobModal'
const useStyles = makeStyles(() => ({
  availableWorkersBox: {
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '16px'
  },
  availableWorkerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 0px 24px 0px'
  },

}))

export const availableLayouts = [
  {
    value: 'default',
    label: <Stack direction='row' spacing={0.5} alignItems='center' >
      <ThreeColumn color='blue' />
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={0.5}
      >
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Job</Typography>
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Worker</Typography>
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Calendar</Typography>
      </Stack>
    </Stack>
  },
  {
    value: 'job-worker',
    label: <Stack direction='row' spacing={0.5} alignItems='center' >
      <TwoColumn fill='blue' />
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={0.5}
      >
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Job</Typography>
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Worker</Typography>
      </Stack>
    </Stack>
  },
  {
    value: 'job-calendar',
    label: <Stack direction='row' spacing={0.5} alignItems='center' >
      <TwoColumn color='blue' />
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={0.5}
      >
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Job</Typography>
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Calendar</Typography>
      </Stack>
    </Stack>
  },
  {
    value: 'calendar',
    label: <Stack direction='row' spacing={0.5} alignItems='center' >
      <CalendarIcon color='blue' />
      <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} >
        <Typography variant='body2' fontSize='14px' fontWeight={500}>Calendar</Typography>
      </Stack>
    </Stack>

  }
]


export const JobsView = ({ jobsItems, positionLoading, getSingleJobDetails, dragStart, removeWorkerFromPosition, layout, loading, onSearchChange }) => {
  const [jobId, setJobId] = useState('')
  const [open, setOpen] = useState(!!jobId)
  const [jobData] = useState(false)
  const modalCloseHandler = state => {
    setOpen(state)
  }

  useEffect(() => {
    if(jobId) {
      setOpen(true)
    }
  }, [jobId])

  const Details = ({ items }) => <JobsDetails
    component={layout === 'job-worker' ? 'accordion' : 'card'}
    positionUpdating={positionLoading}
    onPressIcon={(id) => getSingleJobDetails(id)}
    items={items || []}
    dragStart={dragStart}
    removeWorkerFromPosition={removeWorkerFromPosition}
    setJobId={setJobId}
    />

  const views = [
    { defaultExpanded: true, Icon: ScheduleIcon, items: jobsItems?.job?.filter((job) => job.status.includes('PENDING')), type: 'Pending' },
    { defaultExpanded: true, Icon: MyLocationIcon, items: jobsItems?.job?.filter((job) => job.status === JOB_STATUS.Active), type: 'Active' },
    { defaultExpanded: false, Icon: CheckCircleOutlineOutlinedIcon, items: jobsItems?.job?.filter((job) => job.status === JOB_STATUS.Past || job.status === JOB_STATUS.Cancelled || job.status === JOB_STATUS.Disputed), type: 'Past' },
  ]
  let previousItemsLength = 0

  return (
    <>
      <SearchBox onSearchChange={onSearchChange} background='white' isFilterRequired={false} text='All Jobs' CustomFilter={<JobsFilter />} />
      {
        loading ?
          <CardSkeleton />
          :
          layout === 'job-worker' ?
            <Details items={jobsItems?.job?.filter((job) => job.status.includes('PENDING')) || []} />
            :
            jobsItems?.job?.length === 0 ?
              <Alert sx={{ mt: '8px' }} severity='warning' >
                No Matching Data Found!!
              </Alert> :
              views.map(({ defaultExpanded, Icon, items, type }) => {
                let expand = previousItemsLength === 0 ? true : false
                previousItemsLength = items?.length
                return (items?.length === 0 ? null : <>
                  <CustomAccordion
                    key={type}
                    defaultExpanded={expand}
                    headerBackground={'white'}
                    accordionSummary={
                      <Box display='flex' alignItems={'center'} width='100%'>
                        <Icon sx={{ mr: 1, background: 'black', fill: 'white', borderRadius: '3px', p: '1px' }} />
                        <Typography>{type}</Typography>
                        <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={items?.length || 0} ></Badge>
                      </Box>
                    }>
                    <Details items={items} />
                  </CustomAccordion>
                </>)
              })
      }

      {open && jobId ? (
        <ViewJobModal
          open={open}
          modalCloseHandler={modalCloseHandler}
          jobData={jobData}
          jobId={jobId}
        />
      ) : null}
    </>
  )
}

export const WorkerViews = ({ items, layout, onClickDetail, onSearchChange, loading }) => {
  const classes = useStyles()
  return (
    <>
      {
        layout === 'default' ?
          <>
            {/* <SearchBox background='white' isFilterRequired={false} text='All Workers' CustomFilter={<WorkersFilter />} /> */}
            <SearchBox background='white' isFilterRequired={false} text='All Workers' onSearchChange={onSearchChange} />
            {
              loading ?
                <CardSkeleton />
                :
                <CustomAccordion
                  defaultExpanded={true}
                  headerBackground={'white'}
                  accordionSummary={
                    <Box display='flex' alignItems={'center'} width='100%'>
                      <PeopleOutlineIcon sx={{ mr: 1, background: 'black', fill: 'white', borderRadius: '3px', p: '1px' }} />
                      <Typography>Workers</Typography>
                      <Badge sx={{ ml: '40px', '& > span': { border: '1px solid #5C697B', color: '#5C697B', letterSpacing: '2px', borderRadius: '2px' } }} badgeContent={items?.length || 0} ></Badge>
                    </Box>
                  }>
                  <WorkersAccordion onClickItem={(id) => onClickDetail(id)} layout={layout} items={items} />
                </CustomAccordion>
            }

          </>
          : layout === 'job-worker' ?
            <Box className={classes.availableWorkersBox}>
              <Box className={classes.availableWorkerHeader}>
                <Typography fontSize='20px' lineHeight='32px' fontWeight={700} >Available Workers</Typography>
                <Box width='60%'>
                  <SearchBox
                    background='white'
                    isFilterRequired={false}
                    onSearchChange={onSearchChange}
                    inputProps={{
                      placeholder: 'Search worker ...',
                    }}
                    text='All Workers' CustomFilter={<WorkersFilter />} />

                </Box>
              </Box>
              <Box sx={{ margin: '8px 0px' }}>
                <Typography py={1} fontWeight='700' fontSize='12px'>TOP RECOMMENDED BASED ON CERTIFICATIONS </Typography>
                {
                  loading ?
                    <CardSkeleton count={4} display='flex' alignItems='center' justifyContent='space-evenly' />
                    :
                    <WorkersAccordion onClickItem={(id) => onClickDetail(id)} layout={layout} items={items.slice(0, 4)} />
                }
              </Box>
              <Box sx={{ margin: '16px 0px' }}>
                <Typography py={1} fontWeight='700' fontSize='12px'>MORE SIMILAR CERTIFICATES </Typography>
                {
                  loading ?
                    <CardSkeleton count={4} display='flex' alignItems='center' justifyContent='space-evenly' />
                    :
                    <WorkersAccordion onClickItem={(id) => onClickDetail(id)} layout={layout} items={items.slice(3, items.length)} />
                }
              </Box>
            </Box>
            : null
      }

    </>
  )
}


export default availableLayouts
