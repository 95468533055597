import React, { useState, useRef } from 'react'
import { Avatar, Box, Grid, Typography } from '@mui/material'
// import CardWithDropdown from './CardWithDropdown'
import Table from 'src/components/Table'
import { AppTextColor } from 'src/theme/Colors'
import {
  GetAllPendingJobsMinified,
  GetPendingJobsCount,
  GetReadyJobsCount,
  GetPastJobsCount,
  GetAllActiveJobsMinified, GetAllPastJobsMinified
} from 'src/core/gql/queries/job'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import Card from 'src/components/Card'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'
import ViewJobModal from 'src/components/ViewJobModal'
import EditModel from 'src/components/EditModel'

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#3076FF',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
}

const TableTab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #8697ab;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
    outline: none;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    color: ${blue[600]};
    border-bottom: 2px solid ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
  width: 700px;
  color: ${blue[500]};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`

const renderSchedule = (date: string) => {
  let time = date ? date?.replace(')', '').replace('[', '').replace('(', '').replace(']', '').split(',') : '--'
  const formattedTime = date ? moment(time[0]).format('DD MMM, YYYY') + ' - ' + moment(time[1]).format('DD MMM, YYYY') : '--'

  return (
    <Box width={'fit-content'}>
      <Typography
        color={AppTextColor}
        fontSize={'14px'}
        fontWeight="400"
        sx={{ padding: '5px 8px' }}
      >
        {formattedTime}
      </Typography>
    </Box>
  )
}

const renderSiteName = (site: { name: string }) => {
  return (
    <Box width={'fit-content'}>
      <Typography
        textTransform={'capitalize'}
        color={AppTextColor}
        fontSize={'14px'}
        fontWeight="400"
        sx={{ padding: '5px 8px' }}
      >
        {site?.name}
      </Typography>
    </Box>
  )
}

const renderSiteAddress = (site: { address: string }) => {
  return (
    <Box width={'fit-content'}>
      <Typography
        color={AppTextColor}
        fontSize={'14px'}
        fontWeight="400"
        sx={{ padding: '5px 8px' }}
      >
        {site?.address}
      </Typography>
    </Box>
  )
}

const renderWorkers = positions => {
  let userList = []
  positions.map(position => {
    if (position.fulfilled_by_user !== null){
      userList.push(position.fulfilled_by_user)
    } else if (position.offers.length > 0) {
      position.offers.map(offer => {
        userList.push(offer.user)
      })
    }
  })

  return (
    <Box sx={{ width: '200px', display: 'flex' }}>
      {userList.length > 0 &&
        userList.map((item, index) => {
          return (
            <Box display={index >= 2 ? 'none' : 'flex'} marginRight={1}>
              <Avatar
                sx={{ width: '40px', height: '40px' }}
                alt={item.name}
                src="/static/images/avatar/1.jpg"
              />
            </Box>
          )
        })}
        {userList.length > 2 &&
        (<Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            backgroundColor: '#E1EBFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '12px', color: '#3076FF' }}>
            {userList.length - 2}{' '}
            +
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const Jobs = () => {
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [page, setPage] = useState(0)
  const {
    loading: activeJobsLoading,
    data: activeJobs = {},
  } = useQuery(GetAllActiveJobsMinified, {
      variables: {
      where: {
      },
      limit:20,
      offset: rowsPerPage * (page === 0 ? 1 : page)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const {
    loading: pastJobsLoading,
    data: pastJobs = {},
  } = useQuery(GetAllPastJobsMinified, {
      variables: {
      where: {
      },
      limit:20,
      offset: rowsPerPage * (page === 0 ? 1 : page)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const {
    loading: allJobsLoading,
    data: pending = {},
    refetch,
  } = useQuery(GetAllPendingJobsMinified, {
      variables: {
      where: {
      },
      limit:20,
      offset: rowsPerPage * (page)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const pendingCount = useQuery(GetPendingJobsCount,{
    variables: {},
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const activeJobsCount = useQuery(GetReadyJobsCount,{
    variables: {},
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const pastJobsCount = useQuery(GetPastJobsCount,{
    variables: {},
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  // if(jobsItems && jobsItems.job && jobsItems.job.length>0){
  //   console.log(jobsItems?.job[0])
  // }

  const [columns] = useState([
    {
      name: 'Code',
      key: 'id',
      isChecked: true,
      numaric: false,
      isSortable: true,
      renderContent: (code: string) => `#${code}`,
    },
    {
      name: 'Company & Job Title',
      key: 'requested_by_company',
      isChecked: true,
      renderContent: company => `${company.name}`,
    },
    {
      name: 'Scheduled',
      key: 'scheduled_time',
      isChecked: true,
      isSortable: true,
      customRender: (date: string) => renderSchedule(date),
      numaric: false,
    },
    {
      name: 'Site Name',
      key: 'site',
      isChecked: true,
      numaric: false,
      isSortable: true,
      customRender: (site: any) => renderSiteName(site),
    },
    {
      name: 'Site Address',
      key: 'site',
      isChecked: true,
      numaric: false,
      isSortable: true,
      customRender: (site: any) => renderSiteAddress(site),
    },
    {
      name: 'Contact Phone',
      key: 'created_by_user',
      isChecked: true,
      numaric: false,
      isSortable: true,
      renderContent: created_by_user => `${created_by_user.phone_number}`,
    },
    {
      name: 'Contact Email',
      key: 'created_by_user',
      isChecked: true,
      numaric: false,
      isSortable: true,
      renderContent: created_by_user => `${created_by_user.email}`,
    },
    {
      name: 'Workers',
      key: 'positions',
      isChecked: true,
      numaric: false,
      isSortable: true,
      customRender: (positions: any) =>
        renderWorkers(positions),
    },
   {
        name: 'Actions',
        key: 'action',
        isChecked: true,
        component : row => <EditModel id={row.id} path = {'/jobs/'}/>
      },
  ])

  const [updatedColumns, setUpdatedColumns] = useState([...columns])
  const [columnsConfig, setColumnConfig] = useState([...columns])
  const [jobId, setJobId] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [jobData] = React.useState(false)

  const modalCloseHandler = state => {
    setOpen(state)
  }

  const firstCallDone = useRef(false)

  const [searchQuery, setSearchQuery] = useState('')

  useEnhancedEffect(() => {
    if (firstCallDone.current) {
      console.log('call')
      refetch({
        where: {
          requested_by_company: { name: { _like: `%${searchQuery}%` } },
        },
        limit: rowsPerPage,
        offset: rowsPerPage * (page)
      })
    } else {
      firstCallDone.current = true
    }
  }, [searchQuery, refetch])
  const getSingleJobDetails = (id: any) => {
    if (id) {
      setJobId(id)
      setOpen(true)
    }
  }
  return (
    <>
      {
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              title="Jobs"
              isSearchRequired={true}
              value={searchQuery}
              onSearchChange={value => setSearchQuery(value)}
              showFilterButton={true}
              updatedColumns={updatedColumns}
              setUpdatedColumns={setUpdatedColumns}
              setColumnConfig={setColumnConfig}
              defaultConfig={columns}
            >
              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <TableTab>{`PENDING JOBS ${
                    pending !== undefined
                      ? pending.length > 99
                        ? '99+'
                        : pendingCount?.data?.job_aggregate?.aggregate?.count
                      : ''
                  }`}</TableTab>
                  <TableTab>{`ASSIGNED JOBS ${
                    activeJobs !== undefined
                      ? activeJobs.length > 99
                        ? '99+'
                        : activeJobsCount?.data?.job_aggregate?.aggregate?.count
                      : ''
                  }`}</TableTab>
                  <TableTab>{`PAST JOBS ${
                    pastJobs !== undefined
                      ? pastJobs.length > 99
                        ? '99+'
                        : pastJobsCount?.data?.job_aggregate?.aggregate?.count
                      : ''
                  }`}</TableTab>
                </TabsList>
                <TabPanel value={0}>
                  <Table
                    columns={columnsConfig.filter(f => f.isChecked)}
                    data={
                      pending.job !== undefined && pending.job.length > 0 ? pending.job : []
                    }
                    loading={allJobsLoading}
                    onRowClick={(row) => getSingleJobDetails(row.id)}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    isPaginationRequired={true}
                    total={pendingCount?.data?.job_aggregate?.aggregate?.count}
                    page={page}
                    setPage={setPage}
                  />
                </TabPanel>
                <TabPanel value={1}>
                  <Table
                    columns={columnsConfig.filter(f => f.isChecked)}
                    data={activeJobs.job !== undefined && activeJobs.job.length > 0 ? activeJobs.job  : []}
                    loading={activeJobsLoading}
                    onRowClick={(row) => getSingleJobDetails(row.id)}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    isPaginationRequired={true}
                    total={activeJobsCount?.data?.job_aggregate?.aggregate?.count}
                    page={page}
                    setPage={setPage}
                  />
                </TabPanel>
                <TabPanel value={2}>
                  <Table
                    columns={columnsConfig.filter(f => f.isChecked)}
                    data={pastJobs.job && pastJobs.job.length > 0 ? pastJobs.job : []}
                    loading={pastJobsLoading}
                    onRowClick={(row) => getSingleJobDetails(row.id)}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    isPaginationRequired={true}
                    total={pastJobsCount?.data?.job_aggregate?.aggregate?.count}
                    page={page}
                    setPage={setPage}
                  />
                </TabPanel>
              </TabsUnstyled>
            </Card>
          </Grid>
        </Grid>
      }
      {open && jobId ? (
        <ViewJobModal
          open={open}
          modalCloseHandler={modalCloseHandler}
          jobData={jobData}
          jobId={jobId}
        />
      ) : null}
    </>
  )
}
export default Jobs
