import React from 'react'
import { getQueryParam } from 'src/utils/query'

type Children = JSX.Element | JSX.Element[] | string

type LayoutContextValue = {
  certificateModalOpen: any
  setCertificateModalOpen: Function
  layoutChangeHandler?: Function
  layout: any,
}

const defaultLayoutContextValue: LayoutContextValue = {
  certificateModalOpen: false,
  setCertificateModalOpen: () => { },
  layoutChangeHandler: () => { },
  layout: 'default',
}

export const LayoutContext = React.createContext(defaultLayoutContextValue)

export const LayoutProvider = ({ children }: { children: Children }) => {
  const [certificateModalOpen, setCertificateModalOpen] = React.useState(false)

  const [layout, setLayout] = React.useState(getQueryParam('layout') || 'default')

  const layoutChangeHandler = (layout) => {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?layout=${layout}`
    window.history.pushState({ path: newurl }, '', newurl)
    setLayout(layout)
  }
  const values = {
    certificateModalOpen,
    setCertificateModalOpen,
    layoutChangeHandler,
    layout,
  }

  return (
    <LayoutContext.Provider value={values}>
      {children}
    </LayoutContext.Provider>
  )
}
