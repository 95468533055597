import React from 'react'

type Children = JSX.Element | JSX.Element[] | string

type WorkerContextValueType = {
  certificates: any
  setCertificates: Function
}

const WorkerContextValue: WorkerContextValueType = {
  certificates: [],
  setCertificates: () => { },
}



export const WorkerContext = React.createContext(WorkerContextValue)

export const WorkerProvider = ({ children }: { children: Children }) => {
  const [certificates, setCertificates] = React.useState([])
  const val = {
    certificates,
    setCertificates,
  }

  return (
    <WorkerContext.Provider value={val}>
      {children}
    </WorkerContext.Provider>
  )
}
