import CustomSelectComponent, { otherRender } from 'src/components/Inputs/CustomSelect'
import { useSchedulerContext } from 'src/contexts/SchedulerContext'

const JobStatusDropDown = ({ jobId, status }) => {
  const { setPositionAssignData, updateJobGeneral } = useSchedulerContext()
  const changeHandler = (value) => {
    setPositionAssignData(jobId)
    updateJobGeneral.updateJobGeneral({
      variables: {
        set: {
          status: value
        },
        jobId
      }
    })
  }
  const values = [
    {
      label: 'CANCELLED',
      value: 'CANCELLED'
    },
    {
      label: 'DISPUTED',
      value: 'DISPUTED'
    },
    // {
    //   label: 'OTHER',
    //   value: 'OTHER'
    // },
  ]
  return (
    <CustomSelectComponent options={values} onChange={changeHandler} renderFunction={otherRender} defaultSelected={status} />
  )
}

export default JobStatusDropDown