import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Alert, Box, Tooltip } from '@mui/material'
// import { ReactComponent as EditIcon } from 'src/components/icons/icon-park-outline_ticket.svg'
import Card from 'src/components/Card'
import MoreMenu from 'src/components/Scheduler/MoreMenu'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DetailsIcon from '@mui/icons-material/Details'
import { JOB_STATUS } from 'src/utils/Constants'
import CustomAccordion, { CompanyDetails, WorkSiteDetails, OpenedPositionsAccordion, RequestedWorkers } from 'src/components/Accordion'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
// import JobStatusDropDown from 'src/components/Inputs/JobStatusDropDown'
import { makeStyles } from '@mui/styles'
import { LayoutContext } from 'src/contexts/layoutContext'
const useStyles = makeStyles(() => ({
  anchor: {
    textDecoration: 'none',
    '&::visited': {
      color: 'black'
    }
  }
}))

export const JobsDetails = ({ positionUpdating, items, dragStart, removeWorkerFromPosition, onPressIcon, component = 'card', setJobId }: any) => {
  const classes = useStyles()
  const { layoutChangeHandler } = React.useContext(LayoutContext)

  return (
    <>
      {
          items?.map(({
            background = '',
            status = 'pending',
            id, positions,
            type,
            requested_by_company,
            scheduled_time,
            site, description,
          }) => {
            // const allCertificates: any = positions.map(({ certifications }) => certifications.map(({ certification }) => certification))
            // const uniqueCertificates: any = new Set(allCertificates.flat().map((item) => item.name))
            const childComponent = <> <Box py={1}>
              <CompanyDetails id={id} companyName={requested_by_company?.name} />
              <WorkSiteDetails scheduled_time={scheduled_time} site={site} />
              <Box py={1} borderBottom='1px solid lightgray'>
                <Typography textAlign='left' fontSize='0.875rem'>
                  {description}
                </Typography>
              </Box>
            </Box>
            </>
            return (
              <Box key={scheduled_time} my={1} className='MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-1elwnq4-MuiPaper-root-MuiAccordion-root'>
                <div >
                  <Tooltip placement='top' enterDelay={2000} title='doudble click to open details'>
                    <>
                      {
                        component === 'accordion' ?
                          <Box px={2} py={1} borderBottom='1px solid lightgray' display='flex' alignItems='center' justifyContent='space-between' >
                            <Box display='flex' alignItems='center'>
                              <Typography fontWeight={500}>JOB</Typography><Typography fontWeight={700}>#{id} {' '} {JOB_STATUS[status]}</Typography>
                            </Box>
                            <a style={{ textDecoration: 'none' }} href={`/jobs?jobId=${id}`} className={classes.anchor}>
                              <BorderColorOutlinedIcon sx={{ '&:hover': { cursor: 'pointer' }, color: 'black', fontSize: '24px !important' }} />
                            </a>
                          </Box>
                          : null
                      }
                      {
                        // Job | Worker
                        component === 'accordion' ?
                          <>
                            <CustomAccordion
                              defaultExpanded={false}
                              headerBackground={background}
                              accordionSummary={<Box display='flex' alignItems={'center'} justifyContent='space-between' width='100%'>
                                <Typography>Details</Typography>
                              </Box>}>
                              {childComponent}
                            </CustomAccordion>
                            {
                              positions.length > 0 ?
                                <CustomAccordion
                                  defaultExpanded={true}
                                  headerBackground={background}
                                  accordionSummary={<Box display='flex' alignItems={'center'} justifyContent='space-between' width='100%'>
                                    <Typography>Positions</Typography>
                                  </Box>}>
                                  <OpenedPositionsAccordion
                                    dragStart={dragStart}
                                    openedPositions={positions}
                                    jobId={id}
                                    removeWorkerFromPosition={removeWorkerFromPosition}
                                    positionUpdating={positionUpdating}
                                  />
                                </CustomAccordion>
                                : null
                            }
                          </>
                          :
                          // Job | Worker | Calendar
                          <Card
                            title={
                              <CompanyDetails id={id} companyName={requested_by_company?.name} >
                                <BorderColorOutlinedIcon
                                  sx={{ '&:hover': { cursor: 'pointer' }, color: 'black', fontSize: '24px !important' }}
                                  onClick={() => { setJobId(id) }}
                                />
                                <MoreMenu
                                  menuItems={[
                                    { label: 'Job Detail', handler: () => onPressIcon(id), icon: <DetailsIcon style={{ marginRight: '8px' }} /> },
                                    { label: 'Edit Job', handler: () => { }, icon: <ModeEditOutlineOutlinedIcon style={{ marginRight: '8px' }} /> },
                                    { label: 'Match Workers', handler: () => layoutChangeHandler('job-worker'), icon: <AddCircleOutlineOutlinedIcon style={{ marginRight: '8px' }} /> },
                                  ]}
                                />
                              </CompanyDetails>
                            }>
                             {/*<JobStatusDropDown jobId={id} status={status} /> */}
                            <WorkSiteDetails jobId={id} site={site} scheduled_time={scheduled_time} />
                            <RequestedWorkers positions={positions} />
                          </Card>
                      }
                    </>
                  </Tooltip>
                </div>
              </Box >

            )
          })
      }
    </>
  )
}

export default JobsDetails