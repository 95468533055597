import React, { useEffect } from 'react'
import LoginForm from 'src/components/Forms/login'
import { useAuth0 } from '@auth0/auth0-react'

const Login = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0()
    useEffect(() => {
        if (isAuthenticated) {
            if (typeof window === 'undefined') {
                return
            }
            window.location.replace('/')
        }
    }, [isAuthenticated])
    return <LoginForm onSubmit={() => loginWithRedirect()} />
}

export default Login
