import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import { CREATE_POSITION } from 'src/core/gql/mutations/positions'
import { GetSingleJob } from 'src/core/gql/queries/job'
import { getQueryParam } from 'src/utils/query'

type Children = JSX.Element | JSX.Element[] | string

type JobContextValue = {
  singleJob: {
    loading: boolean,
    error: any,
    data: any,
  },
  addPosition: {
    insertPosition: any,
    insertPositionLoading: boolean
  }
  notify: boolean,
  setNotify: Function
}

const defaultJobContextValue: JobContextValue = {
  singleJob: {
    loading: false,
    error: null,
    data: {},
  },
  addPosition: {
    insertPosition: () => { },
    insertPositionLoading: false
  },
  notify: false,
  setNotify: () => { }
}



export const JobContext = React.createContext(defaultJobContextValue)


export const JobProvider = ({ children }: { children: Children }) => {
  const jobId = getQueryParam('jobId')
  const [getSingleJob, { loading, error, data }] = useLazyQuery(GetSingleJob)
  const [notify, setNotify] = React.useState(false)

  const [addPosition, { loading: insertPositionLoading }] = useMutation<any, any>(CREATE_POSITION, {
    onCompleted: () => {
      setNotify(true)
    },
  })

  const insertPosition = (certificates) => {
    debugger
    addPosition({
      variables: {
        object: {
          ...certificates,
          scheduled_time: data?.job_by_pk?.scheduled_time,
          job_id: jobId
        }
      }
    })
  }

  useEffect(() => {
    if (jobId) {
      getSingleJob({
        variables: {
          id: Number(jobId)
        }
      })
    }
  }, [jobId, getSingleJob])

  const value = {
    singleJob: {
      loading,
      error,
      data
    },
    addPosition: {
      insertPosition,
      insertPositionLoading
    },
    notify,
    setNotify
  }

  return (
    <JobContext.Provider value={value}>
      {children}
    </JobContext.Provider>
  )
}

export function useJobContext() {
  const context = React.useContext(JobContext)
  if (context === undefined) {
    throw new Error('useJobContext must be used within an JobProvider')
  }
  return context
}
