import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import IconText from '../IconText'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import BatteryChargingFullOutlinedIcon from '@mui/icons-material/BatteryChargingFullOutlined'

export default function WorderActiveJobs({ workerData }) {

  const useStyles: any = makeStyles(() => ({
    sectionHeadingText: {
      height: '18px',
      width: '226px',
      left: '8px',
      top: '12px',
      textAlign: 'left',
    },
    sectionHeadingBox: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    jobContainer: {
      width: '100%',
      height: '300px',
      marginTop: 20
    },
    jobDetailModalRoot: {
      width: '100%'
    },
    popUpBodyRowContainer: {
      width: '100%',
      marginBottom: 20,
      border: '1px solid #E0E4ED'
    },
    rowContainer: {
      width: '33%'
    },
    certificationsTitle: {
      marginBottom: '10px !important',
      color: '#A1A1A1',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    assignedWorkers: {
      marginBottom: '10px !important',
      color: '#A1A1A1',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '115% !important',
      letterSpacing: '0.08em !important',
      textTransform: 'uppercase'
    },
    addIcon: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important'
    },
    addButton: {
      backgroundColor: '#A1A1A1 !important',
    },
    menuBodyContainer: {
      maxWidth: '400px !important',
      minWidth: '200px !important'
    },
    profileMenu: {
      margin: '0px 10px'
    },
    statusButton: {
      border: '1px solid red !important'
    },
    dropDownStatus: {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '100% !important',
    },
    itemHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#F7F8FC',
      height: '45px',
      alignItems: 'center',
      padding: '0px 20px',
    },
    headerStatus: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      margin: '0px 12px',
      borderRadius: '40px',
    },
    headerStatusText: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '100%',
      margin: '0px 4px',
      color: '#FFFFFF',
    },
    headerStatusIcon: {
      color: '#FFFFFF',
      width: '18px',
      height: '18px',
    },
  }))

  const classes = useStyles()

  function getDate(time: string) {
    let newTime = time ? time.replace(')', '').replace('[', '').replace('(', '').replace(']', '').split(',') : null
    const formattedTime = newTime ? moment(newTime[0]).format('DD MMM, YYYY') + ' - ' + moment(newTime[1]).format('DD MMM, YYYY') : '--'
    return (formattedTime)
  }

  const getStatusBackgroundColor = (statusLabel) => {
    if (statusLabel === 'READY') return '#009c05'
    else if (statusLabel === 'COMPLETED') return '#00de07'
    else if (statusLabel === 'PENDING_WORKERS') return '#f4af6a'
    else if (statusLabel === 'PENDING_WORKERS') return '#f4af6a'
    else return 'red'
    // else if (statusLabel === 'COMPLETED') return '#FFFFFF'
  }

  return (
    <Box>
      {workerData?.user[0]?.jobs.length > 0 && workerData?.user[0]?.jobs.map((item) => {
        return (
          <Box className={classes.popUpBodyRowContainer}>
            <Box className={classes.itemHeader}>
              <Box>
                <Typography sx={{ fontWeight: '700' }}>
                  Job # {item.id}
                </Typography>
              </Box>
              <Box>
                <Box
                  className={classes.headerStatus}
                  sx={{ background: getStatusBackgroundColor(item?.status) }}
                >
                  <AccessTimeOutlinedIcon className={classes.headerStatusIcon} />
                  <Typography className={classes.headerStatusText}>{item?.status}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: '20px' }}>
              <IconText
                text={item.site.name}
                Icon={BatteryChargingFullOutlinedIcon}
              />
              <IconText
                text={item?.site?.address}
                Icon={LocationOnOutlinedIcon}
              />
              <IconText
                text={getDate(item?.scheduled_time)}
                Icon={AccessTimeOutlinedIcon}
              />
            </Box>
          </Box>
        )
      })}
      {
        workerData?.user[0]?.jobs.length === 0 && <Typography>
          No active jobs
        </Typography>
      }
      {/* <Button variant="contained">Filter In Calendar</Button> */}
    </Box>
  )
}