import { gql } from '@apollo/client'

export const CREATE_WORKER = gql`
mutation createWorker(
    $object: user_insert_input!
)   {
    insert_user_one (object: $object) {
        id
    }
}
`

export const UPDATE_WORKER = gql`
mutation updateWorker(
    $set: user_set_input
    $userId: String!
)   {
    update_user_by_pk(_set: $set, pk_columns: {id: $userId}) {
        id
    }
}
`