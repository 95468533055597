import { FormControl, FormHelperText, Typography, useFormControl } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useMemo } from 'react'
import { AppTextColor, AppErrorColor } from 'src/theme/Colors'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    margin: '0px 0px 10px 0px !important',
  }
}))
const CustomInput = ({ label, placeholder, id, isError, helperText, value, onChangeHandler, maxLength, onKeyDown }: CustomInputProps) => {
  const classes = useStyles()

  function HeadingText() {
    const { focused } = useFormControl() || {}

    const headingColor = useMemo(() => {
      if (isError) {
        return AppErrorColor
      }

      if (focused && !isError) {
        return '#007FFF'
      }

      return AppTextColor
    }, [focused])

    return (
      <Typography fontSize={14} className={classes.headingStyles} color={headingColor}>
        {label}
      </Typography>
    )
  }

  return (
    <FormControl variant="standard" fullWidth>
      <HeadingText />
      <OutlinedInput
        id={id ? id : `bootstrap-input-${label}`}
        name={id ? id : `bootstrap-input-${label}`}
        fullWidth placeholder={placeholder ?? ''}
        error={isError}
        value={value}
        onChange={onChangeHandler}
        inputProps={{ maxLength: maxLength }}
        onKeyDown={onKeyDown}
        size="small"
      />
      <FormHelperText error={isError}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export type CustomInputProps = {
  id?: string
  label: string
  placeholder?: string
  isError?: boolean
  helperText?: string
  value: string
  onChangeHandler: any
  maxLength?: number
  onKeyDown?: any
}
export default CustomInput