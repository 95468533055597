import { Box, Divider, Typography } from '@mui/material'
import { PrimaryColor } from 'src/theme/Colors'

const SubSection = (props: any) => {
    const  {Icon, heading} = props
    return (
        <Box>
            <Box display="flex" alignItems={'center'} gap="10px">
                <Icon fontSize="medium" sx={{color: PrimaryColor}}/>
                <Typography fontSize={'14px'} fontWeight="700" color={PrimaryColor}>{heading}</Typography>
            </Box>
            <Divider sx={{padding: '5px 0px'}} />
            <Box sx={{padding: '10px'}}>
                {props.children}
            </Box>
        </Box>
    )
}

export default SubSection