export const config = {
  hasura: process.env.REACT_APP_HASURA,
  hasuraUri: `https://${process.env.REACT_APP_HASURA}/v1/graphql`,
  hasuraWsUri: `wss://${process.env.REACT_APP_HASURA}/v1/graphql`,
  auth0Uri: process.env.REACT_APP_AUTH0,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  cloudfrontUri: process.env.REACT_APP_CLOUDFRONT,
}

export default config
