import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell , { tableCellClasses }  from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, Paper, TableContainer, TableSortLabel, Typography } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { styled } from '@mui/material/styles'
import { AppBackground, AppBorderColor, AppTextColor } from 'src/theme/Colors'
import Loader from '../Loader'
import { visuallyHidden } from '@mui/utils'

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const CustomTable = ({
  columns,
  data,
  onRowClick = () => {},
  height,
  loading,
  isPaginationRequired = false,
  page = 0,
  setPage = () => {},
  rowsPerPage = 20,
  setRowsPerPage = () => {},
  total = 0,

}: CustomTableProps) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState('id')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: AppBackground,
      color: AppTextColor,
      fontSize: 14,
      fontWeight: '700'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: AppTextColor,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: AppBackground,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const renderData = (c, row) => {
    if (c.mergeRender) {
      return c.mergeRender(row)
    } else {
      if (c.childKey) {
        if (c.toFixed) {
          return row[c.key][c.childKey]?.toFixed(c.toFixed)
        } else {
          // console.log('=============================================================')
          // console.log(c)
          // console.log(c.key)
          // console.log(row[c.key])
          // console.log(c.childKey)
          // // console.log(row[c.key][c.childKey])
          if(row[c.key]){
            return row[c.key][c.childKey]
          }
        }
      } else {
        return c.type && c.type === 'date'
          ? c.format(row[c.key])
          : c.toFixed
            ? row[c.key]?.toFixed(c.toFixed)
            : c.customRender ? c.customRender(row[c.key]) : row[c.key] ? row[c.key] :
              <Typography sx={{color: AppBorderColor, fontStyle: 'italic'}}>None.</Typography>
      }
    }
  }

  const renderRow = (row: any, index: number) => {
    return (
      <StyledTableRow
        key={index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
        onClick={() => onRowClick(row)}
        >
        {columns.map((c, key) =>
          c.key === 'action' ? (
            <StyledTableCell
              id="cell-value"
              key={`${key}-${index}`}
            >
              {c.component(row)}
            </StyledTableCell>
          ) : (
            <StyledTableCell
              id="cell-value"
              key={`${key}-${index}`}
              align={c?.align??'left'}
            >
              {c.renderContent ? c.renderContent(row[c.key]) : renderData(c, row)}
            </StyledTableCell>
          )
        )}
      </StyledTableRow>
    )
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler =
    (property) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none', borderRadius: '2px' }}>
      <TableContainer  sx={{ maxHeight: height ? height : '70vh' }}>
        <Table stickyHeader aria-label="customized table" >
          <TableHead>
            <TableRow>
              {columns.map((headCell: any, i: number) =>
                {
                  if(headCell.isSortable) {
                    return (
                      <StyledTableCell
                        key={i}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.key ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.key}
                          direction={orderBy === headCell.key ? order : 'asc'}
                          onClick={createSortHandler(headCell.key)}
                        >
                          {headCell.name}
                          {orderBy === headCell.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                  )} else {
                    return <StyledTableCell key={i} align={headCell?.align??'left'}>{headCell.name}</StyledTableCell>
                  }
                }
              )}
            </TableRow>
          </TableHead>
          {!loading ?
             data?.length ? (
            <TableBody>{stableSort(data, getComparator(order, orderBy)).map((row, index) => renderRow(row, index))}</TableBody>
          ) : (
           <></>
          ) : <></>}
        </Table>
        {loading ? (
          <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader top='"60%' left='"50%'/>
          </Box>
        ) : !data?.length && (
          <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h2 style={{color: AppBorderColor}}>No Data!</h2>
          </Box>
        )}
      </TableContainer>
      {isPaginationRequired &&
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={total===0? data?.length||0: total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(handleChangePage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }

    </Paper>
  )
}

export type CustomTableProps = {
  columns: any,
  data:  Array<any>,
  onRowClick?: Function,
  height?: any,
  loading?: boolean,
  isPaginationRequired?: boolean,
  page?: number,
  setPage?: any,
  rowsPerPage?: number,
  setRowsPerPage?: any,
  total?: number
}

export default CustomTable

// return c.mergeRender ? c.mergeRender(row)
//   : c.childKey
//     ? c.toFixed
//       ? row[c.key][c.childKey]?.toFixed(c.toFixed)
//       : row[c.key][c.childKey]
//     : c.type && c.type === 'date'
//       ? c.format(row[c.key])
//       : c.toFixed
//         ? row[c.key]?.toFixed(c.toFixed)
//         : c.customRender ? c.customRender(row[c.key]) : row[c.key] ? row[c.key] :
//           <Typography sx={{color: AppBorderColor, fontStyle: 'italic'}} >None.</Typography>
