
import Button from '@mui/material/Button'
import { AppBackground, AppBorderColor } from 'src/theme/Colors'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles(() => ({
    container: {
        background: AppBackground,
        width: '100%',
        textAlign: 'left',
        fontWeight: '700',
        justifyContent: 'flex-start',
        borderStyle: 'dotted',
        border: `1px dotted ${AppBorderColor}`
        },
  }))

const ButtonWithBorder = ({label ,Icon, onClick} : ButtonWithBorderProps) => {
    const classes = useStyle()
    return (
        <Button
            type='button'
            className={classes.container}
            startIcon={<Icon />}
            onClick={() => onClick()}
        >
            {label}
        </Button>
    )
}

type ButtonWithBorderProps = {
    onClick: Function
    label: String,
    Icon?: any
}
export default ButtonWithBorder