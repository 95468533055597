import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import DialogTitle from '@mui/material/DialogTitle'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: '0px !important'
  },
  titleContentContainer: {
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  profile: {
    marginRight: 10
  },
  dialogTitleRoot: {
    width: '90% !important'
  },
  dialogTitle: {
    fontSize: '18px !important',
    color: '#001533 !important',
    fontWeight: '700 !important',
  },
  dialogSubTitle: {
    fontSize: '16px',
    color: '#AEAEAE',
    fontWeight: '400'
  },
  iconsContainer: {
    width: '10% !important',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex'
  },
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 600,
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10
  },
  body: {
    padding: '0px 40px'
  },
  titleInnerContainer: {
    padding: '0px 40px'
  },
  buttonContainer: {
    width: '100%',
    padding: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

type Props = {
  open: boolean;
  setOpen: Function;
  title: string;
  subtitle?: string;
  children: any;
  showProfileIcon?: boolean
  cancelButtonVarient?: any
  acceptButtonVarient?: any
  cancelButtonTitle: string
  acceptButtonTitle: string
  handleAccept?: Function
  handleClose?: Function
  loading?: boolean
}
export default function ModalBase({
  open,
  setOpen,
  title,
  subtitle,
  children,
  cancelButtonVarient,
  acceptButtonVarient,
  cancelButtonTitle,
  acceptButtonTitle,
  handleAccept,
  handleClose,
  loading }: Props) {
  const handleOpen = () => setOpen(true)

  const handleCloseInt = () => {
    if (handleClose) {
      handleClose()
    }
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <Modal
      sx={{ minHeight: '80vh', overflowY: 'auto' }}
      open={open}
      onClose={handleCloseInt}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.root}>
        <DialogTitle className={classes.titleContainer}>
          <Box className={classes.titleContentContainer}>
            <IconButton onClick={handleCloseInt}>
              <Close />
            </IconButton>
          </Box>
          <Box className={classes.titleInnerContainer}>
            <Box className={classes.dialogTitleRoot}>
              <Typography className={classes.dialogTitle}>{title}</Typography>
              {subtitle && <Typography className={classes.dialogSubTitle}>{subtitle}</Typography>}
            </Box>
          </Box>
        </DialogTitle>
        <Box className={classes.body}>
          {children}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button sx={{ width: '48%' }} variant={cancelButtonVarient ? cancelButtonVarient : 'outlined'} onClick={() => handleCloseInt()}>{cancelButtonTitle}</Button>
          <LoadingButton
            sx={{ width: '48%' }}
            variant={acceptButtonVarient ? acceptButtonVarient : 'outlined'}
            loading={loading}
            onClick={() => handleAccept ? handleAccept() : {}}>{acceptButtonTitle}</LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}