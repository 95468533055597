import { Checkbox, FormControlLabel, FormGroup, InputAdornment, Link, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Modal from 'src/components/Modal/index'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CustomInput from 'src/components/Inputs'
import { GetDistinctCertificates } from 'src/core/gql/queries/certificate'
import { useMutation, useQuery } from '@apollo/client'
import { Search } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { CREATE_POSITION } from 'src/core/gql/mutations/positions'
import Notify from 'src/components/Toast'
import Loader from 'src/components/Loader'
const useStyles = makeStyles(() => ({
  scroll: {
    padding: 8,
    overflowY: 'auto',
    scroll: 'auto',
    height: '250px',
    scrollbarColor: '#6b6b6b #2b2b2b',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '10px',
      width: '3px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)',
      width: '3px'
    }
  },
  link: {
    fontWeight: 700,
    textDecoration: 'none'
  }
}))
const validationSchema = yup.object({
  function: yup
    .string()
    .required('Certification function is required')
    .min(8, 'Certification function should be of minimum 8 characters length')
})

const AddPositionPopup = ({ open, setOpen, addPosition, notify }) => {
  const classes = useStyles()
  const { insertPosition, insertPositionLoading } = addPosition
  const { data } = useQuery(GetDistinctCertificates)
  const [searchValue, setSearchValue] = useState(null)
  const formik = useFormik({
    initialValues: {
      function: '',
      certifications: [],
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, i) => {
      const formattedCerfificates = values.certifications.length > 0 ? {
        certifications: {
          data: values.certifications.map((id) => ({ certification_id: Number(id) }))
        }
      } : {}
      insertPosition(formattedCerfificates)
    },
  })
  return (
    <div>
      <Notify defaultOpen={notify} message={'Position successfully created.'} type={'success'} />
      {
        open ?
          <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={'Add Position'}
            cancelButtonTitle={'Cancel'}
            acceptButtonVarient='outlined'
            acceptButtonTitle={'Save Position'}
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={false}
          >
            {insertPositionLoading ? <Loader /> : null}

            <Box rowGap={'10px'} display='flex' flexDirection={'column'} mt='10px'>
              <CustomInput
                id='function'
                label={'Function'}
                placeholder='Type here...'
                value={formik.values.function}
                onChangeHandler={formik.handleChange}
                isError={formik.touched.function && Boolean(formik.errors.function)}
                helperText={formik.touched.function && formik.errors.function}
              />
              <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: '700', marginBottom: '-3px' }}>
                  Necessary Certification
                </Typography>
                <Box sx={{ border: '2px solid lightgray', borderRadius: '10px' }}>
                  <Box >
                    <TextField
                      sx={{ px: 1, }}
                      value={searchValue}
                      onChange={(event) => { setSearchValue(event.target.value) }}
                      placeholder='Type here...'
                      variant='standard'
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>

                      }} />
                  </Box>

                  <Box className={classes.scroll}>
                    <FormGroup>
                      {
                        data?.certification?.filter((certificate) => searchValue ? certificate.name.toLowerCase().includes(searchValue.toLowerCase()) : 1)?.map(({ name, id }, index) => <FormControlLabel
                          key={id}
                          control={<Checkbox id={`${id}`} name={'certifications'} value={id} onChange={formik.handleChange} />}
                          label={name}
                        />)
                      }
                    </FormGroup>
                  </Box>
                </Box>
              </Box>
              {' '}
              <Typography sx={{ fontSize: '12px' }}>
                Can't find a certification?
                <Link className={classes.link}> {' '}Create New</Link>
              </Typography>
              <CustomInput
                id='description'
                label={'optional Description'}
                placeholder='Type here...'
                value={formik.values.description}
                onChangeHandler={formik.handleChange}
                helperText={`${200 - formik.values.description.length} characters Left`}
                maxLength={200}
              />
            </Box>
          </Modal>
          : null
      }
    </div>

  )
}

export default AddPositionPopup